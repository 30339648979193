import React, { Component } from 'react';
import server from '../../configs/domain';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import Slider from "react-slick";
import './gallery.component.css';

class Gallery extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id,
            nav1: null,
            nav2: null,
            selectedProject: [this.props.projects.find(project => project.id === parseInt(this.props.match.params.id))],
            relatedProducts: [],
            newProducts: []
        };
    }

    componentDidMount() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2
        });
        if (this.state.selectedProject.length) {
            let projects = this.props.projects.filter(item => item.type === this.state.selectedProject[0].type);
            this.setState({ relatedProducts: projects.slice(0, 4) });
            this.setState({ newProducts: this.props.projects.reverse().slice(0, 4) });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { id } = this.props.match.params;
        if (id !== prevState.id) {
            this.setState({ id });
            this.setState({
                nav1: this.slider1,
                nav2: this.slider2
            });
            this.setState({ selectedProject: [this.props.projects.find(project => project.id === parseInt(this.props.match.params.id))] });
            if (this.state.selectedProject.length) {
                let projects = this.props.projects.filter(item => item.type === this.state.selectedProject[0].type);
                this.setState({ relatedProducts: projects.slice(0, 4) });
                this.setState({ newProducts: this.props.projects.reverse().slice(0, 4) });
            }
        }
    }

    render() {
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            autoplay: true,
            slidesToScroll: 1
        };

        return (
            <>
                <div>
                    {/* <h2>Slider Syncing (AsNavFor)</h2>
                    <h4>First Slider</h4>
                    <Slider
                        asNavFor={this.state.nav2}
                        ref={slider => (this.slider1 = slider)}
                        {...settings}
                    >
                        <div>
                            <h3>1</h3>
                        </div>
                        <div>
                            <h3>2</h3>
                        </div>
                        <div>
                            <h3>3</h3>
                        </div>
                        <div>
                            <h3>4</h3>
                        </div>
                        <div>
                            <h3>5</h3>
                        </div>
                        <div>
                            <h3>6</h3>
                        </div>
                    </Slider>
                    <h4>Second Slider</h4> */}
                    {/* <Slider
                        asNavFor={this.state.nav1}
                        ref={slider => (this.slider2 = slider)}
                        slidesToShow={3}
                        swipeToSlide={true}
                        focusOnSelect={true}
                    >
                        <div>
                            <h3>1</h3>
                        </div>
                        <div>
                            <h3>2</h3>
                        </div>
                        <div>
                            <h3>3</h3>
                        </div>
                        <div>
                            <h3>4</h3>
                        </div>
                        <div>
                            <h3>5</h3>
                        </div>
                        <div>
                            <h3>6</h3>
                        </div>
                    </Slider> */}
                </div>

                <div>
                    <div className="inteco-page-title-wrap  inteco-style-medium inteco-center-align">
                        {/* <div className="inteco-header-transparent-substitute"></div> */}
                        <div className="inteco-page-title-overlay"></div>
                        <div className="inteco-page-title-container inteco-container">
                            <div className="inteco-page-title-content inteco-item-pdlr">
                                {/* <h1 className="inteco-page-title">Image</h1> */}
                                {/* <div className="inteco-page-caption">Gallery</div> */}
                            </div>
                        </div>
                    </div>
                    <div className="inteco-page-wrapper" id="inteco-page-wrapper">

                        <div className="gdlr-core-page-builder-body">
                            {
                                this.props.projects && this.state.selectedProject.map(image =>

                                    <>
                                        <div className="gdlr-core-pbf-wrapper " key={`project-image-${image.id}`} style={{ padding: "60px 0px 0px 0px" }}>
                                            <div className="gdlr-core-pbf-background-wrap"></div>
                                            <div className="gdlr-core-pbf-wrapper-content gdlr-core-js ">
                                                <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">

                                                    <div className="gdlr-core-pbf-column gdlr-core-column-40 gdlr-core-column-first">
                                                        <div className="gdlr-core-pbf-column-content-margin gdlr-core-js ">
                                                            <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                                                                <div className="gdlr-core-pbf-element">

                                                                    {/* <div className="gdlr-core-image-item gdlr-core-item-pdlr gdlr-core-item-pdb  gdlr-core-center-align" style={{ paddingBottom: "40px" }}>
                                                                        <div className="gdlr-core-image-item-wrap gdlr-core-media-image  gdlr-core-image-item-style-rectangle" style={{ borderWidth: "0px" }}>
                                                                            <a className="gdlr-core-lightgallery gdlr-core-js " data-lightbox-group="gdlr-core-img-group-1" href={`${server}/${image.image}`} data-sub-html="Modern House">
                                                                                <img src={`${server}/${image.image}`} srcSet={`${server}/${image.image}  400w, ${server}/${image.image}  600w, ${server}/${image.image}  900w`} sizes="(max-width: 767px) 100vw, (max-width: 1150px) 66vw, 766px" alt="" width="900" height="500" />
                                                                                <span className="gdlr-core-image-overlay ">
                                                                                    <i className="gdlr-core-image-overlay-icon gdlr-core-size-22 fa fa-search"></i>
                                                                                </span>
                                                                            </a>
                                                                        </div>
                                                                    </div> */}
                                                                    <Slider
                                                                        asNavFor={this.state.nav2}
                                                                        ref={slider => (this.slider1 = slider)}
                                                                        {...settings}
                                                                    >
                                                                        <div className="gdlr-core-image-item gdlr-core-item-pdlr gdlr-core-item-pdb  gdlr-core-center-align" style={{ paddingBottom: "40px" }}>
                                                                            <div className="gdlr-core-image-item-wrap gdlr-core-media-image  gdlr-core-image-item-style-rectangle" style={{ borderWidth: "0px" }}>
                                                                                <div className="gdlr-core-js " data-sub-html="Modern House">
                                                                                    <img className="gallery-img" src={`${server}/${image.image}`} srcSet={`${server}/${image.image}  400w, ${server}/${image.image}  600w, ${server}/${image.image}  900w`} sizes="(max-width: 767px) 100vw, (max-width: 1150px) 66vw, 766px" alt="" width="900" height="500" />
                                                                                    {/* <span className="gdlr-core-image-overlay ">
                                                                                            <i className="gdlr-core-image-overlay-icon gdlr-core-size-22 fa fa-search"></i>
                                                                                        </span> */}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {image.galleries && image.galleries.map((project, key) =>
                                                                            <div className="gdlr-core-image-item gdlr-core-item-pdlr gdlr-core-item-pdb  gdlr-core-center-align" style={{ paddingBottom: "40px" }}>
                                                                                <div className="gdlr-core-image-item-wrap gdlr-core-media-image  gdlr-core-image-item-style-rectangle" style={{ borderWidth: "0px" }}>
                                                                                    <div className="gdlr-core-js " data-sub-html="Modern House">
                                                                                        <img className="gallery-img" src={`${server}/${project.image}`} srcSet={`${server}/${project.image}  400w, ${server}/${project.image}  600w, ${server}/${project.image}  900w`} sizes="(max-width: 767px) 100vw, (max-width: 1150px) 66vw, 766px" alt="" width="900" height="500" />
                                                                                        {/* <span className="gdlr-core-image-overlay ">
                                                                                            <i className="gdlr-core-image-overlay-icon gdlr-core-size-22 fa fa-search"></i>
                                                                                        </span> */}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </Slider>
                                                                    <div className="nav gdlr-core-pbf-section">
                                                                        <div className="gdlr-core-pbf-section-container gdlr-core-container clearfix">
                                                                            <Slider
                                                                                asNavFor={this.state.nav1}
                                                                                ref={slider => (this.slider2 = slider)}
                                                                                slidesToShow={image.galleries.length > 3 ? 5 : image.galleries.length + 1}
                                                                                swipeToSlide={true}
                                                                                focusOnSelect={true}
                                                                                infinite={true}
                                                                                variableWidth={true}
                                                                                responsive={[
                                                                                    {
                                                                                        breakpoint: 1024,
                                                                                        settings: {
                                                                                            slidesToShow: image.galleries.length > 2 ? 3 : image.galleries.length + 1
                                                                                        }
                                                                                    },
                                                                                    {
                                                                                        breakpoint: 600,
                                                                                        settings: {
                                                                                            slidesToShow: image.galleries.length > 0 ? 2 : image.galleries.length + 1
                                                                                        }
                                                                                    },
                                                                                    {
                                                                                        breakpoint: 480,
                                                                                        settings: {
                                                                                            slidesToShow: 1
                                                                                        }
                                                                                    }
                                                                                ]}
                                                                            >
                                                                                <div className="gdlr-core-pbf-column" key={`project-image-main-list-${image.id}`} style={{ maxWidth: "150px" }}>
                                                                                    <div className="gdlr-core-pbf-column-content-margin gdlr-core-js ">
                                                                                        <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                                                                                            <div className="gdlr-core-pbf-element">
                                                                                                <div className="gdlr-core-image-item gdlr-core-item-pdlr gdlr-core-item-pdb  gdlr-core-center-align">
                                                                                                    <div className="gdlr-core-image-item-wrap gdlr-core-media-image  gdlr-core-image-item-style-rectangle" style={{ borderWidth: "0px" }}>
                                                                                                        <div className="gdlr-core-js ">
                                                                                                            <img className="gallery-nav-img" src={`${server}/${image.image}`} srcSet={`${server}/${image.image} 400w, ${server}/${image.image} 700w`} alt="" />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                {image.galleries && image.galleries.map((project, key) =>
                                                                                    <div className="gdlr-core-pbf-column" key={`project-image-list-${project.id}-${key}`} style={{ maxWidth: "150px" }}>
                                                                                        <div className="gdlr-core-pbf-column-content-margin gdlr-core-js ">
                                                                                            <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                                                                                                <div className="gdlr-core-pbf-element">
                                                                                                    <div className="gdlr-core-image-item gdlr-core-item-pdlr gdlr-core-item-pdb  gdlr-core-center-align">
                                                                                                        <div className="gdlr-core-image-item-wrap gdlr-core-media-image  gdlr-core-image-item-style-rectangle" style={{ borderWidth: "0px" }}>
                                                                                                            <div className="gdlr-core-js ">
                                                                                                                <img className="gallery-nav-img" src={`${server}/${project.image}`} srcSet={`${server}/${project.image} 400w, ${server}/${project.image} 700w`} alt="" />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                )}

                                                                            </Slider>
                                                                        </div>
                                                                    </div>


                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="gdlr-core-pbf-column gdlr-core-column-20">
                                                        <div className="gdlr-core-pbf-column-content-margin gdlr-core-js " style={{ padding: "40px 0px 0px 25px" }}>
                                                            <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js ">


                                                                <div className="gdlr-core-pbf-element">
                                                                    <div className="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-bottom gdlr-core-item-pdlr">
                                                                        {/* <div className="gdlr-core-title-item-title-wrap ">
                                                                        <h4 className="gdlr-core-title-item-title gdlr-core-skin-title " style={{ fontSize: "20px", letterSpacing: "0px", textTransform: "none" }}>Project Description</h4>
                                                                    </div>
                                                                    <span className="gdlr-core-title-item-caption gdlr-core-info-font gdlr-core-skin-caption" style={{ fontSize: "17px", fontWeight: 700, fontStyle: "normal", marginTop: "20px" }}>Far far away, behind the word mountains, far from the countries Vokalia and Conso nantia, there live the blind texts. Separated they live in.</span> */}
                                                                        <div dangerouslySetInnerHTML={{ __html: image.description }} />
                                                                    </div>
                                                                </div>


                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="gdlr-core-pbf-section">
                                            <div className="gdlr-core-pbf-section-container gdlr-core-container clearfix">
                                                {image.galleries && image.galleries.map((project, key) =>
                                                    <div className="gdlr-core-pbf-column gdlr-core-column-15" key={`project-image-list-${image.id}-${key}`}>
                                                        <div className="gdlr-core-pbf-column-content-margin gdlr-core-js ">
                                                            <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                                                                <div className="gdlr-core-pbf-element">
                                                                    <div className="gdlr-core-image-item gdlr-core-item-pdlr gdlr-core-item-pdb  gdlr-core-center-align">
                                                                        <div className="gdlr-core-image-item-wrap gdlr-core-media-image  gdlr-core-image-item-style-rectangle" style={{ borderWidth: "0px" }}>
                                                                            <a className="gdlr-core-lightgallery gdlr-core-js " data-lightbox-group="gdlr-core-img-group-1" href={`${server}/${project.image}`}>
                                                                                <img src={`${server}/${project.image}`} srcSet={`${server}/${project.image} 400w, ${server}/${project.image} 700w`} sizes="(max-width: 767px) 100vw, (max-width: 1150px) 33vw, 383px" alt="" width="700" height="935" />
                                                                                <span className="gdlr-core-image-overlay "><i className="gdlr-core-image-overlay-icon gdlr-core-size-22 fa fa-search"></i></span>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}

                                            </div>
                                        </div> */}
                                    </>
                                )}
                        </div>


                    </div>
                </div>

                <div className="gdlr-core-pbf-wrapper " style={{ "padding": "" }} data-skin="Brown Divider">
                    <div className="gdlr-core-pbf-background-wrap">
                        <div className="gdlr-core-pbf-background gdlr-core-parallax gdlr-core-js"
                            style={{ "backgroundImage": "url(upload/hp2-bg-1.jpg)", "backgroundSize": "cover", "backgroundPosition": "center" }}
                            data-parallax-speed="0.2"></div>
                    </div>
                    <div className="gdlr-core-pbf-wrapper-content gdlr-core-js ">
                        <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
                            <div className="gdlr-core-pbf-element">
                                <div
                                    className="gdlr-core-blog-item gdlr-core-item-pdb clearfix  gdlr-core-style-blog-column">
                                    <div className="gdlr-core-block-item-title-wrap  gdlr-core-left-align gdlr-core-item-mglr"
                                        style={{ "marginBottom": "76px" }}>
                                        <div className="gdlr-core-block-item-title-inner">
                                            <h3 className="gdlr-core-block-item-title"
                                                style={{ "fontSize": "29px", "fontStyle": "normal", "textTransform": "none", "letterSpacing": "0px", color: "#111111" }}>
                                                Related Projects</h3><span className="gdlr-core-separator"
                                                    style={{ "borderColor": "#968481" }}></span><NavLink
                                                        className="gdlr-core-block-item-read-more" to={"/projects/" + this.state.selectedProject[0].type} target="_self"
                                                        style={{ "fontSize": "16px", color: "#968481" }}>View More</NavLink>
                                        </div>
                                    </div>
                                    <div className="gdlr-core-portfolio-item-holder gdlr-core-js-2 filter-container clearfix"
                                        data-layout="fitrows">

                                        {
                                            this.state.relatedProducts.length && this.state.relatedProducts.map(project =>

                                                <div className="gdlr-core-item-list  gdlr-core-item-pdlr gdlr-core-column-15 project-list" key={project.title}>
                                                    <div className="gdlr-core-portfolio-grid  gdlr-core-center-align gdlr-core-style-normal" style={{ "marginBottom": "25px" }}>
                                                        <div className="gdlr-core-portfolio-thumbnail gdlr-core-media-image  gdlr-core-style-margin-icon">
                                                            <div className="gdlr-core-portfolio-thumbnail-image-wrap  gdlr-core-zoom-on-hover">
                                                                <NavLink className="gdlr-core-js " to={'/project/' + project.id} data-lightbox-group="gdlr-core-img-group-1"><img src={`${server}/${project.image}`} srcSet={`${server}/${project.image} 400w, ${server}/${project.image} 700w`} sizes="(maxWidth: 767px) 100vw, 25vw" alt="" width="700" height="660" /><span className="gdlr-core-image-overlay  gdlr-core-portfolio-overlay gdlr-core-image-overlay-center-icon gdlr-core-js gdlr-core-with-margin"><span className="gdlr-core-image-overlay-content" style={{ "marginTop": "-17.8417px" }}><span className="gdlr-core-portfolio-icon-wrap"><i className="gdlr-core-portfolio-icon arrow_expand"></i></span></span>
                                                                </span>
                                                                </NavLink>
                                                            </div>
                                                        </div>
                                                        <div className="gdlr-core-portfolio-content-wrap gdlr-core-skin-divider">
                                                            <h3 className="gdlr-core-portfolio-title gdlr-core-skin-title" style={{ "fontSize": "16px", "fontWeight": "600", "letterSpacing": "0px", "textTransform": "none" }}><NavLink to={'/project/' + project.id}>{project.title}</NavLink></h3>
                                                            <span className="gdlr-core-portfolio-info gdlr-core-portfolio-info-tag gdlr-core-info-font gdlr-core-skin-caption"><a href="/#" rel="tag">{project.type}</a> <span className="gdlr-core-sep">/</span> <a href="/#" rel="tag">Projects</a></span>
                                                            <div className="gdlr-core-portfolio-content"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="gdlr-core-pbf-wrapper " style={{ "padding": "0px" }} data-skin="Brown Divider">
                    <div className="gdlr-core-pbf-background-wrap">
                        <div className="gdlr-core-pbf-background gdlr-core-parallax gdlr-core-js"
                            style={{ "backgroundImage": "url(upload/hp2-bg-1.jpg)", "backgroundSize": "cover", "backgroundPosition": "center" }}
                            data-parallax-speed="0.2"></div>
                    </div>
                    <div className="gdlr-core-pbf-wrapper-content gdlr-core-js ">
                        <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
                            <div className="gdlr-core-pbf-element">
                                <div
                                    className="gdlr-core-blog-item gdlr-core-item-pdb clearfix  gdlr-core-style-blog-column">
                                    <div className="gdlr-core-block-item-title-wrap  gdlr-core-left-align gdlr-core-item-mglr"
                                        style={{ "marginBottom": "76px" }}>
                                        <div className="gdlr-core-block-item-title-inner">
                                            <h3 className="gdlr-core-block-item-title"
                                                style={{ "fontSize": "29px", "fontStyle": "normal", "textTransform": "none", "letterSpacing": "0px", color: "#111111" }}>
                                                New Projects</h3><span className="gdlr-core-separator"
                                                    style={{ "borderColor": "#968481" }}></span><NavLink
                                                        className="gdlr-core-block-item-read-more" to={"/projects/" + this.state.selectedProject[0].type} target="_self"
                                                        style={{ "fontSize": "16px", color: "#968481" }}>View More</NavLink>
                                        </div>
                                    </div>
                                    <div className="gdlr-core-portfolio-item-holder gdlr-core-js-2 filter-container clearfix"
                                        data-layout="fitrows">

                                        {
                                            this.state.relatedProducts && this.state.newProducts.map((project, key) =>

                                                <div className="gdlr-core-item-list  gdlr-core-item-pdlr gdlr-core-column-15 project-list" key={`new-${key}-${project.title}`}>
                                                    <div className="gdlr-core-portfolio-grid  gdlr-core-center-align gdlr-core-style-normal" style={{ "marginBottom": "25px" }}>
                                                        <div className="gdlr-core-portfolio-thumbnail gdlr-core-media-image  gdlr-core-style-margin-icon">
                                                            <div className="gdlr-core-portfolio-thumbnail-image-wrap  gdlr-core-zoom-on-hover">
                                                                <NavLink className="gdlr-core-js " to={'/project/' + project.id} data-lightbox-group="gdlr-core-img-group-1"><img src={`${server}/${project.image}`} srcSet={`${server}/${project.image} 400w, ${server}/${project.image} 700w`} sizes="(maxWidth: 767px) 100vw, 25vw" alt="" width="700" height="660" /><span className="gdlr-core-image-overlay  gdlr-core-portfolio-overlay gdlr-core-image-overlay-center-icon gdlr-core-js gdlr-core-with-margin"><span className="gdlr-core-image-overlay-content" style={{ "marginTop": "-17.8417px" }}><span className="gdlr-core-portfolio-icon-wrap"><i className="gdlr-core-portfolio-icon arrow_expand"></i></span></span>
                                                                </span>
                                                                </NavLink>
                                                            </div>
                                                        </div>
                                                        <div className="gdlr-core-portfolio-content-wrap gdlr-core-skin-divider">
                                                            <h3 className="gdlr-core-portfolio-title gdlr-core-skin-title" style={{ "fontSize": "16px", "fontWeight": "600", "letterSpacing": "0px", "textTransform": "none" }}><NavLink to={'/project/' + project.id}>{project.title}</NavLink></h3>
                                                            <span className="gdlr-core-portfolio-info gdlr-core-portfolio-info-tag gdlr-core-info-font gdlr-core-skin-caption"><a href="/#" rel="tag">{project.type}</a> <span className="gdlr-core-sep">/</span> <a href="/#" rel="tag">Projects</a></span>
                                                            <div className="gdlr-core-portfolio-content"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        projects: state.common.content.imageGallery
    }
}

export default connect(mapStateToProps, null)(Gallery);