import React, { lazy } from 'react';
import { Provider } from 'react-redux';
import store from './redux/store';

import Header from './modules/layouts/header/header.component';
// import Footer from './modules/layouts/footer/footer.component';
import Routes from './modules/layouts/routes/route.component';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import HeaderMobile from './modules/layouts/headerMobile/headerMobile.component';
// import ReactLazy from 'react-laz-y-component-loader';
import Footer from './modules/layouts/footer/footer.component';
// const Footer = lazy(() => import('./modules/layouts/footer/footer.component'));

function App() {

  return (
    <Provider store={store}>
      <BrowserRouter>
        <div className="home page-template-default page page-id-2039 gdlr-core-body rctheme-no-js inteco-body inteco-body-front inteco-full  inteco-with-sticky-navigation  inteco-blockquote-style-1 gdlr-core-link-to-lightbox" data-home-url="index.html">
          <HeaderMobile />
          <div className="inteco-body-outer-wrapper ">
            <div className="inteco-body-wrapper clearfix  inteco-with-transparent-header inteco-with-frame">
              <Header />
              <Routes />
              <Footer />
              {/* <ReactLazy><Footer /></ReactLazy> */}
            </div>
          </div>
        </div>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
