import React, { Component } from 'react';
import './footer.component.css';
import server from '../../../configs/domain';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';

class Footer extends Component {
    render() {
        let { projects, services, setting } = this.props;
        return (
            <footer>
                <div className="inteco-footer-wrapper ">
                    <div className="inteco-footer-container inteco-container clearfix">
                        <div className="inteco-footer-column inteco-item-pdlr inteco-column-20">
                            <div id="text-1" className="widget widget_text inteco-widget">
                                <div className="textwidget">
                                    <p><NavLink to="/"><LazyLoadImage effect="blur" className="alignnone size-full wp-image-5803" src="/upload/logo.png"
                                        alt="" width="120" height="24" /></NavLink>
                                        <br />
                                        <span className="gdlr-core-space-shortcode" style={{ "marginTop": "-27px" }}></span>
                                        <br /> {setting && setting.footerText}</p>
                                </div>
                            </div>
                        </div>
                        <div className="inteco-footer-column inteco-item-pdlr inteco-column-20" style={{ padding: "0px 30px" }}>
                            <div id="nav_menu-2" className="widget widget_nav_menu inteco-widget">
                                <h3 className="inteco-widget-title">Services</h3><span className="clear"></span>
                                <div className="menu-services-container">
                                    <ul id="menu-services" className="menu">
                                        {services && services.slice(0, 5).map((service, index) =>
                                            <li key={'footer-' + service.name + index} className="menu-item"><NavLink to={`/service/${service.name}`}>{service.name}</NavLink></li>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* <div className="inteco-footer-column inteco-item-pdlr inteco-column-15">
                            <div id="nav_menu-1" className="widget widget_nav_menu inteco-widget">
                                <h3 className="inteco-widget-title">Quick Links</h3><span className="clear"></span>
                                <div className="menu-quick-links-container">
                                    <ul id="menu-quick-links" className="menu">
                                        <li className="menu-item"><NavLink to="/who-we-are">About Us</NavLink></li>
                                        <li className="menu-item"><NavLink to="/contact">Contact Us</NavLink></li>
                                        <li className="menu-item"><NavLink to="/blog">Blog</NavLink></li>
                                        <li className="menu-item"><NavLink to="/get-a-quote">Get A Quote</NavLink></li>
                                    </ul>
                                </div>
                            </div>
                        </div> */}
                        <div className="inteco-footer-column inteco-item-pdlr inteco-column-20" style={{ padding: "0px 25px" }}>
                            <div id="gdlr-core-recent-portfolio-widget-2"
                                className="widget widget_gdlr-core-recent-portfolio-widget inteco-widget">
                                <h3 className="inteco-widget-title">Recent Projects</h3><span className="clear"></span>
                                <div className="gdlr-core-recent-portfolio-widget-wrap clearfix">

                                    {projects && projects.slice(0, 6).map((project, index) =>
                                        <div className="gdlr-core-recent-portfolio-widget gdlr-core-media-image" key={'footer-' + project.title + index}>
                                            <NavLink to={'/project/' + project.id}><LazyLoadImage effect="blur" src={`${server}/${project.image}`} alt="" width="150" height="150"
                                                title="port-1" /><span className="gdlr-core-image-overlay "><i
                                                    className="gdlr-core-image-overlay-icon gdlr-core-size-15 icon_link_alt"></i></span></NavLink>
                                        </div>
                                    )}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="inteco-copyright-wrapper">
                    <div className="inteco-copyright-container inteco-container clearfix">

                        {/* <div className="inteco-copyright-left inteco-item-pdlr">
                            <a href="https://www.facebook.com/dit.interior" target="_blank" rel="noreferrer">
                                <i className="fa fa-facebook"
                                    style={{ "fontSize": "17px", "color": "#af938f", "marginRight": "12px" }}></i>
                            </a>
                            <a href="https://www.youtube.com/channel/UCH_kEOE-o6hlo1X1DVermTQ" target="_blank" rel="noreferrer">
                                <i className="fa fa-youtube"
                                    style={{ "fontSize": "17px", "color": "#af938f", "marginRight": "12px" }}></i>
                            </a>
                            <a href="https://www.linkedin.com/in/design-ideas-and-technology-2a79a321a/" target="_blank" rel="noreferrer">
                                <i className="fa fa-linkedin"
                                    style={{ "fontSize": "17px", "color": "#af938f", "marginRight": "12px" }}></i>
                            </a>
                            <a href="https://www.instagram.com/ditbd" target="_blank" rel="noreferrer">
                                <i className="fa fa-instagram"
                                    style={{ "fontSize": "17px", "color": "#af938f", "marginRight": "12px" }}></i>
                            </a>
                            <a href="https://twitter.com/Infoditbd" target="_blank" rel="noreferrer">
                                <i className="fa fa-twitter"
                                    style={{ "fontSize": "17px", "color": "#af938f", "marginRight": "12px" }}></i>
                            </a>
                        </div> */}
                        <div className="inteco-copyright-right inteco-item-pdlr">Copyright 2021-2022, Design Ideas & Technology (DIT) All right reserved</div>
                    </div>
                </div>
                <a href="https://api.whatsapp.com/send?phone=+8801633033033&text=Hi%2C" style={{ position: "fixed", bottom: "95px", right: "20px", zIndex: 9 }}><LazyLoadImage effect="blur" src="/images/whatsapp.png" alt="" style={{ width: "60px" }} /></a>
            </footer>
        )
    }
}

const mapStateToProps = state => {
    return {
        projects: state.common.content.imageGallery,
        services: state.common.content.services,
        setting: state.common.content.setting
    }
}

export default connect(mapStateToProps, null)(Footer);