import React, { Component } from "react";
import { connect } from 'react-redux';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import server from '../../configs/domain';
import { LazyLoadImage } from 'react-lazy-load-image-component';
window.$ = window.jQuery;

class HomeClients extends Component {

    render() {
        var settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 5,
            slidesToScroll: 5,
            autoplay: true,
            autoplaySpeed: 2000,
            initialSlide: 0,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return (
            <>
                <div className="gdlr-core-pbf-wrapper " style={{ "margin": "0px 0px 0px 0px",paddingTop:"40px" }}>
                    <div className="gdlr-core-pbf-background-wrap"></div>
                    <div className="gdlr-core-pbf-wrapper-content ">
                        <div className="gdlr-core-pbf-element">
                            <div className="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-center-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                                style={{ "paddingBottom": "40px" }}>
                                <div className="gdlr-core-title-item-title-wrap ">
                                    <h3 className="gdlr-core-title-item-title gdlr-core-skin-title "
                                        style={{ "fontSize": "32px", "letterSpacing": "0px", "textTransform": "none" }}>
                                        Our Valuable Customers </h3>
                                </div>
                            </div>
                        </div>
                        <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
                            <div className="gdlr-core-pbf-column gdlr-core-column-60 gdlr-core-column-first">
                                <div className="gdlr-core-pbf-column-content-margin gdlr-core-js "
                                    style={{ "margin": "0px 20px 0px 20px", "padding": "60px 0px 0px 0px" }}>
                                    <div className="gdlr-core-pbf-background-wrap"
                                        style={{ "boxShadow": "0 0 45px rgba(10, 10, 10,0.06)", "MozBoxShadow": "0 0 45px rgba(10, 10, 10,0.06)", "WebkitBoxShadow": "0 0 45px rgba(10, 10, 10,0.06)", "backgroundColor": "#ffffff" }}>

                                    </div>
                                    <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js home-client">
                                        <div className="gdlr-core-pbf-element">
                                            <div className="gdlr-core-gallery-item gdlr-core-item-pdb clearfix  gdlr-core-gallery-item-style-grid"
                                                style={{ "paddingBottom": "25px" }}>
                                                <div className="gdlr-core-gallery-item-holder gdlr-core-js-2 clearfix"
                                                    data-layout="fitrows">

                                                    {this.props.clients && <Slider {...settings}>
                                                        {
                                                            this.props.clients.map(client =>
                                                                <div key={"client-" + client.id}
                                                                    className="gdlr-core-item-list gdlr-core-gallery-column  gdlr-core-column-12 gdlr-core-item-pdlr gdlr-core-item-mgb">
                                                                    <div className="gdlr-core-gallery-list gdlr-core-media-image">
                                                                        <a className="gdlr-core-lightgallery gdlr-core-js "
                                                                            href={`${server}/${client.image}`}
                                                                            data-lightbox-group="gdlr-core-img-group-3">{<LazyLoadImage effect="blur"
                                                                                src={`${server}/${client.image}`} alt="" width="137"
                                                                                height="103" title="banner-1" />}</a>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        {/* <div
                                                            className="gdlr-core-item-list gdlr-core-gallery-column  gdlr-core-column-12 gdlr-core-column-first gdlr-core-item-pdlr gdlr-core-item-mgb">
                                                            <div className="gdlr-core-gallery-list gdlr-core-media-image">
                                                                <a className="gdlr-core-lightgallery gdlr-core-js "
                                                                    href="upload/banner-1.png"
                                                                    data-lightbox-group="gdlr-core-img-group-3"><LazyLoadImage effect="blur"
                                                                        src="upload/banner-1.png" alt="" width="137"
                                                                        height="103" title="banner-1" /></a>
                                                            </div>
                                                        </div> */}
                                                        {/* <div
                                                            className="gdlr-core-item-list gdlr-core-gallery-column  gdlr-core-column-12 gdlr-core-item-pdlr gdlr-core-item-mgb">
                                                            <div className="gdlr-core-gallery-list gdlr-core-media-image">
                                                                <a className="gdlr-core-lightgallery gdlr-core-js "
                                                                    href="upload/banner-2.png"
                                                                    data-lightbox-group="gdlr-core-img-group-3"><LazyLoadImage effect="blur"
                                                                        src="upload/banner-2.png" alt="" width="137"
                                                                        height="103" title="banner-2" /></a>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="gdlr-core-item-list gdlr-core-gallery-column  gdlr-core-column-12 gdlr-core-item-pdlr gdlr-core-item-mgb">
                                                            <div className="gdlr-core-gallery-list gdlr-core-media-image">
                                                                <a className="gdlr-core-lightgallery gdlr-core-js "
                                                                    href="upload/banner-3.png"
                                                                    data-lightbox-group="gdlr-core-img-group-3"><LazyLoadImage effect="blur"
                                                                        src="upload/banner-3.png" alt="" width="137"
                                                                        height="103" title="banner-3" /></a>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="gdlr-core-item-list gdlr-core-gallery-column  gdlr-core-column-12 gdlr-core-item-pdlr gdlr-core-item-mgb">
                                                            <div className="gdlr-core-gallery-list gdlr-core-media-image">
                                                                <a className="gdlr-core-lightgallery gdlr-core-js "
                                                                    href="upload/banner-4.png"
                                                                    data-lightbox-group="gdlr-core-img-group-3"><LazyLoadImage effect="blur"
                                                                        src="upload/banner-4.png" alt="" width="137"
                                                                        height="103" title="banner-4" /></a>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="gdlr-core-item-list gdlr-core-gallery-column  gdlr-core-column-12 gdlr-core-item-pdlr gdlr-core-item-mgb">
                                                            <div className="gdlr-core-gallery-list gdlr-core-media-image">
                                                                <a className="gdlr-core-lightgallery gdlr-core-js "
                                                                    href="upload/banner-5.png"
                                                                    data-lightbox-group="gdlr-core-img-group-3"><LazyLoadImage effect="blur"
                                                                        src="upload/banner-5.png" alt="" width="137"
                                                                        height="103" title="banner-5" /></a>
                                                            </div>
                                                        </div> */}
                                                    </Slider>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        clients: state.common.content.clients
    }
}

export default connect(mapStateToProps, null)(HomeClients);