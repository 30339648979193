import React, { Component } from 'react';
import server from '../../configs/domain';
import { connect } from 'react-redux';

class BlogDetails extends Component {
    componentDidMount() {
    }
    render() {
        return (
            <div>
                <div className="inteco-page-title-wrap  inteco-style-custom inteco-center-align">
                    {/* <div className="inteco-header-transparent-substitute"></div> */}
                    <div className="inteco-page-title-overlay"></div>
                    <div className="inteco-page-title-container inteco-container">
                        <div className="inteco-page-title-content inteco-item-pdlr">
                            {/* <h1 className="inteco-page-title">Latest Blogs</h1>
                            <div className="inteco-page-caption">Caption aligned here</div> */}
                        </div>
                    </div>
                </div>
                <div className="inteco-page-wrapper" id="inteco-page-wrapper">


                    <div className="inteco-content-container inteco-container single-post" >
                        <div className=" inteco-sidebar-wrap clearfix inteco-line-height-0 inteco-sidebar-style-none">
                            <div className=" inteco-sidebar-center inteco-column-60 inteco-line-height">
                                <div className="inteco-content-wrap inteco-item-pdlr clearfix">
                                    <div className="inteco-content-area">
                                        <article id="post-1227" className="post-1227 post type-post status-publish format-gallery hentry category-post-format tag-post-format post_format-post-format-gallery">
                                            <div className="inteco-single-article clearfix">

                                                {this.props.blogs && this.props.blogs.map((blog, index) =>
                                                    blog.id === parseInt(this.props.match.params.id) &&
                                                    <>
                                                        {/* <div className="gdlr-core-blog-grid-frame">
                                                            <div className="gdlr-core-blog-grid-title-wrap">
                                                                <h3 className="gdlr-core-blog-title gdlr-core-skin-title" style={{ "fontSize": "25px", "fontWeight": 700, "letterSpacing": "0px" }}><a href="/#" >{blog.title}</a></h3>
                                                                <div className="gdlr-core-blog-info-wrapper gdlr-core-skin-divider"><span className="gdlr-core-blog-info gdlr-core-blog-info-font gdlr-core-skin-caption gdlr-core-blog-info-date"><a href="/#">{new Date(blog.createdAt).toUTCString()}</a></span><span className="gdlr-core-blog-info gdlr-core-blog-info-font gdlr-core-skin-caption gdlr-core-blog-info-author"><span className="gdlr-core-head" >By</span><a href="/#" title="Posts by John Smith" rel="author">Admin</a></span><span className="gdlr-core-blog-info gdlr-core-blog-info-font gdlr-core-skin-caption gdlr-core-blog-info-category"><span className="gdlr-core-head" >In</span><a href="/#" rel="tag">Blogs</a></span><span className="gdlr-core-blog-info gdlr-core-blog-info-font gdlr-core-skin-caption gdlr-core-blog-info-comment-number"></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <br /> */}
                                                        <div className="inteco-single-article-thumbnail inteco-media-gallery">
                                                            <div className="gdlr-core-gallery-shortcode-grid-fixed clearfix">
                                                                {/* <div className=" gdlr-core-gallery-shortcode-item gdlr-core-media-image  gdlr-core-first">
                                                                    <a className="gdlr-core-lightgallery gdlr-core-js " href={`${server}/${blog.image}`} data-lightbox-group="gdlr-core-img-group-1">
                                                                        <img src={`${server}/${blog.image}`} alt="" />
                                                                    </a>
                                                                </div> */}
                                                                <div className="gdlr-core-gallery-shortcode-item-right">
                                                                    <img src={`${server}/${blog.image}`} alt="" style={{ width: "100%" }} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="inteco-single-article-content">
                                                            {/* <div className="inteco-single-social-share inteco-item-rvpdlr">
                                                                <div className="gdlr-core-social-share-item gdlr-core-item-pdb  gdlr-core-left-align gdlr-core-social-share-right-text gdlr-core-item-mglr gdlr-core-style-color" style={{ paddingBottom: "0px" }}>
                                                                    <span className="gdlr-core-social-share-wrap">

                                                                    </span>
                                                                    <span className="gdlr-core-social-share-count">
                                                                        <span className="gdlr-core-divider gdlr-core-skin-divider"></span>
                                                                        <span className="gdlr-core-count">0</span>
                                                                        <span className="gdlr-core-suffix">Shares</span>
                                                                    </span>
                                                                </div>
                                                            </div> */}
                                                            <div className="gdlr-core-blog-grid-frame">
                                                                <div className="gdlr-core-blog-grid-title-wrap">
                                                                    <h3 className="gdlr-core-blog-title gdlr-core-skin-title" style={{ "fontSize": "25px", "fontWeight": 700, "letterSpacing": "0px" }}><a href="/#" >{blog.title}</a></h3>
                                                                    <div className="gdlr-core-blog-info-wrapper gdlr-core-skin-divider"><span className="gdlr-core-blog-info gdlr-core-blog-info-font gdlr-core-skin-caption gdlr-core-blog-info-date"><a href="/#">{new Date(blog.createdAt).toUTCString()}</a></span><span className="gdlr-core-blog-info gdlr-core-blog-info-font gdlr-core-skin-caption gdlr-core-blog-info-author"><span className="gdlr-core-head" >By</span><a href="/#" title="Posts by John Smith" rel="author">Admin</a></span><span className="gdlr-core-blog-info gdlr-core-blog-info-font gdlr-core-skin-caption gdlr-core-blog-info-category"><span className="gdlr-core-head" >In</span><a href="/#" rel="tag">Blogs</a></span><span className="gdlr-core-blog-info gdlr-core-blog-info-font gdlr-core-skin-caption gdlr-core-blog-info-comment-number"></span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <br />
                                                            <p><div dangerouslySetInnerHTML={{ __html: blog.description }} /></p>
                                                        </div>
                                                    </>
                                                )}

                                            </div>
                                            {/* Single Artecle */}
                                        </article>
                                        {/* Post Id */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        blogs: state.common.content.blogs
    }
}

export default connect(mapStateToProps, null)(BlogDetails);