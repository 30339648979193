import React, { Component } from 'react';

class Team extends Component {
    componentDidMount() {
        // var loadScript = function (src) {
        //     var tag = document.createElement('script');
        //     tag.async = false;
        //     tag.src = src;
        //     var body = document.getElementsByTagName('body')[0];
        //     body.appendChild(tag);
        // }

        // loadScript('js/plugins.js');
    }
    render() {
        return (
            <div>
                <div className="inteco-page-title-wrap  inteco-style-custom inteco-center-align">
                    {/* <div className="inteco-header-transparent-substitute"></div> */}
                    <div className="inteco-page-title-overlay"></div>
                    <div className="inteco-page-title-container inteco-container">
                        <div className="inteco-page-title-content inteco-item-pdlr">
                            {/* <h1 className="inteco-page-title">Our Team</h1> */}
                        </div>
                    </div>
                </div>
                <div className="inteco-page-wrapper" id="inteco-page-wrapper">
                    <div className="gdlr-core-page-builder-body">
                        <div className="gdlr-core-pbf-wrapper " style={{ "padding": "95px 0px 30px 0px" }} id="gdlr-core-wrapper-1">
                            <div className="gdlr-core-pbf-background-wrap">
                                <div className="gdlr-core-pbf-background gdlr-core-parallax gdlr-core-js" style={{ "backgroundImage": "url(upload/tittle-bg-top-1.png)", "backgroundRepeat": "no-repeat", "backgroundPosition": "top center" }} data-parallax-speed="0.1"></div>
                            </div>
                            <div className="gdlr-core-pbf-wrapper-content gdlr-core-js ">
                                <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
                                    <div className="gdlr-core-pbf-column gdlr-core-column-30 gdlr-core-column-first">
                                        <div className="gdlr-core-pbf-column-content-margin gdlr-core-js ">
                                            <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                                                <div className="gdlr-core-pbf-element">
                                                    <div className="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr">
                                                        <div className="gdlr-core-title-item-title-wrap  gdlr-core-with-divider">
                                                            <h3 className="gdlr-core-title-item-title gdlr-core-skin-title " style={{ "fontSize": "33px", "letterSpacing": "0px", "textTransform": "none", color: "#111111" }}>Super Team .</h3>
                                                            <div className="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider" style={{ "borderColor": "#9a827d" }}></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="gdlr-core-pbf-column gdlr-core-column-30">
                                        <div className="gdlr-core-pbf-column-content-margin gdlr-core-js ">
                                            <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                                                <div className="gdlr-core-pbf-element">
                                                    <div className="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                                                        <div className="gdlr-core-text-box-item-content" style={{ "fontSize": "18px", "fontWeight": 400, "textTransform": "none" }}>
                                                            <p>A wonderful serenity has taken possession of my entire soul, like these sweet mornings of spring which I enjoy with my whole heart. <span style={{ "color": "#b6958e" }}>I am alone, and feel the charm of existence in this spot</span>, which was created for the bliss of souls like mine. I am so happy, my dear friend, so absorbed in the exquisite sense of mere tranquil existence, that I neglect my talents. I should be incapable of drawing a single stroke at the present moment; and yet I feel that I never was a greater.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="gdlr-core-pbf-wrapper " style={{ "padding": "0px 0px 0px 0px" }}>
                            <div className="gdlr-core-pbf-background-wrap"></div>
                            <div className="gdlr-core-pbf-wrapper-content gdlr-core-js ">
                                <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
                                    <div className="gdlr-core-pbf-element">
                                        <div className="gdlr-core-personnel-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-personnel-item-style-grid gdlr-core-personnel-style-grid gdlr-core-with-divider ">
                                            <div className="gdlr-core-personnel-list-column  gdlr-core-column-15 gdlr-core-column-first gdlr-core-item-pdlr">
                                                <div className="gdlr-core-personnel-list clearfix">
                                                    <div className="gdlr-core-personnel-list-image gdlr-core-media-image  gdlr-core-opacity-on-hover gdlr-core-zoom-on-hover">
                                                        <a href="../personnel/john-balmer/index.html"><img src="upload/personnel-1-1.jpg" alt="" width="600" height="718" title="personnel-1-1" /></a>
                                                    </div>
                                                    <div className="gdlr-core-personnel-list-content-wrap">
                                                        <h3 className="gdlr-core-personnel-list-title" style={{ "fontSize": "20px", "letterSpacing": "0px", "textTransform": "none" }}><a href="javascript:void(0)" >John Balmer</a></h3>
                                                        <div className="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption" style={{ "fontSize": "15px", "fontStyle": "normal" }}>CEO & Founder</div>
                                                        <div className="gdlr-core-personnel-list-divider gdlr-core-skin-divider"></div>
                                                        {/* <div className="gdlr-core-personnel-list-social">
                                                            <div className="gdlr-core-social-network-item gdlr-core-item-pdb  gdlr-core-none-align" style={{ "paddingBottom": "0px" }}><a href="/#" ref="noreferrer" target="_blank" className="gdlr-core-social-network-icon" title="facebook"><i className="fa fa-facebook" ></i></a><a href="/#" target="_blank" className="gdlr-core-social-network-icon" title="linkedin"><i className="fa fa-linkedin" ></i></a><a href="/#" target="_blank" className="gdlr-core-social-network-icon" title="pinterest"><i className="fa fa-pinterest-p" ></i></a><a href="/#" target="_blank" className="gdlr-core-social-network-icon" title="twitter"><i className="fa fa-twitter" ></i></a></div>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="gdlr-core-personnel-list-column  gdlr-core-column-15 gdlr-core-item-pdlr">
                                                <div className="gdlr-core-personnel-list clearfix">
                                                    <div className="gdlr-core-personnel-list-image gdlr-core-media-image  gdlr-core-opacity-on-hover gdlr-core-zoom-on-hover">
                                                        <a href="../personnel/marry-scott/index.html"><img src="upload/personnel-2-1.jpg" alt="" width="600" height="718" title="personnel-2-1" /></a>
                                                    </div>
                                                    <div className="gdlr-core-personnel-list-content-wrap">
                                                        <h3 className="gdlr-core-personnel-list-title" style={{ "fontSize": "20px", "letterSpacing": "0px", "textTransform": "none" }}><a href="../personnel/marry-scott/index.html" >Marry Scott</a></h3>
                                                        <div className="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption" style={{ "fontSize": "15px", "fontStyle": "normal" }}>Planning Manager</div>
                                                        <div className="gdlr-core-personnel-list-divider gdlr-core-skin-divider"></div>
                                                        {/* <div className="gdlr-core-personnel-list-social">
                                                            <div className="gdlr-core-social-network-item gdlr-core-item-pdb  gdlr-core-none-align" style={{ "paddingBottom": "0px" }}><a href="/#" target="_blank" className="gdlr-core-social-network-icon" title="facebook"><i className="fa fa-facebook" ></i></a><a href="/#" target="_blank" className="gdlr-core-social-network-icon" title="linkedin"><i className="fa fa-linkedin" ></i></a><a href="/#" target="_blank" className="gdlr-core-social-network-icon" title="pinterest"><i className="fa fa-pinterest-p" ></i></a><a href="/3" target="_blank" className="gdlr-core-social-network-icon" title="twitter"><i className="fa fa-twitter" ></i></a></div>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="gdlr-core-personnel-list-column  gdlr-core-column-15 gdlr-core-item-pdlr">
                                                <div className="gdlr-core-personnel-list clearfix">
                                                    <div className="gdlr-core-personnel-list-image gdlr-core-media-image  gdlr-core-opacity-on-hover gdlr-core-zoom-on-hover">
                                                        <a href="../personnel/jennette-jones/index.html"><img src="upload/personnel-3-1.jpg" alt="" width="600" height="718" title="personnel-3-1" /></a>
                                                    </div>
                                                    <div className="gdlr-core-personnel-list-content-wrap">
                                                        <h3 className="gdlr-core-personnel-list-title" style={{ "fontSize": "20px", "letterSpacing": "0px", "textTransform": "none" }}><a href="../personnel/jennette-jones/index.html" >Jennette Jones</a></h3>
                                                        <div className="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption" style={{ "fontSize": "15px", "fontStyle": "normal" }}>Senior Interior</div>
                                                        <div className="gdlr-core-personnel-list-divider gdlr-core-skin-divider"></div>
                                                        {/* <div className="gdlr-core-personnel-list-social">
                                                            <div className="gdlr-core-social-network-item gdlr-core-item-pdb  gdlr-core-none-align" style={{ "paddingBottom": "0px" }}><a href="/#" target="_blank" className="gdlr-core-social-network-icon" title="facebook"><i className="fa fa-facebook" ></i></a><a href="/#" target="_blank" className="gdlr-core-social-network-icon" title="linkedin"><i className="fa fa-linkedin" ></i></a><a href="/#" target="_blank" className="gdlr-core-social-network-icon" title="pinterest"><i className="fa fa-pinterest-p" ></i></a><a href="/#" target="_blank" className="gdlr-core-social-network-icon" title="twitter"><i className="fa fa-twitter" ></i></a></div>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="gdlr-core-personnel-list-column  gdlr-core-column-15 gdlr-core-item-pdlr">
                                                <div className="gdlr-core-personnel-list clearfix">
                                                    <div className="gdlr-core-personnel-list-image gdlr-core-media-image  gdlr-core-opacity-on-hover gdlr-core-zoom-on-hover">
                                                        <a href="../personnel/joseph-smith/index.html"><img src="upload/personnel-4-1.jpg" alt="" width="600" height="718" title="personnel-4-1" /></a>
                                                    </div>
                                                    <div className="gdlr-core-personnel-list-content-wrap">
                                                        <h3 className="gdlr-core-personnel-list-title" style={{ "fontSize": "20px", "letterSpacing": "0px", "textTransform": "none" }}><a href="../personnel/joseph-smith/index.html" >Joseph Smith</a></h3>
                                                        <div className="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption" style={{ "fontSize": "15px", "fontStyle": "normal" }}>Project Manager</div>
                                                        <div className="gdlr-core-personnel-list-divider gdlr-core-skin-divider"></div>
                                                        {/* <div className="gdlr-core-personnel-list-social">
                                                            <div className="gdlr-core-social-network-item gdlr-core-item-pdb  gdlr-core-none-align" style={{ "paddingBottom": "0px" }}><a href="/#" target="_blank" className="gdlr-core-social-network-icon" title="facebook"><i className="fa fa-facebook" ></i></a><a href="/#" target="_blank" className="gdlr-core-social-network-icon" title="linkedin"><i className="fa fa-linkedin" ></i></a><a href="/#" target="_blank" className="gdlr-core-social-network-icon" title="pinterest"><i className="fa fa-pinterest-p" ></i></a><a href="/#" target="_blank" className="gdlr-core-social-network-icon" title="twitter"><i className="fa fa-twitter" ></i></a></div>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="gdlr-core-personnel-list-column  gdlr-core-column-15 gdlr-core-column-first gdlr-core-item-pdlr">
                                                <div className="gdlr-core-personnel-list clearfix">
                                                    <div className="gdlr-core-personnel-list-image gdlr-core-media-image  gdlr-core-opacity-on-hover gdlr-core-zoom-on-hover">
                                                        <a href="../personnel/paula-james/index.html"><img src="upload/personnel-5-1.jpg" alt="" width="600" height="718" title="personnel-5-1" /></a>
                                                    </div>
                                                    <div className="gdlr-core-personnel-list-content-wrap">
                                                        <h3 className="gdlr-core-personnel-list-title" style={{ "fontSize": "20px", "letterSpacing": "0px", "textTransform": "none" }}><a href="../personnel/paula-james/index.html" >Paula James</a></h3>
                                                        <div className="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption" style={{ "fontSize": "15px", "fontStyle": "normal" }}>Company’s Secretary</div>
                                                        <div className="gdlr-core-personnel-list-divider gdlr-core-skin-divider"></div>
                                                        {/* <div className="gdlr-core-personnel-list-social">
                                                            <div className="gdlr-core-social-network-item gdlr-core-item-pdb  gdlr-core-none-align" style={{ "paddingBottom": "0px" }}><a href="/#" target="_blank" className="gdlr-core-social-network-icon" title="facebook"><i className="fa fa-facebook" ></i></a><a href="/#" target="_blank" className="gdlr-core-social-network-icon" title="linkedin"><i className="fa fa-linkedin" ></i></a><a href="/#" target="_blank" className="gdlr-core-social-network-icon" title="pinterest"><i className="fa fa-pinterest-p" ></i></a><a href="/#" target="_blank" className="gdlr-core-social-network-icon" title="twitter"><i className="fa fa-twitter" ></i></a></div>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="gdlr-core-personnel-list-column  gdlr-core-column-15 gdlr-core-item-pdlr">
                                                <div className="gdlr-core-personnel-list clearfix">
                                                    <div className="gdlr-core-personnel-list-image gdlr-core-media-image  gdlr-core-opacity-on-hover gdlr-core-zoom-on-hover">
                                                        <a href="../personnel/steve-cook/index.html"><img src="upload/personnel-6-1.jpg" alt="" width="600" height="718" title="personnel-6-1" /></a>
                                                    </div>
                                                    <div className="gdlr-core-personnel-list-content-wrap">
                                                        <h3 className="gdlr-core-personnel-list-title" style={{ "fontSize": "20px", "letterSpacing": "0px", "textTransform": "none" }}><a href="../personnel/steve-cook/index.html" >Steve Cook</a></h3>
                                                        <div className="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption" style={{ "fontSize": "15px", "fontStyle": "normal" }}>Architecture Head</div>
                                                        <div className="gdlr-core-personnel-list-divider gdlr-core-skin-divider"></div>
                                                        {/* <div className="gdlr-core-personnel-list-social">
                                                            <div className="gdlr-core-social-network-item gdlr-core-item-pdb  gdlr-core-none-align" style={{ "paddingBottom": "0px" }}><a href="/#" target="_blank" className="gdlr-core-social-network-icon" title="facebook"><i className="fa fa-facebook" ></i></a><a href="/#" target="_blank" className="gdlr-core-social-network-icon" title="linkedin"><i className="fa fa-linkedin" ></i></a><a href="/#" target="_blank" className="gdlr-core-social-network-icon" title="pinterest"><i className="fa fa-pinterest-p" ></i></a><a href="/#" target="_blank" className="gdlr-core-social-network-icon" title="twitter"><i className="fa fa-twitter" ></i></a></div>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="gdlr-core-personnel-list-column  gdlr-core-column-15 gdlr-core-item-pdlr">
                                                <div className="gdlr-core-personnel-list clearfix">
                                                    <div className="gdlr-core-personnel-list-image gdlr-core-media-image  gdlr-core-opacity-on-hover gdlr-core-zoom-on-hover">
                                                        <a href="../personnel/abby-ashton/index.html"><img src="upload/personnel-7-1.jpg" alt="" width="600" height="718" title="personnel-7-1" /></a>
                                                    </div>
                                                    <div className="gdlr-core-personnel-list-content-wrap">
                                                        <h3 className="gdlr-core-personnel-list-title" style={{ "fontSize": "20px", "letterSpacing": "0px", "textTransform": "none" }}><a href="../personnel/abby-ashton/index.html" >Abby Ashton</a></h3>
                                                        <div className="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption" style={{ "fontSize": "15px", "fontStyle": "normal" }}>Lighting Manager</div>
                                                        <div className="gdlr-core-personnel-list-divider gdlr-core-skin-divider"></div>
                                                        {/* <div className="gdlr-core-personnel-list-social">
                                                            <div className="gdlr-core-social-network-item gdlr-core-item-pdb  gdlr-core-none-align" style={{ "paddingBottom": "0px" }}><a href="/#" target="_blank" className="gdlr-core-social-network-icon" title="facebook"><i className="fa fa-facebook" ></i></a><a href="/#" target="_blank" className="gdlr-core-social-network-icon" title="linkedin"><i className="fa fa-linkedin" ></i></a><a href="/#" target="_blank" className="gdlr-core-social-network-icon" title="pinterest"><i className="fa fa-pinterest-p" ></i></a><a href="/#" target="_blank" className="gdlr-core-social-network-icon" title="twitter"><i className="fa fa-twitter" ></i></a></div>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="gdlr-core-personnel-list-column  gdlr-core-column-15 gdlr-core-item-pdlr">
                                                <div className="gdlr-core-personnel-list clearfix">
                                                    <div className="gdlr-core-personnel-list-image gdlr-core-media-image  gdlr-core-opacity-on-hover gdlr-core-zoom-on-hover">
                                                        <a href="../personnel/paul-smith/index.html"><img src="upload/personnel-8-1.jpg" alt="" width="600" height="718" title="personnel-8-1" /></a>
                                                    </div>
                                                    <div className="gdlr-core-personnel-list-content-wrap">
                                                        <h3 className="gdlr-core-personnel-list-title" style={{ "fontSize": "20px", "letterSpacing": "0px", "textTransform": "none" }}><a href="../personnel/paul-smith/index.html" >Paul Smith</a></h3>
                                                        <div className="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption" style={{ "fontSize": "15px", "fontStyle": "normal" }}>CFO</div>
                                                        <div className="gdlr-core-personnel-list-divider gdlr-core-skin-divider"></div>
                                                        {/* <div className="gdlr-core-personnel-list-social">
                                                            <div className="gdlr-core-social-network-item gdlr-core-item-pdb  gdlr-core-none-align" style={{ "paddingBottom": "0px" }}><a href="/#" target="_blank" className="gdlr-core-social-network-icon" title="facebook"><i className="fa fa-facebook" ></i></a><a href="/#" target="_blank" className="gdlr-core-social-network-icon" title="linkedin"><i className="fa fa-linkedin" ></i></a><a href="/#" target="_blank" className="gdlr-core-social-network-icon" title="pinterest"><i className="fa fa-pinterest-p" ></i></a><a href="/#" target="_blank" className="gdlr-core-social-network-icon" title="twitter"><i className="fa fa-twitter" ></i></a></div>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="gdlr-core-pbf-wrapper " style={{"padding": "0px 0px 0px 0px"}}>
                            <div className="gdlr-core-pbf-background-wrap" style={{"backgroundColor": "#f7f7f7"}}></div>
                            <div className="gdlr-core-pbf-wrapper-content gdlr-core-js ">
                                <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
                                    <div className="gdlr-core-pbf-column gdlr-core-column-30 gdlr-core-column-first">
                                        <div className="gdlr-core-pbf-column-content-margin gdlr-core-js  gdlr-core-column-extend-left" data-sync-height="height1">
                                            <div className="gdlr-core-pbf-background-wrap">
                                                <div className="gdlr-core-pbf-background gdlr-core-parallax gdlr-core-js" style={{"backgroundImage": "url(upload/gdtography-310706-unsplash.jpg)", "backgroundSize": "cover", "backgroundPosition": "center"}} data-parallax-speed="0.2"></div>
                                            </div>
                                            <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js  gdlr-core-sync-height-content"></div>
                                        </div>
                                    </div>
                                    <div className="gdlr-core-pbf-column gdlr-core-column-30" data-skin="team column svc  icon" id="gdlr-core-column-1">
                                        <div className="gdlr-core-pbf-column-content-margin gdlr-core-js " style={{"padding": "110px 0px 40px 70px"}} data-sync-height="height1">
                                            <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js  gdlr-core-sync-height-content">
                                                <div className="gdlr-core-pbf-element">
                                                    <div className="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-bottom gdlr-core-item-pdlr" style="paddingBottom: 40px ;">
                                                        <div className="gdlr-core-title-item-title-wrap ">
                                                            <h3 className="gdlr-core-title-item-title gdlr-core-skin-title " style="fontSize: 25px ;letterSpacing: 0px ;textTransform: none ;color: #2b2b2b ;">Why Choose Us?</h3></div>
                                                    </div>
                                                </div>
                                                <div className="gdlr-core-pbf-element">
                                                    <div className="gdlr-core-column-service-item gdlr-core-item-pdb  gdlr-core-left-align gdlr-core-column-service-icon-left gdlr-core-no-caption gdlr-core-item-pdlr" style="paddingBottom: 15px;">
                                                        <div className="gdlr-core-column-service-media gdlr-core-media-image" style="marginTop: 10px;marginRight: 30px;"><img src="upload/columsvc-icon-1.png" alt="" width="37" height="37" title="columsvc-icon-1" /></div>
                                                        <div className="gdlr-core-column-service-content-wrapper">
                                                            <div className="gdlr-core-column-service-title-wrap" style="marginBottom: 10px ;">
                                                                <h3 className="gdlr-core-column-service-title gdlr-core-skin-title" style="fontSize: 21px ;fontWeight: 700 ;textTransform: none ;">On Time Service</h3></div>
                                                            <div className="gdlr-core-column-service-content" style="fontSize: 18px ;fontWeight: 400 ;textTransform: none ;">
                                                                <p>A wonderful serenity has taken possession of my entire soul, like these sweet mornings of spring which I enjoy with.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="gdlr-core-pbf-element">
                                                    <div className="gdlr-core-column-service-item gdlr-core-item-pdb  gdlr-core-left-align gdlr-core-column-service-icon-left gdlr-core-no-caption gdlr-core-item-pdlr" style="paddingBottom: 15px;">
                                                        <div className="gdlr-core-column-service-media gdlr-core-media-image" style="marginTop: 10px;marginRight: 25px;"><img src="upload/columsvc-icon-2.png" alt="" width="46" height="46" title="columsvc-icon-2" /></div>
                                                        <div className="gdlr-core-column-service-content-wrapper">
                                                            <div className="gdlr-core-column-service-title-wrap" style="marginBottom: 10px ;">
                                                                <h3 className="gdlr-core-column-service-title gdlr-core-skin-title" style="fontSize: 21px ;fontWeight: 700 ;textTransform: none ;">A Team Of Professionals</h3></div>
                                                            <div className="gdlr-core-column-service-content" style="fontSize: 18px ;fontWeight: 400 ;textTransform: none ;">
                                                                <p>A wonderful serenity has taken possession of my entire soul, like these sweet mornings of spring which I enjoy with.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="gdlr-core-pbf-element">
                                                    <div className="gdlr-core-column-service-item gdlr-core-item-pdb  gdlr-core-left-align gdlr-core-column-service-icon-left gdlr-core-no-caption gdlr-core-item-pdlr" style="paddingBottom: 25px;">
                                                        <div className="gdlr-core-column-service-media gdlr-core-media-image" style="marginTop: 10px;marginRight: 27px;marginBottom:  ;"><img src="upload/columsvc-icon-3.png" alt="" width="42" height="41" title="columsvc-icon-3" /></div>
                                                        <div className="gdlr-core-column-service-content-wrapper">
                                                            <div className="gdlr-core-column-service-title-wrap" style="marginBottom: 10px ;">
                                                                <h3 className="gdlr-core-column-service-title gdlr-core-skin-title" style="fontSize: 21px ;fontWeight: 700 ;textTransform: none ;">Long Experiences In This Field</h3></div>
                                                            <div className="gdlr-core-column-service-content" style="fontSize: 18px ;fontWeight: 400 ;textTransform: none ;">
                                                                <p>A wonderful serenity has taken possession of my entire soul, like these sweet mornings of spring which I enjoy with.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="gdlr-core-pbf-wrapper " style="padding: 140px 0px 150px 0px;" id="gdlr-core-wrapper-2">
                            <div className="gdlr-core-pbf-background-wrap"></div>
                            <div className="gdlr-core-pbf-wrapper-content gdlr-core-js ">
                                <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
                                    <div className="gdlr-core-pbf-column gdlr-core-column-15 gdlr-core-column-first" data-skin="Skin Number">
                                        <div className="gdlr-core-pbf-column-content-margin gdlr-core-js ">
                                            <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                                                <div className="gdlr-core-pbf-element">
                                                    <div className="gdlr-core-counter-item gdlr-core-item-pdlr gdlr-core-item-pdb ">
                                                        <div className="gdlr-core-counter-item-number gdlr-core-skin-title gdlr-core-title-font" style="fontSize: 35px ;fontWeight: 800 ;"><span className="gdlr-core-counter-item-count gdlr-core-js" data-duration="" data-counter-start="0" data-counter-end="345">0</span></div>
                                                        <div className="gdlr-core-counter-item-bottom-text gdlr-core-skin-content" style="fontWeight: 700 ;">Happy Clients</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="gdlr-core-pbf-column gdlr-core-column-15" data-skin="Skin Number">
                                        <div className="gdlr-core-pbf-column-content-margin gdlr-core-js ">
                                            <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                                                <div className="gdlr-core-pbf-element">
                                                    <div className="gdlr-core-counter-item gdlr-core-item-pdlr gdlr-core-item-pdb ">
                                                        <div className="gdlr-core-counter-item-number gdlr-core-skin-title gdlr-core-title-font" style="fontSize: 35px ;fontWeight: 800 ;"><span className="gdlr-core-counter-item-count gdlr-core-js" data-duration="" data-counter-start="0" data-counter-end="934">0</span></div>
                                                        <div className="gdlr-core-counter-item-bottom-text gdlr-core-skin-content" style="fontWeight: 700 ;">Amazing works</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="gdlr-core-pbf-column gdlr-core-column-15" data-skin="Skin Number">
                                        <div className="gdlr-core-pbf-column-content-margin gdlr-core-js ">
                                            <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                                                <div className="gdlr-core-pbf-element">
                                                    <div className="gdlr-core-counter-item gdlr-core-item-pdlr gdlr-core-item-pdb ">
                                                        <div className="gdlr-core-counter-item-number gdlr-core-skin-title gdlr-core-title-font" style="fontSize: 35px ;fontWeight: 800 ;"><span className="gdlr-core-counter-item-count gdlr-core-js" data-duration="" data-counter-start="0" data-counter-end="20">0</span><span className="gdlr-core-counter-item-suffix">+</span></div>
                                                        <div className="gdlr-core-counter-item-bottom-text gdlr-core-skin-content" style="fontWeight: 700 ;">Awards winning</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="gdlr-core-pbf-column gdlr-core-column-15" data-skin="Skin Number">
                                        <div className="gdlr-core-pbf-column-content-margin gdlr-core-js ">
                                            <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                                                <div className="gdlr-core-pbf-element">
                                                    <div className="gdlr-core-counter-item gdlr-core-item-pdlr gdlr-core-item-pdb ">
                                                        <div className="gdlr-core-counter-item-number gdlr-core-skin-title gdlr-core-title-font" style="fontSize: 35px ;fontWeight: 800 ;"><span className="gdlr-core-counter-item-count gdlr-core-js" data-duration="" data-counter-start="0" data-counter-end="16">0</span></div>
                                                        <div className="gdlr-core-counter-item-bottom-text gdlr-core-skin-content" style="fontWeight: 700 ;">operated Years</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="gdlr-core-pbf-wrapper " style="margin: 0px 0px 0px 0px;padding: 0px 0px 150px 0px;" id="gdlr-core-wrapper-3">
                            <div className="gdlr-core-pbf-background-wrap">
                                <div className="gdlr-core-pbf-background gdlr-core-parallax gdlr-core-js" style="backgroundImage: url(upload/about-team-bg-1.jpg) ;backgroundSize: cover ;backgroundPosition: center ;" data-parallax-speed="0.2"></div>
                            </div>
                            <div className="gdlr-core-pbf-wrapper-content gdlr-core-js ">
                                <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
                                    <div className="gdlr-core-pbf-column gdlr-core-column-20 gdlr-core-column-first" id="gdlr-core-column-2">
                                        <div className="gdlr-core-pbf-column-content-margin gdlr-core-js " style="margin: -55px 26px 0px 0px;padding: 70px 30px 25px 30px;">
                                            <div className="gdlr-core-pbf-background-wrap" style="boxShadow: 0 0 40px rgba(10, 10, 10,0.08); MozBoxShadow: 0 0 40px rgba(10, 10, 10,0.08); -webkit-boxShadow: 0 0 40px rgba(10, 10, 10,0.08); backgroundColor: #ffffff ;"></div>
                                            <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                                                <div className="gdlr-core-pbf-element">
                                                    <div className="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-bottom gdlr-core-item-pdlr" style="paddingBottom: 7px ;">
                                                        <div className="gdlr-core-title-item-title-wrap ">
                                                            <h3 className="gdlr-core-title-item-title gdlr-core-skin-title " style="fontSize: 19px ;letterSpacing: 0px ;textTransform: none ;">Perfect Design  </h3></div>
                                                    </div>
                                                </div>
                                                <div className="gdlr-core-pbf-element">
                                                    <div className="gdlr-core-divider-item gdlr-core-divider-item-normal gdlr-core-item-pdlr gdlr-core-left-align" style="marginBottom: 24px ;">
                                                        <div className="gdlr-core-divider-container" style="maxWidth: 140px ;">
                                                            <div className="gdlr-core-divider-line gdlr-core-skin-divider" style="borderColor: #968481 ;"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="gdlr-core-pbf-element">
                                                    <div className="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align" style="paddingBottom: 0px ;">
                                                        <div className="gdlr-core-text-box-item-content" style="fontSize: 18px ;fontWeight: 400 ;textTransform: none ;">
                                                            <p>A wonderful serenity has taken possession of my entire soul, like these sweet mornings of spring which.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="gdlr-core-pbf-element">
                                                    <div className="gdlr-core-button-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align"><a className="gdlr-core-button  gdlr-core-button-transparent gdlr-core-button-no-border gdlr-core-button-full-width" href="#" style="fontSize: 15px ;letterSpacing: 0px ;color: #1b1b1b ;padding: 0px 0px 0px 0px;textTransform: none ;"><span className="gdlr-core-content" >More</span><i className="gdlr-core-pos-right fa fa-long-arrow-right" style="color: #1b1b1b ;"  ></i></a></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="gdlr-core-pbf-column gdlr-core-column-20" id="gdlr-core-column-3">
                                        <div className="gdlr-core-pbf-column-content-margin gdlr-core-js " style="margin: -55px 13px 0px 13px;padding: 70px 30px 25px 30px;">
                                            <div className="gdlr-core-pbf-background-wrap" style="boxShadow: 0 0 40px rgba(10, 10, 10,0.08); MozBoxShadow: 0 0 40px rgba(10, 10, 10,0.08); -webkit-boxShadow: 0 0 40px rgba(10, 10, 10,0.08); backgroundColor: #ffffff ;"></div>
                                            <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                                                <div className="gdlr-core-pbf-element">
                                                    <div className="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-bottom gdlr-core-item-pdlr" style="paddingBottom: 7px ;">
                                                        <div className="gdlr-core-title-item-title-wrap ">
                                                            <h3 className="gdlr-core-title-item-title gdlr-core-skin-title " style="fontSize: 19px ;letterSpacing: 0px ;textTransform: none ;">Carefully Planned</h3></div>
                                                    </div>
                                                </div>
                                                <div className="gdlr-core-pbf-element">
                                                    <div className="gdlr-core-divider-item gdlr-core-divider-item-normal gdlr-core-item-pdlr gdlr-core-left-align" style="marginBottom: 24px ;">
                                                        <div className="gdlr-core-divider-container" style="maxWidth: 174px ;">
                                                            <div className="gdlr-core-divider-line gdlr-core-skin-divider" style="borderColor: #968481 ;"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="gdlr-core-pbf-element">
                                                    <div className="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align" style="paddingBottom: 0px ;">
                                                        <div className="gdlr-core-text-box-item-content" style="fontSize: 18px ;fontWeight: 400 ;textTransform: none ;">
                                                            <p>A wonderful serenity has taken possession of my entire soul, like these sweet mornings of spring which.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="gdlr-core-pbf-element">
                                                    <div className="gdlr-core-button-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align"><a className="gdlr-core-button  gdlr-core-button-transparent gdlr-core-button-no-border gdlr-core-button-full-width" href="#" style="fontSize: 15px ;letterSpacing: 0px ;color: #1b1b1b ;padding: 0px 0px 0px 0px;textTransform: none ;"><span className="gdlr-core-content" >More</span><i className="gdlr-core-pos-right fa fa-long-arrow-right" style="color: #1b1b1b ;"  ></i></a></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="gdlr-core-pbf-column gdlr-core-column-20" id="gdlr-core-column-4">
                                        <div className="gdlr-core-pbf-column-content-margin gdlr-core-js " style="margin: -55px 0px 0px 26px;padding: 70px 30px 25px 30px;">
                                            <div className="gdlr-core-pbf-background-wrap" style="boxShadow: 0 0 40px rgba(10, 10, 10,0.08); MozBoxShadow: 0 0 40px rgba(10, 10, 10,0.08); -webkit-boxShadow: 0 0 40px rgba(10, 10, 10,0.08); backgroundColor: #ffffff ;"></div>
                                            <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                                                <div className="gdlr-core-pbf-element">
                                                    <div className="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-bottom gdlr-core-item-pdlr" style="paddingBottom: 7px ;">
                                                        <div className="gdlr-core-title-item-title-wrap ">
                                                            <h3 className="gdlr-core-title-item-title gdlr-core-skin-title " style="fontSize: 19px ;letterSpacing: 0px ;textTransform: none ;">Smartly Execute</h3></div>
                                                    </div>
                                                </div>
                                                <div className="gdlr-core-pbf-element">
                                                    <div className="gdlr-core-divider-item gdlr-core-divider-item-normal gdlr-core-item-pdlr gdlr-core-left-align" style="marginBottom: 24px ;">
                                                        <div className="gdlr-core-divider-container" style="maxWidth: 157px ;">
                                                            <div className="gdlr-core-divider-line gdlr-core-skin-divider" style="borderColor: #968481 ;"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="gdlr-core-pbf-element">
                                                    <div className="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align" style="paddingBottom: 0px ;">
                                                        <div className="gdlr-core-text-box-item-content" style="fontSize: 18px ;fontWeight: 400 ;textTransform: none ;">
                                                            <p>A wonderful serenity has taken possession of my entire soul, like these sweet mornings of spring which.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="gdlr-core-pbf-element">
                                                    <div className="gdlr-core-button-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align"><a className="gdlr-core-button  gdlr-core-button-transparent gdlr-core-button-no-border gdlr-core-button-full-width" href="#" style="fontSize: 15px ;letterSpacing: 0px ;color: #1b1b1b ;padding: 0px 0px 0px 0px;textTransform: none ;"><span className="gdlr-core-content" >More</span><i className="gdlr-core-pos-right fa fa-long-arrow-right" style="color: #1b1b1b ;"  ></i></a></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="gdlr-core-pbf-wrapper " style="margin: -110px 0px 0px 0px;padding: 0px 0px 90px 0px;" id="gdlr-core-wrapper-4">
                            <div className="gdlr-core-pbf-background-wrap"></div>
                            <div className="gdlr-core-pbf-wrapper-content gdlr-core-js ">
                                <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
                                    <div className="gdlr-core-pbf-column gdlr-core-column-60 gdlr-core-column-first">
                                        <div className="gdlr-core-pbf-column-content-margin gdlr-core-js " style="margin: 0px 0px 0px 0px;padding: 60px 0px 0px 0px;">
                                            <div className="gdlr-core-pbf-background-wrap" style="boxShadow: 0 0 45px rgba(10, 10, 10,0.06); MozBoxShadow: 0 0 45px rgba(10, 10, 10,0.06); -webkit-boxShadow: 0 0 45px rgba(10, 10, 10,0.06); backgroundColor: #ffffff ;"></div>
                                            <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                                                <div className="gdlr-core-pbf-element">
                                                    <div className="gdlr-core-gallery-item gdlr-core-item-pdb clearfix  gdlr-core-gallery-item-style-grid" style="paddingBottom: 25px ;">
                                                        <div className="gdlr-core-gallery-item-holder gdlr-core-js-2 clearfix" data-layout="fitrows">
                                                            <div className="gdlr-core-item-list gdlr-core-gallery-column  gdlr-core-column-12 gdlr-core-column-first gdlr-core-item-pdlr gdlr-core-item-mgb">
                                                                <div className="gdlr-core-gallery-list gdlr-core-media-image">
                                                                    <a className="gdlr-core-lightgallery gdlr-core-js " href="upload/banner-1.png" data-lightbox-group="gdlr-core-img-group-1"><img src="upload/banner-1.png" alt="" width="137" height="103" title="banner-1" /></a>
                                                                </div>
                                                            </div>
                                                            <div className="gdlr-core-item-list gdlr-core-gallery-column  gdlr-core-column-12 gdlr-core-item-pdlr gdlr-core-item-mgb">
                                                                <div className="gdlr-core-gallery-list gdlr-core-media-image">
                                                                    <a className="gdlr-core-lightgallery gdlr-core-js " href="upload/banner-2.png" data-lightbox-group="gdlr-core-img-group-1"><img src="upload/banner-2.png" alt="" width="137" height="103" title="banner-2" /></a>
                                                                </div>
                                                            </div>
                                                            <div className="gdlr-core-item-list gdlr-core-gallery-column  gdlr-core-column-12 gdlr-core-item-pdlr gdlr-core-item-mgb">
                                                                <div className="gdlr-core-gallery-list gdlr-core-media-image">
                                                                    <a className="gdlr-core-lightgallery gdlr-core-js " href="upload/banner-3.png" data-lightbox-group="gdlr-core-img-group-1"><img src="upload/banner-3.png" alt="" width="137" height="103" title="banner-3" /></a>
                                                                </div>
                                                            </div>
                                                            <div className="gdlr-core-item-list gdlr-core-gallery-column  gdlr-core-column-12 gdlr-core-item-pdlr gdlr-core-item-mgb">
                                                                <div className="gdlr-core-gallery-list gdlr-core-media-image">
                                                                    <a className="gdlr-core-lightgallery gdlr-core-js " href="upload/banner-4.png" data-lightbox-group="gdlr-core-img-group-1"><img src="upload/banner-4.png" alt="" width="137" height="103" title="banner-4" /></a>
                                                                </div>
                                                            </div>
                                                            <div className="gdlr-core-item-list gdlr-core-gallery-column  gdlr-core-column-12 gdlr-core-item-pdlr gdlr-core-item-mgb">
                                                                <div className="gdlr-core-gallery-list gdlr-core-media-image">
                                                                    <a className="gdlr-core-lightgallery gdlr-core-js " href="upload/banner-5.png" data-lightbox-group="gdlr-core-img-group-1"><img src="upload/banner-5.png" alt="" width="137" height="103" title="banner-5" /></a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        )
    }
}

export default Team;