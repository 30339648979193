import React, { Component } from 'react';
import { connect } from 'react-redux';

class Goal extends Component {
    componentDidMount() {
        // var loadScript = function (src) {
        //     var tag = document.createElement('script');
        //     tag.async = false;
        //     tag.src = src;
        //     var body = document.getElementsByTagName('body')[0];
        //     body.appendChild(tag);
        // }

        // loadScript('js/plugins.js');
    }
    render() {
        let { setting } = this.props;
        return (
            <div>
                <div className="inteco-page-title-wrap  inteco-style-large inteco-center-align">
                    {/* <div className="inteco-header-transparent-substitute"></div> */}
                    <div className="inteco-page-title-overlay"></div>
                    <div className="inteco-page-title-container inteco-container">
                        <div className="inteco-page-title-content inteco-item-pdlr">
                            {/* <h1 className="inteco-page-title">Goal</h1> */}
                            {/* <div className="inteco-page-caption">Know Us Better</div> */}
                        </div>
                    </div>
                </div>
                <div className="infinite-page-wrapper" id="infinite-page-wrapper">
                    <div className="gdlr-core-page-builder-body">
                        <div className="gdlr-core-pbf-wrapper " style={{ "padding": "100px 0px 50px 0px" }}>
                            <div className="gdlr-core-pbf-background-wrap"></div>
                            <div className="gdlr-core-pbf-wrapper-content gdlr-core-js ">
                                <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
                                    <div className="gdlr-core-pbf-element">
                                        <div className="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr" style={{ "paddingBottom": "50px" }}><span className="gdlr-core-title-item-caption gdlr-core-info-font gdlr-core-skin-caption">Know Us Better</span>
                                            <div className="gdlr-core-title-item-title-wrap ">
                                                <h3 className="gdlr-core-title-item-title gdlr-core-skin-title " style={{ "fontSize": "33px" }}>Dit Aim Goal<span className="gdlr-core-title-item-title-divider gdlr-core-skin-divider"></span></h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="gdlr-core-pbf-element">
                                        <div className="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                                            <div className="gdlr-core-text-box-item-content">
                                                <p>{setting && setting.ourStory}</p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="gdlr-core-pbf-element">
                                        <div className="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                                            <div className="gdlr-core-text-box-item-content">
                                                <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. Separated they live in Bookmarksgrove right at the coast of the Semantics, a large language ocean. A small river named Duden flows by their place and supplies it with the necessary regelialia. It is a paradisematic country, in which roasted parts of sentences fly into your mouth. Even the all-powerful Pointing has no control about the blind texts it is an almost unorthographic life One day however a small line of blind text by the name of Lorem Ipsum decided to leave for the far World of Grammar. The Big Oxmox advised her not to do so, because there were thousands of bad Commas, wild.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="gdlr-core-pbf-element">
                                        <div className="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                                            <div className="gdlr-core-text-box-item-content">
                                                <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. Separated they live in Bookmarksgrove right at the coast of the Semantics, a large language ocean. A small river named Duden flows by their place and supplies it with the necessary regelialia. It is a paradisematic country, in which roasted parts of sentences fly into your mouth. Even the all-powerful Pointing has no control about the blind texts it is an almost unorthographic life One day however a small line of blind text by the name of Lorem Ipsum decided to leave for the far World of Grammar. The Big Oxmox advised her not to do so, because there were thousands of bad Commas, wild.</p>
                                            </div>
                                        </div>
                                    </div> */}

                                </div>
                            </div>
                        </div>
                        <div className="gdlr-core-pbf-section">
                            <div className="gdlr-core-pbf-section-container gdlr-core-container clearfix">
                                <div className="gdlr-core-pbf-element">
                                    <div className="gdlr-core-divider-item gdlr-core-divider-item-with-icon gdlr-core-item-pdlr gdlr-core-center-align">
                                        <div className="gdlr-core-divider-item-with-icon-inner gdlr-core-js">
                                            <div className="gdlr-core-divider-line gdlr-core-left gdlr-core-skin-divider" style={{ "width": "570px", "marginTop": "-0.5px" }}></div><i className="icon_quotations" style={{ "fontSize": "30px" }}></i>
                                            <div className="gdlr-core-divider-line gdlr-core-skin-divider gdlr-core-right" style={{ "width": "570px", "marginTop": "-0.5px" }}></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        setting: state.common.content.setting
    }
}

export default connect(mapStateToProps, null)(Goal);