import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import server from '../../configs/domain';
import { connect } from 'react-redux';

class ImageGallery extends Component {

    componentDidMount() {
        // var loadScript = function (src) {
        //     var tag = document.createElement('script');
        //     tag.async = false;
        //     tag.src = src;
        //     var body = document.getElementsByTagName('body')[0];
        //     body.appendChild(tag);
        // }

        // loadScript('/js/plugins.js');

    }
    render() {
        return (
            <div>
                <div className="inteco-page-title-wrap  inteco-style-medium inteco-center-align">
                    {/* <div className="inteco-header-transparent-substitute"></div> */}
                    <div className="inteco-page-title-overlay"></div>
                    <div className="inteco-page-title-container inteco-container">
                        <div className="inteco-page-title-content inteco-item-pdlr">
                            {/* <h1 className="inteco-page-title">Gallery</h1> */}
                            {/* <div className="inteco-page-caption">{this.props.match.params.type}</div> */}
                        </div>
                    </div>
                </div>
                <div className="inteco-page-wrapper" id="inteco-page-wrapper">
                    <div className="gdlr-core-page-builder-body">
                        <div className="gdlr-core-pbf-section">
                            <div className="gdlr-core-pbf-section-container gdlr-core-container clearfix">
                                <div className="gdlr-core-pbf-element">
                                    <div className="gdlr-core-portfolio-item gdlr-core-item-pdb clearfix  gdlr-core-portfolio-item-style-grid" style={{ "paddingBottom": "10px" }}>

                                        {/* <div className="filter light-filter clearfix gdlr-core-filterer-wrap gdlr-core-js  gdlr-core-item-pdlr gdlr-core-style-text gdlr-core-center-align">
                                            <ul>
                                                <li><a href="/#" className="active" data-filter="*">All</a></li>
                                                <li><a href="/#" data-filter=".className1" >Architecture</a></li>
                                                <li><a href="/#" data-filter=".className2" >Highrise</a></li>
                                                <li><a href="/#" data-filter=".className3" >Housing</a></li>
                                            </ul>
                                        </div> */}

                                        <div className="gdlr-core-portfolio-item-holder gdlr-core-js-2 filter-container clearfix" data-layout="fitrows">


                                            {
                                                this.props.projects && this.props.projects.map(project => {
                                                    return (
                                                        <div className="gdlr-core-item-list  gdlr-core-item-pdlr gdlr-core-column-15" key={project.title}>
                                                            <div className="gdlr-core-portfolio-grid  gdlr-core-center-align gdlr-core-style-normal" style={{ "marginBottom": "25px" }}>
                                                                <div className="gdlr-core-portfolio-thumbnail gdlr-core-media-image  gdlr-core-style-margin-icon">
                                                                    <div className="gdlr-core-portfolio-thumbnail-image-wrap  gdlr-core-zoom-on-hover">
                                                                        <a className="gdlr-core-lightgallery gdlr-core-js " href={`${server}/${project.image}`} data-lightbox-group="gdlr-core-img-group-1"><img src={`${server}/${project.image}`} srcSet={`${server}/${project.image} 400w, ${server}/${project.image} 700w`} sizes="(maxWidth: 767px) 100vw, 25vw" alt="" width="700" height="660" /><span className="gdlr-core-image-overlay  gdlr-core-portfolio-overlay gdlr-core-image-overlay-center-icon gdlr-core-js gdlr-core-with-margin"><span className="gdlr-core-image-overlay-content" style={{ "marginTop": "-17.8417px" }}><span className="gdlr-core-portfolio-icon-wrap"><i className="gdlr-core-portfolio-icon arrow_expand"></i></span></span>
                                                                        </span>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                                <div className="gdlr-core-portfolio-content-wrap gdlr-core-skin-divider">
                                                                    <h3 className="gdlr-core-portfolio-title gdlr-core-skin-title" style={{ "fontSize": "16px", "fontWeight": "600", "letterSpacing": "0px", "textTransform": "none" }}><NavLink to={'/image-gallery/' + project.id}>{project.title}</NavLink></h3>
                                                                    <span className="gdlr-core-portfolio-info gdlr-core-portfolio-info-tag gdlr-core-info-font gdlr-core-skin-caption"><a href="/#" rel="tag">Gallery</a> <span className="gdlr-core-sep">/</span> <a href="/#" rel="tag">Images</a></span>
                                                                    <div className="gdlr-core-portfolio-content"></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )

                                                })
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        projects: state.common.content.imageGallery
    }
}

export default connect(mapStateToProps, null)(ImageGallery);