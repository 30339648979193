import { GET_CONTENT, FETCH_CONTENT_SUCCESS } from './commonTypes';

const contents = JSON.parse(localStorage.getItem('contents')) || {};

const initialState = {
    content: contents
}

const commonReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CONTENT: return {
            ...state,
            content: action.payload
        }
        case FETCH_CONTENT_SUCCESS: {
            localStorage.setItem('contents', JSON.stringify(action.payload))
            return {
                ...state,
                content: action.payload
            }
        }
        default: return state
    }
}

export default commonReducer