import React, { Component } from 'react';
import { connect } from 'react-redux';
import './videoGallery.component.css';

class VideoGallery extends Component {

    componentDidMount() {
        // var loadScript = function (src) {
        //     var tag = document.createElement('script');
        //     tag.async = false;
        //     tag.src = src;
        //     var body = document.getElementsByTagName('body')[0];
        //     body.appendChild(tag);
        // }

        // loadScript('/js/plugins.js');

    }
    render() {
        return (
            <div>
                <div className="inteco-page-title-wrap  inteco-style-medium inteco-center-align">
                    {/* <div className="inteco-header-transparent-substitute"></div> */}
                    <div className="inteco-page-title-overlay"></div>
                    <div className="inteco-page-title-container inteco-container">
                        <div className="inteco-page-title-content inteco-item-pdlr">
                            {/* <h1 className="inteco-page-title">Videos</h1>
                            <div className="inteco-page-caption"></div> */}
                        </div>
                    </div>
                </div>
                <div className="inteco-page-wrapper" id="inteco-page-wrapper">
                    <div className="gdlr-core-page-builder-body">
                        <div className="gdlr-core-pbf-section">
                            <div className="gdlr-core-pbf-section-container gdlr-core-container clearfix">
                                <div className="gdlr-core-pbf-element">
                                    <div className="gdlr-core-portfolio-item gdlr-core-item-pdb clearfix  gdlr-core-portfolio-item-style-grid" style={{ "paddingBottom": "10px" }}>

                                        {/* <div className="filter light-filter clearfix gdlr-core-filterer-wrap gdlr-core-js  gdlr-core-item-pdlr gdlr-core-style-text gdlr-core-center-align">
                                            <ul>
                                                <li><a href="/#" className="active" data-filter="*">All</a></li>
                                                <li><a href="/#" data-filter=".className1" >Architecture</a></li>
                                                <li><a href="/#" data-filter=".className2" >Highrise</a></li>
                                                <li><a href="/#" data-filter=".className3" >Housing</a></li>
                                            </ul>
                                        </div> */}

                                        <div className="gdlr-core-portfolio-item-holder gdlr-core-js-2 filter-container clearfix" data-layout="fitrows">


                                            {/* {
                                                this.props.projects && this.props.projects.map(project => {
                                                    return (
                                                        <div className="gdlr-core-item-list  gdlr-core-item-pdlr gdlr-core-column-15">
                                                            <div className="gdlr-core-portfolio-grid  gdlr-core-center-align gdlr-core-style-normal" style={{ "marginBottom": "25px" }}>
                                                                <div className="gdlr-core-portfolio-thumbnail gdlr-core-media-image  gdlr-core-style-margin-icon">
                                                                    <div className="gdlr-core-portfolio-thumbnail-image-wrap  gdlr-core-zoom-on-hover">
                                                                        <a className="gdlr-core-lightgallery gdlr-core-js " href={`${server}/${project.image}`} data-lightbox-group="gdlr-core-img-group-1">
                                                                            <img src={`${server}/${project.image}`} srcset={`${server}/${project.image} 400w, ${server}/${project.image} 700w`} sizes="(maxWidth: 767px) 100vw, 25vw" alt="" width="700" height="660" />
                                                                            <span className="gdlr-core-image-overlay  gdlr-core-portfolio-overlay gdlr-core-image-overlay-center-icon gdlr-core-js gdlr-core-with-margin"><span className="gdlr-core-image-overlay-content" style={{ "marginTop": "-17.8417px" }}><span className="gdlr-core-portfolio-icon-wrap"><i className="gdlr-core-portfolio-icon arrow_expand"></i></span></span>
                                                                            </span>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                    )

                                                })
                                            } */}

                                            {
                                                this.props.projects && this.props.projects.map(project => {
                                                    return (
                                                        <div key={project.title} className="gdlr-core-item-list  gdlr-core-item-pdlr gdlr-core-column-30">
                                                            <div className="gdlr-core-blog-grid gdlr-core-style-2 gdlr-core-js  gdlr-core-blog-grid-with-frame gdlr-core-item-mgb gdlr-core-skin-e-background  gdlr-core-outer-frame-element" style={{ "boxShadow": "rgba(0, 0, 0, 0.1) 0px 0px 60px" }} data-sync-height="blog-item-1">
                                                                <div className="gdlr-core-blog-thumbnail gdlr-core-media-video">
                                                                    <div className="gdlr-core-fluid-video-wrapper" style={{ "paddingTop": "61.4286%" }}>
                                                                        <iframe title={project.title} src={`${project.videoUrl}?rel=0&modestbranding=1&autohide=1&showinfo=0&controls=0`} data-player-type="vimeo" allowFullScreen="" id="gdlr-video-329229"></iframe>
                                                                    </div>
                                                                </div>
                                                                <div className="gdlr-core-blog-grid-frame" style={{ padding: "20px" }}>
                                                                    <div className="gdlr-core-blog-info-wrapper gdlr-core-skin-divider"><span className="gdlr-core-blog-info gdlr-core-blog-info-font gdlr-core-skin-caption gdlr-core-blog-info-date"><span className="gdlr-core-blog-info-sep">•</span><a href="/#">{new Date(project.createdAt).toLocaleString()}</a></span></div>
                                                                    <h3 className="gdlr-core-blog-title gdlr-core-skin-title" style={{ "fontSize": "14px" }}>{project.title}</h3>
                                                                </div>

                                                            </div>
                                                        </div>


                                                    )

                                                })
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        projects: state.common.content.videoGallery
    }
}

export default connect(mapStateToProps, null)(VideoGallery);