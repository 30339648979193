import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import rootReducer from './rootReducer';
import { getContent } from './common/commonActions';

const store = createStore(
    rootReducer, applyMiddleware(thunk)
)
store.dispatch(getContent());

export default store