import React, { Component } from 'react';
import { connect } from 'react-redux';
import server from '../../configs/domain';

class AboutTeam extends Component {
    componentDidMount() {
        // var loadScript = function (src) {
        //     var tag = document.createElement('script');
        //     tag.async = false;
        //     tag.src = src;
        //     var body = document.getElementsByTagName('body')[0];
        //     body.appendChild(tag);
        // }

        // loadScript('js/plugins.js');
    }
    render() {
        return (
            <div>
                <div className="inteco-page-title-wrap  inteco-style-custom inteco-left-align">
                    {/* <div className="inteco-header-transparent-substitute"></div> */}
                    <div className="inteco-page-title-overlay"></div>
                    <div className="inteco-page-title-container inteco-container">
                        <div className="inteco-page-title-content inteco-item-pdlr">
                            {/* <h1 className="inteco-page-title">About Our Team</h1> */}
                        </div>
                    </div>
                </div>
                <div className="inteco-page-wrapper" id="inteco-page-wrapper">
                    <div className="gdlr-core-page-builder-body">
                        <div className="gdlr-core-pbf-wrapper " style={{ "padding": "90px 0px 20px 0px" }}>
                            <div className="gdlr-core-pbf-background-wrap" style={{ "backgroundColor": "#f5f5f5" }}></div>
                            <div className="gdlr-core-pbf-wrapper-content gdlr-core-js ">
                                <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
                                    <div className="gdlr-core-pbf-column gdlr-core-column-60 gdlr-core-column-first" data-skin="Grey">
                                        <div className="gdlr-core-pbf-column-content-margin gdlr-core-js ">
                                            <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                                                <div className="gdlr-core-pbf-element">
                                                    <div className="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr" style={{ "paddingBottom": "60px" }}>
                                                        <div className="gdlr-core-title-item-title-wrap ">
                                                            <h3 className="gdlr-core-title-item-title gdlr-core-skin-title " style={{ "fontSize": "37px", "letterSpacing": "0px", "textTransform": "none" }}>Our Teams</h3></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="gdlr-core-pbf-element">
                                        <div className="gdlr-core-personnel-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-personnel-item-style-grid-with-background gdlr-core-personnel-style-grid gdlr-core-with-background">

                                            {this.props.teams && this.props.teams.map(team =>
                                                <div className="gdlr-core-personnel-list-column  gdlr-core-column-20 gdlr-core-item-pdlr">
                                                    <div className="gdlr-core-personnel-list clearfix">
                                                        <div className="gdlr-core-personnel-list-image gdlr-core-media-image  gdlr-core-opacity-on-hover gdlr-core-zoom-on-hover">
                                                            <div><img src={`${server}/${team.image}`} alt="" width="495" height="600" title="personnel-2-1" /></div>
                                                        </div>
                                                        <div className="gdlr-core-personnel-list-content-wrap">
                                                            <h3 className="gdlr-core-personnel-list-title" style={{ "fontSize": "20px", "letterSpacing": "0px", "textTransform": "none" }}><a href="javascript:void(0)" >{team.name}</a></h3>
                                                            <div className="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption" style={{ "fontSize": "15px", "fontStyle": "normal" }}>{team.designation}</div>
                                                            <div className="gdlr-core-personnel-list-content">
                                                                <p>{team.details}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}



                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        teams: state.common.content.teams
    }
}

export default connect(mapStateToProps, null)(AboutTeam);