import React, { Component } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import server from '../../configs/domain';
import { connect } from 'react-redux';
import './service.component.css';
class Service extends Component {
    constructor(props) {
        super(props);
        this.state = {
            service: '',
        }
    }
    componentDidMount() {
        // if (this.props.services) {
        //     let service = this.props.services.find(service => service.name === this.props.match.params.name)
        //     this.setState({ service })
        // }
    }
    componentDidUpdate(nextProps) {
        // if (nextProps.match.params.name !== this.props.match.params.name) {
        //     if (this.props.services) {
        //         let service = this.props.services.find(service => service.name === this.props.match.params.name)
        //         this.setState({ service })
        //     }
        // }
    }
    render() {
        let service;
        if (this.props.services)
            service = this.props.services.find(service => service.name.trim() === this.props.match.params.name.trim())
        return (
            <div className="inteco-page-wrapper" id="inteco-page-wrapper">
                {service &&
                    <div className="gdlr-core-page-builder-body">
                        <div className="gdlr-core-pbf-wrapper " style={{ "padding": "250px 0px 150px 0px" }} id="gdlr-core-wrapper-1">
                            <div className="gdlr-core-pbf-background-wrap">
                                <div className="gdlr-core-pbf-background gdlr-core-parallax gdlr-core-js" style={{ "backgroundImage": `url(${server}/${service.backgroundImg})`, "backgroundSize": "cover", "backgroundPosition": "center" }} data-parallax-speed="0.2"></div>
                            </div>
                            <div className="gdlr-core-pbf-wrapper-content gdlr-core-js ">
                                <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
                                    <div className="gdlr-core-pbf-column gdlr-core-column-60 gdlr-core-column-first">
                                        <div className="gdlr-core-pbf-column-content-margin gdlr-core-js ">
                                            <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js " style={{ "maxWidth": "720px" }}>
                                                <div className="gdlr-core-pbf-element">
                                                    <div className="gdlr-core-image-item gdlr-core-item-pdlr gdlr-core-item-pdb  gdlr-core-center-align" style={{ "paddingBottom": "20px" }}>
                                                        {/* <div className="gdlr-core-image-item-wrap gdlr-core-media-image  gdlr-core-image-item-style-rectangle" style={{ "borderWidth": "0px" }}><img src="/upload/service3-hero-icon.png" alt="" width="78" height="78" title="service3-hero-icon" /></div> */}
                                                    </div>
                                                </div>
                                                <div className="gdlr-core-pbf-element">
                                                    <div className="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-center-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr" style={{ "paddingBottom": "20px" }}><span className="gdlr-core-title-item-caption gdlr-core-info-font gdlr-core-skin-caption service-title" style={{ "fontStyle": "normal", color: "#ffffff", "marginBottom": "0px" }}>{service.name}</span></div>
                                                </div>
                                                {/* <div className="gdlr-core-pbf-element">
                                                    <div className="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-center-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"><span className="gdlr-core-title-item-caption gdlr-core-info-font gdlr-core-skin-caption" style={{ "fontSize": "18px", "fontStyle": "normal", color: "#ffffff" }}>{service.shortDesc}</span></div>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="gdlr-core-pbf-wrapper " style={{ "padding": "0px 0px 0px 0px" }}>
                            <div className="gdlr-core-pbf-background-wrap"></div>
                            <div className="gdlr-core-pbf-wrapper-content gdlr-core-js ">
                                <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
                                    <div className="gdlr-core-pbf-column gdlr-core-column-30 gdlr-core-column-first">
                                        <div className="gdlr-core-pbf-column-content-margin gdlr-core-js service-we-provide-img" data-sync-height="height-1">
                                            <div className="gdlr-core-pbf-background-wrap">
                                                <div className="gdlr-core-pbf-background gdlr-core-parallax gdlr-core-js" style={{ "backgroundImage": "url(upload/ourservice3-bg-1.jpg)", "backgroundRepeat": "no-repeat", "backgroundPosition": "center left" }} data-parallax-speed="0.08"></div>
                                            </div>
                                            <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js  gdlr-core-sync-height-content">
                                                <div className="gdlr-core-pbf-element">
                                                    <div className="gdlr-core-image-item gdlr-core-item-pdlr gdlr-core-item-pdb  gdlr-core-right-align">
                                                        <div className="gdlr-core-image-item-wrap gdlr-core-media-image  gdlr-core-image-item-style-rectangle" style={{ "borderWidth": "0px", "maxWidth": "530px" }}><LazyLoadImage effect="blur" src={`${server}/${service.providedServiceImg}`} alt="" width="730" height="730" title="ourservices3-bg-2" /></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="gdlr-core-pbf-column gdlr-core-column-30" id="gdlr-core-column-1">
                                        <div className="gdlr-core-pbf-column-content-margin gdlr-core-js service-we-provide-text" data-sync-height="height-1">
                                            <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js  gdlr-core-sync-height-content">
                                                <div className="gdlr-core-pbf-element">
                                                    <div className="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr" style={{ "paddingBottom": "32px" }}><span className="gdlr-core-title-item-caption gdlr-core-info-font gdlr-core-skin-caption" style={{ "fontSize": "21px", "fontWeight": 700, "fontStyle": "normal", color: "#7f7f7f", "marginBottom": "0px" }}>We Provide</span>
                                                        <div className="gdlr-core-title-item-title-wrap ">
                                                            <h3 className="gdlr-core-title-item-title gdlr-core-skin-title " style={{ "fontSize": "34px", "letterSpacing": "0px", "textTransform": "none", "color": "#111111" }}>{service.providedServiceName}</h3></div>
                                                    </div>
                                                </div>
                                                <div className="gdlr-core-pbf-element">
                                                    <div className="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                                                        <div className="gdlr-core-text-box-item-content" style={{ "fontSize": "18px", "fontWeight": 400, "textTransform": "none" }}>
                                                            {/* <p>A wonderful serenity has taken possession of my entire soul, like these sweet mornings of spring which I enjoy with my whole heart.</p>
                                                        <p><span style={{ "color": "#b6958e" }}>I am alone, and feel the charm of existence in this spot</span>, which was created for the bliss of souls like mine. I am so happy, my dear friend, so absorbed in the exquisite sense of mere tranquil existence, that I neglect my talents. I should be incapable of drawing a single stroke at the present moment; and yet I feel that I never was a greater artist than now. When, while the lovely valley. I feel that I never was a greater artist than now. When, while the lovely valley teems with vapour around me, and the meridian sun strikes the upper surface of the impenetrable foliage of my trees.</p> */}
                                                            <p>{service.providedServiceDesc}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="gdlr-core-pbf-wrapper " style={{ "padding": "105px 0px 105px 0px" }}>
                            <div className="gdlr-core-pbf-background-wrap">
                                <div className="gdlr-core-pbf-background gdlr-core-parallax gdlr-core-js" style={{ "backgroundImage": `url(${server}/${service.serviceFeatureBackgroundImg})`, "backgroundSize": "cover", "backgroundPosition": "center " }} data-parallax-speed="0.8"></div>
                            </div>
                            <div className="gdlr-core-pbf-wrapper-content gdlr-core-js ">
                                <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
                                    <div className="gdlr-core-pbf-column gdlr-core-column-20 gdlr-core-column-first" id="gdlr-core-column-2">
                                        <div className="gdlr-core-pbf-column-content-margin gdlr-core-js " style={{ "padding": "65px 0px 15px 40px" }} data-sync-height="height11">
                                            <div className="gdlr-core-pbf-background-wrap" style={{ "backgroundColor": "#ffffff", opacity: "0.9 " }}></div>
                                            <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js  gdlr-core-sync-height-content">
                                                <div className="gdlr-core-pbf-element">
                                                    <div className="gdlr-core-column-service-item gdlr-core-item-pdb  gdlr-core-left-align gdlr-core-column-service-icon-top gdlr-core-no-caption gdlr-core-item-pdlr" style={{ "paddingBottom": "30px" }}>
                                                        <div className="gdlr-core-column-service-media gdlr-core-media-image" style={{ "marginTop": "10px", "marginRight": "0px", "marginBottom": "32px" }}><img src="/upload/icon-time-1.png" alt="" width="45" height="45" title="icon-time-1" /></div>
                                                        <div className="gdlr-core-column-service-content-wrapper">
                                                            <div className="gdlr-core-column-service-title-wrap">
                                                                <h3 className="gdlr-core-column-service-title gdlr-core-skin-title" style={{ "fontSize": "20px", "fontWeight": 800, "textTransform": "none" }}>{service.serviceFeature1}</h3></div>
                                                            <div className="gdlr-core-column-service-content" style={{ "fontSize": "17px", "fontWeight": 400, "textTransform": "none" }}>
                                                                <p>{service.serviceFeature1Desc}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="gdlr-core-pbf-column gdlr-core-column-20" id="gdlr-core-column-3">
                                        <div className="gdlr-core-pbf-column-content-margin gdlr-core-js " style={{ "padding": "65px 22px 0px 22px" }} data-sync-height="height11">
                                            <div className="gdlr-core-pbf-background-wrap" style={{ "backgroundColor": "#ffffff", opacity: "0.9" }}></div>
                                            <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js  gdlr-core-sync-height-content">
                                                <div className="gdlr-core-pbf-element">
                                                    <div className="gdlr-core-column-service-item gdlr-core-item-pdb  gdlr-core-left-align gdlr-core-column-service-icon-top gdlr-core-no-caption gdlr-core-item-pdlr" style={{ "paddingBottom": "30px" }}>
                                                        <div className="gdlr-core-column-service-media gdlr-core-media-image" style={{ "marginTop": "5px", "marginRight": "0px", "marginBottom": "33px" }}><img src="/upload/icon-2-1.png" alt="" width="59" height="46" title="icon-2" /></div>
                                                        <div className="gdlr-core-column-service-content-wrapper">
                                                            <div className="gdlr-core-column-service-title-wrap">
                                                                <h3 className="gdlr-core-column-service-title gdlr-core-skin-title" style={{ "fontSize": "20px", "fontWeight": 800, "textTransform": "none" }}>{service.serviceFeature2}</h3></div>
                                                            <div className="gdlr-core-column-service-content" style={{ "fontSize": "17px", "fontWeight": 400, "textTransform": "none" }}>
                                                                <p>{service.serviceFeature2Desc}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="gdlr-core-pbf-column gdlr-core-column-20" id="gdlr-core-column-4">
                                        <div className="gdlr-core-pbf-column-content-margin gdlr-core-js " style={{ "padding": "65px 45px 0px 0px" }} data-sync-height="height11">
                                            <div className="gdlr-core-pbf-background-wrap" style={{ "backgroundColor": "#ffffff", opacity: "0.9" }}></div>
                                            <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js  gdlr-core-sync-height-content">
                                                <div className="gdlr-core-pbf-element">
                                                    <div className="gdlr-core-column-service-item gdlr-core-item-pdb  gdlr-core-left-align gdlr-core-column-service-icon-top gdlr-core-no-caption gdlr-core-item-pdlr" style={{ "paddingBottom": "30px" }}>
                                                        <div className="gdlr-core-column-service-media gdlr-core-media-image" style={{ "marginRight": "0px" }}><img src="/upload/icon-light.png" alt="" width="39" height="52" title="icon-light" /></div>
                                                        <div className="gdlr-core-column-service-content-wrapper">
                                                            <div className="gdlr-core-column-service-title-wrap">
                                                                <h3 className="gdlr-core-column-service-title gdlr-core-skin-title" style={{ "fontSize": "20px", "fontWeight": 800, "textTransform": "none" }}>{service.serviceFeature3}</h3></div>
                                                            <div className="gdlr-core-column-service-content" style={{ "fontSize": "17px", "fontWeight": 400, "textTransform": "none" }}>
                                                                <p>{service.serviceFeature3Desc}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        {/* <div className="gdlr-core-pbf-wrapper " style={{ "padding": "0px 0px 0px 0px" }}>
                            <div className="gdlr-core-pbf-background-wrap"></div>
                            <div className="gdlr-core-pbf-wrapper-content gdlr-core-js ">
                                <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
                                    <div className="gdlr-core-pbf-column gdlr-core-column-30 gdlr-core-column-first" id="gdlr-core-column-5">
                                        <div className="gdlr-core-pbf-column-content-margin gdlr-core-js " style={{ "padding": "125px 0px 0px 20px" }} data-sync-height="height-1">
                                            <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js  gdlr-core-sync-height-content">
                                                <div className="gdlr-core-pbf-element">
                                                    <div className="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr" style={{ "paddingBottom": "25px" }}><span className="gdlr-core-title-item-caption gdlr-core-info-font gdlr-core-skin-caption" style={{ "fontSize": "21px", "fontWeight": 700, "fontStyle": "normal", color: "#7f7f7f", "marginBottom": "0px" }}>Tell us what you want</span>
                                                        <div className="gdlr-core-title-item-title-wrap ">
                                                            <h3 className="gdlr-core-title-item-title gdlr-core-skin-title " style={{ "fontSize": "34px", "letterSpacing": "0px", "textTransform": "none", color: "#111111" }}>{service.clientServiceTitle}</h3></div>
                                                    </div>
                                                </div>
                                                <div className="gdlr-core-pbf-element">
                                                    <div className="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align" style={{ "paddingBottom": "10px" }}>
                                                        <div className="gdlr-core-text-box-item-content" style={{ "fontSize": "18px", "fontWeight": 400, "textTransform": "none" }}>
                                                            <p>{service.clientServiceDesc}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div className="gdlr-core-pbf-column gdlr-core-column-30">
                                        <div className="gdlr-core-pbf-column-content-margin gdlr-core-js " style={{ "margin": "0px 0px 0px 40px", padding: "100px 0px 0px 0px" }} data-sync-height="height-1">
                                            <div className="gdlr-core-pbf-background-wrap">
                                                <div className="gdlr-core-pbf-background gdlr-core-parallax gdlr-core-js" style={{ "backgroundImage": "url(upload/ourservice3-img-bg-1.jpg)", "backgroundRepeat": "no-repeat", "backgroundPosition": "center left" }} data-parallax-speed="0.08"></div>
                                            </div>
                                            <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js  gdlr-core-sync-height-content">
                                                <div className="gdlr-core-pbf-element">
                                                    <div className="gdlr-core-image-item gdlr-core-item-pdlr gdlr-core-item-pdb  gdlr-core-right-align">
                                                        <div className="gdlr-core-image-item-wrap gdlr-core-media-image  gdlr-core-image-item-style-rectangle" style={{ "borderWidth": "0px", "maxWidth": "530px" }}><img src={`${server}/${service.clientServiceBackgroundImg}`} alt="" width="730" height="730" title="ourservices3-img-bg-2" /></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="gdlr-core-pbf-wrapper " style={{ "padding": "160px 0px 135px 0px" }}>
                            <div className="gdlr-core-pbf-background-wrap" style={{ "backgroundColor": "#1e1e1e" }}></div>
                            <div className="gdlr-core-pbf-wrapper-content gdlr-core-js ">
                                <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-pbf-wrapper-full-no-space">
                                    <div className="gdlr-core-pbf-element">
                                        <div className="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-center-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr">
                                            <div className="gdlr-core-title-item-title-wrap ">
                                                <h3 className="gdlr-core-title-item-title gdlr-core-skin-title " style={{ "fontSize": "34px", "letterSpacing": "0px", "textTransform": "none", color: "#ffffff" }}>Interested In Our Services</h3></div>
                                        </div>
                                    </div>
                                    <div className="gdlr-core-pbf-element">
                                        <div className="gdlr-core-button-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-center-align" style={{ "paddingBottom": "0px" }}><a className="gdlr-core-button  gdlr-core-button-transparent gdlr-core-button-with-border" href="/#" style={{ "fontSize": "18px", "fontWeight": 700, "letterSpacing": "0px", color: "#ffffff", padding: "0px 0px 2px 0px", "textTransform": "none", "borderRadius": "0px", "MozBorderRadius": "0px", "WebkitBorderRadius": "0px", "borderWidth": "0px 0px 7px 0px", "borderColor": "#968481" }}><span className="gdlr-core-content" >Get A Quote</span><i className="gdlr-core-pos-right fa fa-long-arrow-right" style={{ "color": "#ffffff" }}  ></i></a></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="gdlr-core-pbf-wrapper " style={{ "padding": "0px 0px 0px 0px" }}>
                            <div className="gdlr-core-pbf-background-wrap"></div>
                            <div className="gdlr-core-pbf-wrapper-content gdlr-core-js ">
                                <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
                                    <div className="gdlr-core-pbf-column gdlr-core-column-30 gdlr-core-column-first" id="gdlr-core-column-6">
                                        <div className="gdlr-core-pbf-column-content-margin gdlr-core-js " style={{ "padding": "100px 80px 80px 0px" }} data-sync-height="height3">
                                            <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js  gdlr-core-sync-height-content">
                                                <div className="gdlr-core-pbf-element">
                                                    <div className="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-bottom gdlr-core-item-pdlr" style={{ "paddingBottom": "12px" }}>
                                                        <div className="gdlr-core-title-item-title-wrap ">
                                                            <h3 className="gdlr-core-title-item-title gdlr-core-skin-title " style={{ "fontSize": "29px", "letterSpacing": "0px", "textTransform": "none", color: "#111111" }}>Frequently Asked Questions</h3></div>
                                                    </div>
                                                </div>
                                                <div className="gdlr-core-pbf-element">
                                                    <div className="gdlr-core-divider-item gdlr-core-divider-item-normal gdlr-core-item-pdlr gdlr-core-left-align">
                                                        <div className="gdlr-core-divider-container" style={{ "maxWidth": "67px" }}>
                                                            <div className="gdlr-core-divider-line gdlr-core-skin-divider" style={{ "borderColor": "#968481", "borderBottomWidth": "3px" }}></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="gdlr-core-pbf-element">
                                                    <div className="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                                                        <div className="gdlr-core-text-box-item-content" style={{ "fontSize": "17px", "fontWeight": 400, "textTransform": "none", color: "#7a7a7a" }}>
                                                            <p>A wonderful serenity has taken possession of my entire soul, like these sweet mornings of spring which I enjoy with my whole heart.</p>
                                                            <p><span style={{ "color": "#9a827d" }}>I am alone, and feel the charm of existence in this spot</span>, which was created for the bliss of souls like mine. I am so happy, my dear friend, so absorbed in the exquisite</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="gdlr-core-pbf-column gdlr-core-column-30" id="gdlr-core-column-7">
                                        <div className="gdlr-core-pbf-column-content-margin gdlr-core-js " style={{ "padding": "100px 70px 70px 80px" }} data-sync-height="height3">
                                            <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js  gdlr-core-sync-height-content">
                                                <div className="gdlr-core-pbf-element">
                                                    <div className="gdlr-core-accordion-item gdlr-core-item-pdlr gdlr-core-item-pdb  gdlr-core-accordion-style-icon" style={{ "paddingBottom": "0px" }}>
                                                        <div className="gdlr-core-accordion-item-tab clearfix  gdlr-core-active">
                                                            <div className="gdlr-core-accordion-item-content-wrapper">
                                                                <h4 className="gdlr-core-accordion-item-title gdlr-core-js " style={{ "fontSize": "19px", "fontWeight": 700, "textTransform": "none" }}><div className="gdlr-core-accordion-item-icon gdlr-core-js gdlr-core-skin-icon " ></div>Design & Office Furniture</h4>
                                                                <div className="gdlr-core-accordion-item-content">
                                                                    <p>A wonderful serenity has taken possession of my entire soul, like these sweet mornings of spring which I enjoy with my whole heart. <span style={{ "color": "#b6958e" }}>I am alone, and feel the charm of existence in this spot</span>, which was created for the bliss of souls like mine. I am so happy, my dear friend, so absorbed in the exquisite sense of mere.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="gdlr-core-accordion-item-tab clearfix ">
                                                            <div className="gdlr-core-accordion-item-content-wrapper">
                                                                <h4 className="gdlr-core-accordion-item-title gdlr-core-js " style={{ "fontSize": "19px", "fontWeight": 700, "textTransform": "none" }}><div className="gdlr-core-accordion-item-icon gdlr-core-js gdlr-core-skin-icon " ></div>Original Plans & Rendering</h4>
                                                                <div className="gdlr-core-accordion-item-content">
                                                                    <p>A wonderful serenity has taken possession of my entire soul, like these sweet mornings of spring which I enjoy with my whole heart. <span style={{ "color": "#b6958e" }}>I am alone, and feel the charm of existence in this spot</span>, which was created for the bliss of souls like mine. I am so happy, my dear friend, so absorbed in the exquisite sense of mere.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="gdlr-core-accordion-item-tab clearfix ">
                                                            <div className="gdlr-core-accordion-item-content-wrapper">
                                                                <h4 className="gdlr-core-accordion-item-title gdlr-core-js " style={{ "fontSize": "19px", "fontWeight": 700, "textTransform": "none" }}><div className="gdlr-core-accordion-item-icon gdlr-core-js gdlr-core-skin-icon " ></div>Restoration Center</h4>
                                                                <div className="gdlr-core-accordion-item-content">
                                                                    <p>A wonderful serenity has taken possession of my entire soul, like these sweet mornings of spring which I enjoy with my whole heart. <span style={{ "color": "#b6958e" }}>I am alone, and feel the charm of existence in this spot</span>, which was created for the bliss of souls like mine. I am so happy, my dear friend, so absorbed in the exquisite sense of mere.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="gdlr-core-accordion-item-tab clearfix ">
                                                            <div className="gdlr-core-accordion-item-content-wrapper">
                                                                <h4 className="gdlr-core-accordion-item-title gdlr-core-js " style={{ "fontSize": "19px", "fontWeight": 700, "textTransform": "none" }}><div className="gdlr-core-accordion-item-icon gdlr-core-js gdlr-core-skin-icon " ></div>Reimagine & Refresh</h4>
                                                                <div className="gdlr-core-accordion-item-content">
                                                                    <p>A wonderful serenity has taken possession of my entire soul, like these sweet mornings of spring which I enjoy with my whole heart. <span style={{ "color": "#b6958e" }}>I am alone, and feel the charm of existence in this spot</span>, which was created for the bliss of souls like mine. I am so happy, my dear friend, so absorbed in the exquisite sense of mere.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <div className="gdlr-core-pbf-wrapper " style={{ "padding": "0px 0px 0px 0px" }}>
                            <div className="gdlr-core-pbf-background-wrap"></div>
                            <div className="gdlr-core-pbf-wrapper-content gdlr-core-js ">
                                <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-pbf-wrapper-full-no-space">
                                    <div className="gdlr-core-pbf-column gdlr-core-column-60 gdlr-core-column-first">
                                        <div className="gdlr-core-pbf-column-content-margin gdlr-core-js ">
                                            <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                                                <div className="gdlr-core-pbf-element">
                                                    <div className="gdlr-core-gallery-item gdlr-core-item-pdb clearfix  gdlr-core-gallery-item-style-grid" style={{ "paddingBottom": "0px" }}>
                                                        <div className="gdlr-core-gallery-item-holder gdlr-core-js-2 clearfix" data-layout="fitrows">
                                                            <div className="gdlr-core-item-list gdlr-core-gallery-column  gdlr-core-column-15 gdlr-core-column-first gdlr-core-item-pdlr gdlr-core-item-mgb">
                                                                <div className="gdlr-core-gallery-list gdlr-core-media-image">
                                                                    <a className="gdlr-core-lightgallery" href="/#" onClick={e => e.preventDefault()}><LazyLoadImage src={`${server}/${service.image1}`} style={{padding:"5px"}} alt="" width="550" height="500" title="bed-bedroom-clean-775219" /></a>
                                                                </div>
                                                            </div>
                                                            <div className="gdlr-core-item-list gdlr-core-gallery-column  gdlr-core-column-15 gdlr-core-item-pdlr gdlr-core-item-mgb">
                                                                <div className="gdlr-core-gallery-list gdlr-core-media-image">
                                                                    <a className="gdlr-core-lightgallery " href="/#" onClick={e => e.preventDefault()} data-sub-html="Minimal Interior Design"><LazyLoadImage src={`${server}/${service.image2}`} style={{padding:"5px"}} alt="" width="550" height="500" title="Image Title" /></a>
                                                                </div>
                                                            </div>
                                                            <div className="gdlr-core-item-list gdlr-core-gallery-column  gdlr-core-column-15 gdlr-core-item-pdlr gdlr-core-item-mgb">
                                                                <div className="gdlr-core-gallery-list gdlr-core-media-image">
                                                                    <a className="gdlr-core-lightgallery " href="/#" onClick={e => e.preventDefault()}><LazyLoadImage src={`${server}/${service.image3}`} style={{padding:"5px"}} alt="" width="550" height="500" title="pexels-photo-327482" /></a>
                                                                </div>
                                                            </div>
                                                            <div className="gdlr-core-item-list gdlr-core-gallery-column  gdlr-core-column-15 gdlr-core-item-pdlr gdlr-core-item-mgb">
                                                                <div className="gdlr-core-gallery-list gdlr-core-media-image">
                                                                    <a className="gdlr-core-lightgallery" href="/#" onClick={e => e.preventDefault()}><LazyLoadImage src={`${server}/${service.image4}`} style={{padding:"5px"}} alt="" width="550" height="500" title="pexels-photo-373548" /></a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        services: state.common.content.services
    }
}

export default connect(mapStateToProps, null)(Service);