import React, { Component } from 'react';
import Home from '../../home/home.component';
import { Route, Switch } from 'react-router-dom';
import Blog from '../../blog/blog.component';
import GetAQuote from '../../getAQuote/getAQuote.component';
import Contact from '../../contact/contact.component';
import Portfolio from '../../portfolio/portfolio.component';
import Service from '../../service/service.component';
import Team from '../../team/team.component';
import AboutTeam from '../../aboutTeam/aboutTeam.component';
// import Story from '../../story/story.component';
import WhoWeAre from '../../whoWeAre/whoWeAre.component';
import Goal from '../../goal/goal.component';
import Objective from '../../objective/objective.component';
import Projects from '../../projects/projects.component';
import ImageGallery from '../../imageGallery/imageGallery.component';
import Gallery from '../../gallery/gallery.component';
import VideoGallery from '../../videoGallery/videoGallery.component';
import BlogDetails from '../../blogDetails/blogDetails.component';
import ScrollToTop from './ScrollToTop';
import Catalog from '../../catalog/catalog.component';

class Routes extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <>
                <ScrollToTop />
                <Switch>
                    <Route path="/" exact component={Home} />
                    <Route path="/blog" exact component={Blog} />
                    <Route path="/blog/:id" exact component={BlogDetails} />
                    <Route path="/get-a-quote" exact component={GetAQuote} />
                    <Route path="/contact" exact component={Contact} />
                    <Route path="/gallery" exact component={Portfolio} />
                    <Route path="/service/:name" exact component={Service} />
                    <Route path="/team" exact component={Team} />
                    <Route path="/about-team" exact component={AboutTeam} />
                    <Route path="/our-story" exact component={Goal} />
                    <Route path="/who-we-are" exact component={WhoWeAre} />
                    <Route path="/goal" exact component={Goal} />
                    <Route path="/objective" exact component={Objective} />
                    <Route path="/projects/:type" exact component={Projects} />
                    <Route path="/image-gallery" exact component={ImageGallery} />
                    <Route path="/image-gallery/:id" exact component={Gallery} />
                    <Route path="/project/:id" exact component={Gallery} />
                    <Route path="/video-gallery" exact component={VideoGallery} />
                    <Route path="/catalogs" exact component={Catalog} />
                </Switch>
            </>
        )
    }

}

export default Routes;