import React, { Component } from 'react';

class WhoWeAre extends Component {
    componentDidMount() {
        // var loadScript = function (src) {
        //     var tag = document.createElement('script');
        //     tag.async = false;
        //     tag.src = src;
        //     var body = document.getElementsByTagName('body')[0];
        //     body.appendChild(tag);
        // }

        // loadScript('js/plugins.js');
    }
    render() {
        return (
            <div>
                <div className="inteco-page-title-wrap  inteco-style-large inteco-center-align">
                    {/* <div className="inteco-header-transparent-substitute"></div> */}
                    <div className="inteco-page-title-overlay"></div>
                    <div className="inteco-page-title-container inteco-container">
                        <div className="inteco-page-title-content inteco-item-pdlr">
                            {/* <h1 className="inteco-page-title">Who We Are</h1> */}
                            {/* <div className="inteco-page-caption">Know Us Better</div> */}
                        </div>
                    </div>
                </div>
                <div className="inteco-page-wrapper" id="inteco-page-wrapper">
                    <div className="gdlr-core-page-builder-body">
                        <div className="gdlr-core-pbf-wrapper " style={{ "padding": "0px 0px 0px 0px" }}>
                            <div className="gdlr-core-pbf-background-wrap"></div>
                            <div className="gdlr-core-pbf-wrapper-content gdlr-core-js ">
                                <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
                                    <div className="gdlr-core-pbf-column gdlr-core-column-30 gdlr-core-column-first" id="gdlr-core-column-1">
                                        <div className="gdlr-core-pbf-column-content-margin gdlr-core-js " style={{ "padding": "104px 0px 40px 70px" }} data-sync-height="height-1">
                                            <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js  gdlr-core-sync-height-content">
                                                <div className="gdlr-core-pbf-element">
                                                    <div className="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr" style={{ "paddingBottom": "25px" }}><span className="gdlr-core-title-item-caption gdlr-core-info-font gdlr-core-skin-caption" style={{ "fontSize": "23px", "fontWeight": 700, "fontStyle": "normal", color: "#7f7f7f", "marginBottom": "0px" }}>Who we are</span>
                                                        <div className="gdlr-core-title-item-title-wrap ">
                                                            <h3 className="gdlr-core-title-item-title gdlr-core-skin-title " style={{ "fontSize": "39px", "fontWeight": 900, "letterSpacing": "0px", "textTransform": "none", color: "#232323" }}>Our Story .</h3></div>
                                                    </div>
                                                </div>
                                                <div className="gdlr-core-pbf-element">
                                                    <div className="gdlr-core-divider-item gdlr-core-divider-item-normal gdlr-core-item-pdlr gdlr-core-center-align" style={{ "marginRight": "-166px" }}>
                                                        <div className="gdlr-core-divider-line gdlr-core-skin-divider" style={{ "borderColor": "#d6c5c2" }}></div>
                                                    </div>
                                                </div>
                                                <div className="gdlr-core-pbf-element">
                                                    <div className="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                                                        <div className="gdlr-core-text-box-item-content" style={{ "fontSize": "18px", "fontWeight": 400, "textTransform": "none" }}>
                                                            <p>A wonderful serenity has taken possession of my entire soul, like these sweet mornings of spring which I enjoy with my whole heart.</p>
                                                            <p><span style={{ "color": "#b6958e" }}>I am alone, and feel the charm of existence in this spot</span>, which was created for the bliss of souls like mine. I am so happy, my dear friend, so absorbed in the exquisite sense of mere tranquil existence, that I neglect my talents. I should be incapable of drawing a single stroke at the present moment; and yet I feel that I never was a greater artist than now. When, while the lovely valley. I feel that I never was a greater artist than now. When, while the lovely valley teems with vapour around me, and the meridian sun strikes the upper surface of the impenetrable foliage of my trees. Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="gdlr-core-pbf-column gdlr-core-column-30" id="gdlr-core-column-2">
                                        <div className="gdlr-core-pbf-column-content-margin gdlr-core-js " style={{ "margin": "0px 0px 0px 70px", "padding": "84px 0px 45px 0px" }} data-sync-height="height-1">
                                            <div className="gdlr-core-pbf-background-wrap">
                                                <div className="gdlr-core-pbf-background gdlr-core-parallax gdlr-core-js" style={{ "backgroundImage": "url(upload/hp-1-chair-bg-4-1-1.jpg)", "backgroundRepeat": "no-repeat", "backgroundPosition": "center left" }} data-parallax-speed="0.08"></div>
                                            </div>
                                            <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js  gdlr-core-sync-height-content">
                                                <div className="gdlr-core-pbf-element">
                                                    <div className="gdlr-core-image-item gdlr-core-item-pdlr gdlr-core-item-pdb  gdlr-core-center-align">
                                                        <div className="gdlr-core-image-item-wrap gdlr-core-media-image  gdlr-core-image-item-style-rectangle" style={{ "borderWidth": "0px", "maxWidth": "430px" }}><img src="upload/hp-1-chair-1.jpg" alt="" width="620" height="777" title="hp-1-chair" /></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

export default WhoWeAre;