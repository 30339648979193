import axios from 'axios';
import serverUrl from '../configs/domain';

class ApiService {

    quoteSave(data) {
        return axios.post(`${serverUrl}/quotes`, data);
    }

    contactSend(data) {
        return axios.post(`${serverUrl}/contact`, data);
    }

    signUp(data) {
        return axios.post(`${serverUrl}/users`, data);
    }

    login(data) {
        return axios.post(`${serverUrl}/users/login`, data);
    }

    getProfile(token) {
        return axios.get(`${serverUrl}/users/profile`, {
            headers: {
                token
            }
        })
    }

    sendOtp(data) {
        return axios.post(`${serverUrl}/otp/send`, data);
    }
}

export default new ApiService();