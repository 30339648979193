import React from 'react';

import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

function HeaderMobile() {
    const services = useSelector(state => state.common.content.services);
    return (
        <div className="inteco-mobile-header-wrap">
            <div className="inteco-mobile-header inteco-header-background inteco-style-slide inteco-sticky-mobile-navigation "
                id="inteco-mobile-header">
                <div className="inteco-mobile-header-container inteco-container clearfix">
                    <div className="inteco-logo  inteco-item-pdlr" style={{paddingTop:"8px",paddingBottom:"0px"}}>
                        <div className="inteco-logo-inner">
                            <NavLink className="" to="/"><img src="/upload/logo.png" alt="" width="200" height="39"
                                title="logo" /></NavLink>
                        </div>
                    </div>
                    <div className="inteco-mobile-menu-right">
                        {/* <div className="inteco-main-menu-search" id="inteco-mobile-top-search"><i className="fa fa-search"></i>
                        </div> */}
                        <div className="inteco-top-search-wrap">
                            <div className="inteco-top-search-close"></div>

                            <div className="inteco-top-search-row">
                                <div className="inteco-top-search-cell">
                                    <form role="search" method="get" className="search-form" action="#">
                                        <input type="text" className="search-field inteco-title-font" placeholder="Search..."
                                            value="" name="s" readOnly />
                                        <div className="inteco-top-search-submit"><i className="fa fa-search"></i></div>
                                        <input type="submit" className="search-submit" value="Search" readOnly />
                                        <div className="inteco-top-search-close"><i className="icon_close"></i></div>
                                    </form>
                                </div>
                            </div>

                        </div>

                        <div className="inteco-overlay-menu inteco-mobile-menu" id="inteco-mobile-menu"><a
                            className="inteco-overlay-menu-icon inteco-mobile-menu-button inteco-mobile-button-hamburger"
                            href="true" onClick={(e) => { e.preventDefault(); }}><span></span></a>
                            <div className="inteco-overlay-menu-content inteco-navigation-font slide-left">
                                <div className="inteco-overlay-menu-close"></div>
                                <div className="inteco-overlay-menu-row">
                                    <div className="inteco-overlay-menu-cell">
                                        <ul id="menu-main-navigation" className="menu">
                                            <li className="menu-item menu-item-home current-menu-item"><NavLink
                                                to="/">Home</NavLink>
                                            </li>
                                            <li className="menu-item menu-item-has-children"><NavLink to="/our-story">About Us</NavLink>
                                                <ul className="sub-menu">
                                                    {/* <li className="menu-item"><NavLink to="/who-we-are">Who we are </NavLink></li> */}
                                                    <li className="menu-item"><NavLink to="/our-story">Our Story</NavLink></li>
                                                    {/* <li className="menu-item"><NavLink to="/goal">Dit aim goal</NavLink></li> */}
                                                    {/* <li className="menu-item"><NavLink to="/objective">Objective</NavLink></li> */}
                                                    <li className="menu-item"><NavLink to="/about-team">Our Team</NavLink></li>
                                                    <li className="menu-item"><NavLink to="/catalogs">Catalogs</NavLink></li>
                                                </ul>
                                            </li>
                                            <li className="menu-item menu-item-has-children"><NavLink
                                                to="/service/">Services</NavLink>
                                                <ul className="sub-menu">
                                                    {services && services.map((service, index) =>
                                                        <li className="menu-item" key={'header-mobile-' + service.name + index}><NavLink to={`/service/${service.name}`}>{service.name}</NavLink></li>
                                                    )}
                                                </ul>
                                            </li>
                                            <li className="menu-item menu-item-has-children"><NavLink to="/projects/OnGoing">Projects</NavLink>
                                                <ul className="sub-menu">
                                                    <li className="menu-item"><NavLink to="/projects/OnGoing">Ongoing</NavLink></li>
                                                    <li className="menu-item"><NavLink to="/projects/Completed">Completed</NavLink></li>
                                                </ul>
                                            </li>
                                            <li className="menu-item menu-item-has-children"><NavLink
                                                to="/video-gallery">Gallery</NavLink>
                                                <ul className="sub-menu">
                                                    <li className="menu-item"><NavLink to="/video-gallery">Video Gallery</NavLink></li>
                                                </ul>
                                            </li>
                                            <li className="menu-item"><NavLink
                                                to="/get-a-quote">Get A Quote</NavLink>
                                            </li>
                                            <li className="menu-item"><NavLink
                                                to="/blog">Blog</NavLink>
                                            </li>
                                            <li className="menu-item"><NavLink
                                                to="/contact">Contact Us</NavLink>
                                            </li>


                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeaderMobile;