import React, { Component } from 'react';
import server from '../../configs/domain';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

class Blog extends Component {
    componentDidMount() {
        // var loadScript = function (src) {
        //     var tag = document.createElement('script');
        //     tag.async = false;
        //     tag.src = src;
        //     var body = document.getElementsByTagName('body')[0];
        //     body.appendChild(tag);
        // }

        // loadScript('js/plugins.js');
    }
    render() {
        return (
            <div>
                <div className="inteco-page-title-wrap  inteco-style-custom inteco-center-align">
                    {/* <div className="inteco-header-transparent-substitute"></div> */}
                    <div className="inteco-page-title-overlay"></div>
                    <div className="inteco-page-title-container inteco-container">
                        <div className="inteco-page-title-content inteco-item-pdlr">
                            {/* <h1 className="inteco-page-title">Latest Blogs</h1>
                            <div className="inteco-page-caption">Caption aligned here</div> */}
                        </div>
                    </div>
                </div>
                <div className="inteco-page-wrapper" id="inteco-page-wrapper">
                    <div className="gdlr-core-page-builder-body">
                        <div className="gdlr-core-pbf-wrapper ">
                            <div className="gdlr-core-pbf-background-wrap" style={{ "backgroundColor": "#f7f7f7" }}></div>
                            <div className="gdlr-core-pbf-wrapper-content gdlr-core-js ">
                                <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
                                    <div className="gdlr-core-pbf-element">
                                        <div className="gdlr-core-blog-item gdlr-core-item-pdb clearfix  gdlr-core-style-blog-column-with-frame" style={{ "paddingBottom": "40px" }}>
                                            <div className="gdlr-core-blog-item-holder gdlr-core-js-2 clearfix" data-layout="fitrows">
                                                {/* <div className="gdlr-core-item-list  gdlr-core-item-pdlr gdlr-core-column-30 gdlr-core-column-first">
                                                    <div className="gdlr-core-blog-grid  gdlr-core-blog-grid-with-frame gdlr-core-item-mgb gdlr-core-skin-e-background  gdlr-core-js" data-sync-height="blog-item-1">
                                                        <div className="gdlr-core-blog-thumbnail gdlr-core-media-image  gdlr-core-opacity-on-hover gdlr-core-zoom-on-hover">
                                                            <a href="/#"><img src="upload/etienne-beauregard-riverin-48305-unsplash-700x430.jpg" alt="" width="700" height="430" title="Image Title" /></a>
                                                        </div>
                                                        <div className="gdlr-core-blog-grid-frame">
                                                            <div className="gdlr-core-blog-grid-title-wrap">
                                                                <h3 className="gdlr-core-blog-title gdlr-core-skin-title" style={{ "fontSize": "25px", "fontWeight": 700, "letterSpacing": "0px" }}><a href="/#" >In Good Taste: Mark Finlay Architects &#038; Interiors</a></h3>
                                                                <div className="gdlr-core-blog-info-wrapper gdlr-core-skin-divider"><span className="gdlr-core-blog-info gdlr-core-blog-info-font gdlr-core-skin-caption gdlr-core-blog-info-date"><a href="/#">June 6, 2016</a></span><span className="gdlr-core-blog-info gdlr-core-blog-info-font gdlr-core-skin-caption gdlr-core-blog-info-author"><span className="gdlr-core-head" >By</span><a href="/#" title="Posts by John Smith" rel="author">John Smith</a></span><span className="gdlr-core-blog-info gdlr-core-blog-info-font gdlr-core-skin-caption gdlr-core-blog-info-category"><span className="gdlr-core-head" >In</span><a href="/#" rel="tag">Blog</a></span><span className="gdlr-core-blog-info gdlr-core-blog-info-font gdlr-core-skin-caption gdlr-core-blog-info-comment-number"><span className="gdlr-core-head" ><i className="fa fa-comments-o" ></i></span><a href="##respond">2 </a></span>
                                                                </div>
                                                            </div>
                                                            <div className="gdlr-core-blog-content">A wonderful serenity has taken possession of my entire soul, like these sweet mornings of spring which I enjoy with my whole heart. I am alone, and feel the charm of existence in this spot, which was created for the bliss of souls like mine. I am so happy, my dear friend, so absorbed in...
                                                            <div className="clear"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}
                                                {this.props.blogs && this.props.blogs.map((blog, index) =>
                                                    <div className="gdlr-core-item-list  gdlr-core-item-pdlr gdlr-core-column-30" key={'blog-' + index + blog.title}>
                                                        <div className="gdlr-core-blog-grid  gdlr-core-blog-grid-with-frame gdlr-core-item-mgb gdlr-core-skin-e-background  gdlr-core-js" data-sync-height="blog-item-1">
                                                            <div className="gdlr-core-blog-thumbnail gdlr-core-media-image  gdlr-core-opacity-on-hover gdlr-core-zoom-on-hover">
                                                                <NavLink to={"/blog/" + blog.id}><img src={`${server}/${blog.image}`} alt="" width="700" height="430" title="oleg-laptev-546619-unsplash" /></NavLink>
                                                            </div>
                                                            <div className="gdlr-core-blog-grid-frame">
                                                                <div className="gdlr-core-blog-grid-title-wrap">
                                                                    <h3 className="gdlr-core-blog-title gdlr-core-skin-title" style={{ "fontSize": "25px", "fontWeight": 700, "letterSpacing": "0px", "marginBottom":"0px" }}><NavLink to={'/blog/' + blog.id} style={{"lineHeight":"1.5"}}>{blog.title}</NavLink></h3>
                                                                    <div className="gdlr-core-blog-info-wrapper gdlr-core-skin-divider"><span className="gdlr-core-blog-info gdlr-core-blog-info-font gdlr-core-skin-caption gdlr-core-blog-info-date"><a href="/#">{new Date(blog.createdAt).toUTCString()}</a></span><span className="gdlr-core-blog-info gdlr-core-blog-info-font gdlr-core-skin-caption gdlr-core-blog-info-author"><span className="gdlr-core-head" >By</span><a href="/#" title="Posts by John Smith" rel="author">Admin</a></span><span className="gdlr-core-blog-info gdlr-core-blog-info-font gdlr-core-skin-caption gdlr-core-blog-info-category"><span className="gdlr-core-head" >In</span><a href="/#" rel="tag">Blogs</a></span><span className="gdlr-core-blog-info gdlr-core-blog-info-font gdlr-core-skin-caption gdlr-core-blog-info-comment-number"></span>
                                                                    </div>
                                                                </div>
                                                                <div className="gdlr-core-blog-content">{blog.short_description}
                                                                    <div className="clear"></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}

                                            </div>
                                            {/* <div className="gdlr-core-pagination  gdlr-core-style-circle gdlr-core-left-align gdlr-core-item-pdlr"><span aria-current='page' className='page-numbers current'>1</span>
                                                <a className='page-numbers' href='/#'>2</a>
                                                <a className="next page-numbers" href="/#"></a>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        blogs: state.common.content.blogs
    }
}

export default connect(mapStateToProps, null)(Blog);