import React, { Component } from 'react';
import apiService from '../../api/api.service';
import { connect } from 'react-redux';
import "./contact.component.css";

class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            subject: '',
            phone: '',
            message: '',
            error: '',
            success: '',
            loading: false
        }

    }

    submitHandler = e => {
        e.preventDefault();
        if (!this.state.name || !this.state.email || !this.state.message) {
            this.setState({ error: 'Please enter valid data.' })
            return;
        }
        this.setState({ loading: true });
        apiService.contactSend(this.state).then((res) => {
            this.setState({
                name: '',
                email: '',
                subject: '',
                phone: '',
                message: ''
            });
            if (res.data.status === 'error') {
                this.setState({ error: res.data.message });
                return;
            }
            this.setState({ success: 'Request Successfullty Submitted.' });
            this.setState({ error: '' });
            this.setState({ loading: false });
        }).catch(err => {
            this.setState({ error: err.response.data.message });
            this.setState({ loading: false });
        })
    }

    changeHandler = e => {
        this.setState({ [e.target.name]: e.target.value });
    }

    componentDidMount() {
        // var loadScript = function (src) {
        //     var tag = document.createElement('script');
        //     tag.async = false;
        //     tag.src = src;
        //     var body = document.getElementsByTagName('body')[0];
        //     body.appendChild(tag);
        // }

        // loadScript('plugins/goodlayers-core/include/js/page-builder.js');

        if(window.gtag) window.gtag('event', 'conversion', {'send_to': 'AW-16655492942/TZEPCIqpncYZEM7O-oU-'});
    }
    render() {
        return (
            <div className="inteco-page-wrapper" id="inteco-page-wrapper">
                <div className="gdlr-core-page-builder-body">
                    {/* <div className="gdlr-core-pbf-wrapper " style={{ "margin": "0px 0px 0px 0px", padding: "390px 0px 170px 0px" }} id="gdlr-core-wrapper-1">
                        <div className="gdlr-core-pbf-background-wrap">
                            <div className="gdlr-core-pbf-background gdlr-core-parallax gdlr-core-js" style={{ "backgroundImage": "url(upload/top-bg2.jpg)", "backgroundSize": "cover", "backgroundPosition": "center" }} data-parallax-speed="0.3"></div>
                        </div>
                        <div className="gdlr-core-pbf-wrapper-content gdlr-core-js " data-gdlr-animation="fadeIn" data-gdlr-animation-duration="600ms" data-gdlr-animation-offset="0.8">
                            <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
                                <div className="gdlr-core-pbf-element">
                                    <div className="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-center-align gdlr-core-title-item-caption-bottom gdlr-core-item-pdlr">
                                        <div className="gdlr-core-title-item-title-wrap ">
                                            <h3 className="gdlr-core-title-item-title gdlr-core-skin-title " style={{ "fontSize": "75px", "fontWeight": 700, "letterSpacing": "0px", "textTransform": "none", color: "#ffffff" }}>Contact Us</h3></div><span className="gdlr-core-title-item-caption gdlr-core-info-font gdlr-core-skin-caption" style={{ "fontSize": "25px", "fontStyle": "normal", color: "#ffffff", "marginTop": "15px" }}>Get Intouch</span></div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className="inteco-page-title-wrap  inteco-style-custom inteco-left-align">
                        {/* <div className="inteco-header-transparent-substitute"></div> */}
                        <div className="inteco-page-title-overlay"></div>
                        <div className="inteco-page-title-container inteco-container">
                            <div className="inteco-page-title-content inteco-item-pdlr">
                                {/* <h1 className="inteco-page-title">About Our Team</h1> */}
                            </div>
                        </div>
                    </div>
                    <div className="gdlr-core-pbf-wrapper " style={{ "padding": "40px 0px 40px 0px" }}>
                        <div className="gdlr-core-pbf-background-wrap" style={{ "backgroundColor": "#f5f5f5" }}></div>
                        <div className="gdlr-core-pbf-wrapper-content gdlr-core-js ">
                            <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
                                <div className="gdlr-core-pbf-column gdlr-core-column-20 gdlr-core-column-first">
                                    <div className="gdlr-core-pbf-column-content-margin gdlr-core-js " style={{ "padding": "50px 20px 0px 20px" }}>
                                        <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                                            <div className="gdlr-core-pbf-element">
                                                <div className="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-bottom gdlr-core-item-pdlr" style={{ "paddingBottom": "25px" }}>
                                                    <div className="gdlr-core-title-item-title-wrap ">
                                                        <h3 className="gdlr-core-title-item-title gdlr-core-skin-title " style={{ "fontSize": "28px", "fontWeight": 700, "letterSpacing": "0px", "textTransform": "none" }}>Location</h3></div>
                                                </div>
                                            </div>
                                            <div className="gdlr-core-pbf-element">
                                                <div className="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align" style={{ "paddingBottom": "0px" }}>
                                                    <div className="gdlr-core-text-box-item-content" style={{ "fontSize": "16px" }}>
                                                        <p>{this.props.setting.address}
                                                            <br />
                                                            {/* Dhaka, Bangladesh */}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="gdlr-core-pbf-element">
                                                <div className="gdlr-core-icon-list-item gdlr-core-item-pdlr gdlr-core-item-pdb clearfix ">
                                                    <ul>
                                                        <li className=" gdlr-core-skin-divider gdlr-core-with-hover"><span className="gdlr-core-icon-list-icon-wrap"><i className="gdlr-core-icon-list-icon-hover fa fa-envelope-o" style={{ "fontSize": "16px" }}  ></i><i className="gdlr-core-icon-list-icon fa fa-envelope-o" style={{ "fontSize": "16px", width: "16px" }} ></i></span>
                                                            <div className="gdlr-core-icon-list-content-wrap"><span className="gdlr-core-icon-list-content" style={{ "fontSize": "16px" }}>{this.props.setting.email}</span></div>
                                                        </li>
                                                        <li className=" gdlr-core-skin-divider gdlr-core-with-hover"><span className="gdlr-core-icon-list-icon-wrap"><i className="gdlr-core-icon-list-icon-hover fa fa-phone" style={{ "fontSize": "16px" }}  ></i><i className="gdlr-core-icon-list-icon fa fa-phone" style={{ "fontSize": "16px", width: "16px" }} ></i></span>
                                                            <div className="gdlr-core-icon-list-content-wrap"><a href={`tel:${this.props.setting.phone}`} className="gdlr-core-icon-list-content" style={{ "fontSize": "16px" }}>{this.props.setting.phone} </a></div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="gdlr-core-pbf-element">
                                                <div className="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-bottom gdlr-core-item-pdlr" style={{ "paddingBottom": "25px" }}>
                                                    <div className="gdlr-core-title-item-title-wrap ">
                                                        <h3 className="gdlr-core-title-item-title gdlr-core-skin-title " style={{ "fontSize": "28px", "fontWeight": 700, "letterSpacing": "0px", "textTransform": "none" }}>Map</h3></div>
                                                </div>
                                            </div>
                                            <div className="gdlr-core-pbf-element">
                                                <div className="gdlr-core-wp-google-map-plugin-item gdlr-core-item-pdlr gdlr-core-item-pdb " style={{ "paddingBottom": "0px" }}>
                                                    <div style={{ "overflow": "hidden", width: "100%", position: "relative" }}>

                                                        <iframe style={{ "width": "100%", height: "480px", border: 0 }} title="contact-map" src={this.props.setting.mapSrc} width="600" height="450" allowFullScreen="" loading="lazy"></iframe>
                                                        <div style={{ "position": "absolute", width: "80%", bottom: "20px", left: 0, right: 0, "marginLeft": "auto", "marginRight": "auto", color: "#000" }}>

                                                        </div>
                                                        {/* <style>#gmap_canvas img{maxWidth:none!important;background:none!important}</style> */}
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="gdlr-core-pbf-column gdlr-core-column-40" data-skin="White form">
                                    <div className="gdlr-core-pbf-column-content-margin gdlr-core-js " style={{ "padding": "50px 20px 0px 20px" }}>
                                        <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                                            <div className="gdlr-core-pbf-element">
                                                <div className="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-bottom gdlr-core-item-pdlr" style={{ "paddingBottom": "25px" }}>
                                                    <div className="gdlr-core-title-item-title-wrap ">
                                                        <h3 className="gdlr-core-title-item-title gdlr-core-skin-title " style={{ "fontSize": "32px", "fontWeight": 700, "letterSpacing": "0px", "textTransform": "none" }}>Leave us your info </h3></div>
                                                </div>
                                            </div>
                                            <div className="gdlr-core-pbf-element">
                                                <div className="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align" style={{ "paddingBottom": "35px" }}>
                                                    <div className="gdlr-core-text-box-item-content" style={{ "fontSize": "16px", "textTransform": "none" }}>
                                                        <p>A wonderful serenity has taken possession of my entire soul, like these sweet mornings of spring which I enjoy with my whole heart. I am alone, and feel the charm of existence in this spot, which was created for the bliss of souls like mine.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="gdlr-core-pbf-element">
                                                <div className="gdlr-core-contact-form-7-item gdlr-core-item-pdlr gdlr-core-item-pdb ">
                                                    <div role="form" className="wpcf7" id="wpcf7-f1979-p1977-o1" lang="en-US" dir="ltr">
                                                        <div className="screen-reader-response"></div>
                                                        <form className="quform" action="plugins/quform/process.php" method="post" encType="multipart/form-data">

                                                            <div className="quform-elements">
                                                                <div className="quform-element">
                                                                    <div>
                                                                        <span>Your Name (required)</span>
                                                                        <br />
                                                                        <p className="wpcf7-form-control-wrap your-name">
                                                                            <input id="name" type="text" name="name" value={this.state.name} onChange={this.changeHandler} size="40" className="input1" aria-required="true" aria-invalid="false" />
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className="quform-element">
                                                                    <div>Your Email (required)
                                                                        <br />
                                                                        <p className="wpcf7-form-control-wrap your-email">
                                                                            <input id="email" type="text" name="email" value={this.state.email} onChange={this.changeHandler} size="40" className="input1" aria-required="true" aria-invalid="false" />
                                                                        </p>
                                                                    </div>
                                                                </div>

                                                                <div className="quform-element">
                                                                    <div>Your Phone (required)
                                                                        <br />
                                                                        <p className="wpcf7-form-control-wrap your-email">
                                                                            <input id="email" type="text" name="phone" value={this.state.phone} onChange={this.changeHandler} size="40" className="input1" aria-required="true" aria-invalid="false" />
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className="quform-element">
                                                                    <div>Your Subject (required)
                                                                        <br />
                                                                        <p className="wpcf7-form-control-wrap your-email">
                                                                            <input id="email" type="text" name="subject" value={this.state.subject} onChange={this.changeHandler} size="40" className="input1" aria-required="true" aria-invalid="false" />
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className="quform-element">
                                                                    <div>Your Message
                                                                        <br />
                                                                        <p className="wpcf7-form-control-wrap your-message">
                                                                            <textarea id="message" name="message" value={this.state.message} onChange={this.changeHandler} cols="40" rows="10" className="input1" aria-invalid="false"></textarea>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    {/* <!-- Begin Submit button --> */}
                                                                    <div className="quform-submit">
                                                                        <div className="quform-submit-inner">
                                                                            <button type="submit" className="submit-button" onClick={this.submitHandler} style={{ padding: "5px" }} disabled={this.state.loading}>
                                                                                {this.state.loading && <i class="fa fa-refresh fa-spin" style={{ fontSize: "20px" }}></i>}
                                                                                {!this.state.loading && <i class="fa fa-sign-in" style={{ fontSize: "20px" }}></i>}
                                                                                <span style={{ paddingLeft: "10px", fontSize: "20px" }}>Send</span>
                                                                            </button>
                                                                        </div>
                                                                        {this.state.error && <div className="quform-loading-wrap"><span className="quform-loading" style={{ color: "red" }}>{this.state.error}</span></div>}
                                                                        {this.state.success && <div className="quform-loading-wrap"><span className="quform-loading" style={{ color: 'green' }}>{this.state.success}</span></div>}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="gdlr-core-pbf-wrapper " style={{ "padding": "75px 0px 75px 0px" }}>
                        <div className="gdlr-core-pbf-background-wrap" style={{ "backgroundColor": "#ffffff" }}></div>
                        <div className="gdlr-core-pbf-wrapper-content gdlr-core-js " data-gdlr-animation="fadeInUp" data-gdlr-animation-duration="600ms" data-gdlr-animation-offset="0.8">
                            <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-pbf-wrapper-full-no-space">
                                <div className="gdlr-core-pbf-element">
                                    <div className="gdlr-core-social-network-item gdlr-core-item-pdb  gdlr-core-center-align gdlr-core-item-pdlr" style={{ "paddingBottom": "0px" }}><a href="/#" target="_blank" className="gdlr-core-social-network-icon" title="email" style={{ "fontSize": "20px", color: "#232323 " }}><i className="fa fa-envelope" ></i></a><a href="/#" target="_blank" className="gdlr-core-social-network-icon" title="facebook" style={{ "fontSize": "20px", color: "#232323", "marginLeft": "37px" }}><i className="fa fa-facebook" ></i></a><a href="/#" target="_blank" className="gdlr-core-social-network-icon" title="google-plus" style={{ "fontSize": "20px", color: "#232323", "marginLeft": "37px" }}><i className="fa fa-google-plus" ></i></a><a href="/#" target="_blank" className="gdlr-core-social-network-icon" title="linkedin" style={{ "fontSize": "20px", color: "#232323", "marginLeft": "37px" }}><i className="fa fa-linkedin" ></i></a><a href="/#" target="_blank" className="gdlr-core-social-network-icon" title="skype" style={{ "fontSize": "20px", color: "#232323", "marginLeft": "37px" }}><i className="fa fa-skype" ></i></a><a href="/#" target="_blank" className="gdlr-core-social-network-icon" title="twitter" style={{ "fontSize": "20px", color: "#232323", "marginLeft": "37px" }}><i className="fa fa-twitter" ></i></a></div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        setting: state.common.content.setting
    }
}

export default connect(mapStateToProps, null)(Contact);