import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import './header.component.css';

class Header extends Component {
    render() {
        return (
            <div className="inteco-header-background-transparent">
                <header
                    className="inteco-header-wrap inteco-header-style-plain  inteco-style-float-left inteco-sticky-navigation inteco-style-slide"
                    data-navigation-offset="75px" style={{ backgroundImage: "linear-gradient(rgb(11, 11, 11), rgba(0, 0, 0, 0.02))" }}>
                    <div className="inteco-header-background"></div>
                    <div className="inteco-header-container  inteco-header-full">

                        <div className="inteco-header-container-inner clearfix" style={{ display: "flex" }}>
                            <div className="inteco-logo  inteco-item-pdlr" style={{ flexGrow: 1 }}>
                                <div className="inteco-logo-inner">
                                    <NavLink to="/"><img src="/upload/logo.png" alt="" width="200"
                                        height="39" title="logo" /></NavLink>
                                </div>
                                {/* <div className="inteco-logo-bottom-divider" id="inteco-logo-bottom-divider"></div> */}
                            </div>
                            <div className="inteco-navigation inteco-item-pdlr clearfix " style={{ display: "flex", flexGrow: 2, justifyContent: "center" }}>
                                <div className="inteco-main-menu" id="inteco-main-menu">
                                    <ul id="menu-main-navigation-1" className="sf-menu">
                                        <li
                                            className="menu-item menu-item-home current-menu-item menu-item-has-children inteco-normal-menu">
                                            <NavLink to="/" className="sf-with-ul-pre">Home</NavLink>
                                        </li>
                                        <li className="menu-item menu-item-has-children inteco-normal-menu"><NavLink
                                            to="/our-story" className="sf-with-ul-pre">About US</NavLink>
                                            <ul className="sub-menu">
                                                {/* <li className="menu-item" data-size="60"><NavLink to="/who-we-are">Who we are </NavLink></li> */}
                                                <li className="menu-item" data-size="60"><NavLink to="/our-story">Our Story</NavLink></li>
                                                {/* <li className="menu-item" data-size="60"><NavLink to="/goal">Dit aim goal</NavLink></li> */}
                                                {/* <li className="menu-item" data-size="60"><NavLink to="/objective">Objective</NavLink></li> */}
                                                <li className="menu-item" data-size="60"><NavLink to="/about-team">Our Team</NavLink></li>
                                                <li className="menu-item" data-size="60"><NavLink to="/catalogs">Catalogs</NavLink></li>
                                                {/* <li className="menu-item" data-size="60"><NavLink to="/team">Super team</NavLink></li> */}
                                            </ul>
                                        </li>
                                        <li className="menu-item menu-item-has-children inteco-normal-menu">
                                            <NavLink to={`/service/${this.props.services && this.props.services[0].name}`} onClick={(e) => e.preventDefault()} className="sf-with-ul-pre">Services</NavLink>
                                            <ul className="sub-menu">
                                                {this.props.services && this.props.services.map((service, index) =>
                                                    <li className="menu-item" key={'header-' + service.name + index} data-size="60"><NavLink to={`/service/${service.name}`}>{service.name}</NavLink></li>
                                                )}

                                                {/* <li className="menu-item" data-size="60"><NavLink to="/service">Home Interior Design</NavLink></li>
                                            <li className="menu-item" data-size="60"><NavLink to="/service">Home Furniture Solution</NavLink></li>
                                            <li className="menu-item" data-size="60"><NavLink to="/service">Roof & Tarace Design</NavLink></li>
                                            <li className="menu-item" data-size="60"><NavLink to="/service">Kitchen Design</NavLink></li> */}
                                            </ul>
                                        </li>
                                        <li className="menu-item menu-item-has-children inteco-normal-menu">
                                            <NavLink to="/projects/OnGoing" className="sf-with-ul-pre">Projects</NavLink>
                                            <ul className="sub-menu">
                                                <li className="menu-item" data-size="60"><NavLink to="/projects/OnGoing">Ongoing</NavLink></li>
                                                <li className="menu-item" data-size="60"><NavLink to="/projects/Completed">Completed </NavLink></li>
                                            </ul>
                                        </li>
                                        <li className="menu-item menu-item-has-children inteco-normal-menu">
                                            <NavLink to="/video-gallery" className="sf-with-ul-pre">Gallery</NavLink>
                                            <ul className="sub-menu">
                                                {/* <li className="menu-item" data-size="60"><NavLink to="/image-gallery">Photo Gallery</NavLink></li> */}
                                                <li className="menu-item" data-size="60"><NavLink to="/video-gallery">Video Gallery </NavLink></li>
                                            </ul>
                                        </li>
                                        <li className="menu-item menu-item-has-children inteco-normal-menu">
                                            <NavLink to="/get-a-quote" className="sf-with-ul-pre">Get A Quote</NavLink>
                                        </li>
                                        <li className="menu-item menu-item-has-children inteco-normal-menu"><NavLink
                                            to="/blog"
                                            className="sf-with-ul-pre">Blog</NavLink>
                                        </li>
                                        <li className="menu-item menu-item-has-children inteco-normal-menu"><NavLink
                                            to="/contact" className="sf-with-ul-pre">Contact Us</NavLink>
                                        </li>

                                    </ul>
                                </div>
                                {/* <div className="inteco-main-menu-right-wrap clearfix ">
                                    <div className="inteco-main-menu-search" id="inteco-top-search"><i
                                        className="fa fa-search"></i></div>
                                    <div className="inteco-top-search-wrap">
                                        <div className="inteco-top-search-close"></div>

                                        <div className="inteco-top-search-row">
                                            <div className="inteco-top-search-cell">
                                                <form role="search" method="get" className="search-form" action="#">
                                                    <input type="text" className="search-field inteco-title-font"
                                                        placeholder="Search..." value="" name="s" readOnly />
                                                    <div className="inteco-top-search-submit"><i className="fa fa-search"></i>
                                                    </div>
                                                    <input type="submit" className="search-submit" value="Search" readOnly />
                                                    <div className="inteco-top-search-close"><i className="icon_close"></i>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>

                                    </div>

                                </div> */}
                            </div>
                            {/* <!-- inteco-navigation --> */}

                            <div className="inteco-header-right-social gdlr-core-item-pdlr" style={{ flexGrow: 1 }}>
                                <a href="https://www.facebook.com/dit.interior" target="_blank" rel="noreferrer" className="inteco-top-bar-social-icon text-white" title="facebook">
                                    <i className="fa fa-facebook"></i>
                                </a>
                                <a href="https://www.youtube.com/channel/UCH_kEOE-o6hlo1X1DVermTQ" target="_blank" rel="noreferrer" className="inteco-top-bar-social-icon text-white" title="youtube" style={{paddingLeft: "0.95vw"}}>
                                    <i className="fa fa-youtube"></i>
                                </a>
                                {/* <a href="https://www.linkedin.com/in/design-ideas-and-technology-2a79a321a/" target="_blank" rel="noreferrer" className="inteco-top-bar-social-icon text-white" title="linkedin">
                                    <i className="fa fa-linkedin"></i>
                                </a> */}
                                <a href="https://www.instagram.com/ditbd" target="_blank" rel="noreferrer" className="inteco-top-bar-social-icon text-white" title="instagram" style={{paddingLeft: "0.95vw"}}>
                                    <i className="fa fa-instagram"></i>
                                </a>
                                {/* <a href="https://twitter.com/Infoditbd" target="_blank" rel="noreferrer" className="inteco-top-bar-social-icon text-white" title="twitter">
                                    <i className="fa fa-twitter"></i>
                                </a> */}
                            </div>
                        </div>
                        {/* <!-- inteco-header-inner --> */}
                    </div>
                    {/* <!-- inteco-header-container --> */}
                </header>
                {/* <!-- header --> */}
            </div>
        )
    }
}


const mapStateToProps = state => {
    return {
        services: state.common.content.services
    }
}

export default connect(mapStateToProps, null)(Header);