import React, { Component } from 'react';
import server from '../../configs/domain';
import { connect } from 'react-redux';

class Catalog extends Component {
    componentDidMount() {
        // var loadScript = function (src) {
        //     var tag = document.createElement('script');
        //     tag.async = false;
        //     tag.src = src;
        //     var body = document.getElementsByTagName('body')[0];
        //     body.appendChild(tag);
        // }

        // loadScript('js/plugins.js');
    }
    render() {
        return (
            <div>
                <div className="inteco-page-title-wrap  inteco-style-large inteco-center-align">
                    {/* <div className="inteco-header-transparent-substitute"></div> */}
                    <div className="inteco-page-title-overlay"></div>
                    <div className="inteco-page-title-container inteco-container">
                        <div className="inteco-page-title-content inteco-item-pdlr">
                            {/* <h1 className="inteco-page-title">Our Story</h1> */}
                            {/* <div className="inteco-page-caption">Know Us Better</div> */}
                        </div>
                    </div>
                </div>
                <div className="inteco-page-wrapper" id="inteco-page-wrapper">
                    <div class="gdlr-core-page-builder-body">
                        <div class="gdlr-core-pbf-wrapper " style={{ "padding": "65px 0px 30px 0px" }}>
                            <div class="gdlr-core-pbf-background-wrap" style={{ "background-color": "#f5f5f5" }}></div>
                            <div class="gdlr-core-pbf-wrapper-content gdlr-core-js ">
                                <div class="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
                                    <div class="gdlr-core-pbf-element">
                                        <div class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr" style={{ "padding-bottom": "50px" }}>
                                            <div class="gdlr-core-title-item-title-wrap ">
                                                <h3 class="gdlr-core-title-item-title gdlr-core-skin-title " style={{ "font-size": "19px", "font-weight": "700", "letter-spacing": "2px" }}>Portfolio & Catalog<span class="gdlr-core-title-item-title-divider gdlr-core-skin-divider"></span>
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        this.props.catalogs && this.props.catalogs.map(catalog =>
                                            <div class="gdlr-core-pbf-column gdlr-core-column-20">
                                                <div class="gdlr-core-pbf-column-content-margin gdlr-core-js " style={{ "padding": "5px 5px 5px 5px" }}>
                                                    <div class="gdlr-core-pbf-background-wrap"></div>
                                                    <div class="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                                                        <div class="gdlr-core-pbf-element">
                                                            <div
                                                                class="gdlr-core-promo-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                                                                <div class="gdlr-core-promo-box">
                                                                    <div class="gdlr-core-promo-box-item-image gdlr-core-media-image"></div>
                                                                    <div class="gdlr-core-promo-box-content-wrap gdlr-core-left-align gdlr-core-with-frame"
                                                                        style={{ "background-color": "#ffffff", "border-color": "#e8e8e8", "border-width": "0px 3px 3px 3px" }}>
                                                                        <h3 class="gdlr-core-promo-box-item-title" style={{ "font-size": "15px", "font-family": '"Raleway", sans-serif' }}>{catalog.title}</h3>
                                                                        <div class="gdlr-core-promo-box-item-content">
                                                                            <p style={{ "font-family": '"Comfortaa", display', "color": "#9b9b9b", "fontSize": "16.5px", "lineHeight": "1.6" }}>{catalog.categoryUrlSlug}</p>
                                                                        </div>
                                                                        <div style={{ "padding": "0", "margin-left": "10px" }}
                                                                            class="gdlr-core-button-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                                                                            <a class="gdlr-core-button  gdlr-core-button-solid gdlr-core-center-align gdlr-core-button-no-border"
                                                                                href={`${server}/${catalog.image}`} target="_blank" rel="noopener noreferrer" id="gdlr-core-button-id-31124"
                                                                                style={{ "padding": "8px 30px", "border-radius": "8px", "background-color": "#3433b8" }}><span
                                                                                    class="gdlr-core-content" style={{ "font-size": "12px", fontFamily: '"Raleway", sans-serif' }}>Download
                                                                                    Catelog</span></a></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }

                                    {/* <div class="gdlr-core-pbf-column gdlr-core-column-20 gdlr-core-column-first">
                                        <div class="gdlr-core-pbf-column-content-margin gdlr-core-js " style={{ "padding": "5px 5px 5px 5px" }}>
                                            <div class="gdlr-core-pbf-background-wrap"></div>
                                            <div class="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                                                <div class="gdlr-core-pbf-element">
                                                    <div
                                                        class="gdlr-core-promo-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-center-align">
                                                        <div class="gdlr-core-promo-box">
                                                            <div class="gdlr-core-promo-box-item-image gdlr-core-media-image"></div>
                                                            <div style={{ "background-color": "#ffffff", "border-color": "#e8e8e8", "border-width": "0px 3px 3px 3px" }}
                                                                class="gdlr-core-promo-box-content-wrap gdlr-core-left-align gdlr-core-with-frame">
                                                                <h3 class="gdlr-core-promo-box-item-title" style={{ "font-size": "15px" }}>Bed Room
                                                                    Catalog</h3>
                                                                <div class="gdlr-core-promo-box-item-content">
                                                                    <p>A wonderful serenity has taken possession of my entire soul, like these sweet
                                                                        mornings of spring which I enjoy with my whole heart. I am alone, and feel
                                                                        the charm of existence in this spot.</p>
                                                                </div>
                                                                <div style={{ "padding": "0", "margin-left": "10px" }}
                                                                    class="gdlr-core-button-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                                                                    <a class="gdlr-core-button  gdlr-core-button-solid gdlr-core-center-align gdlr-core-button-no-border"
                                                                        href="/#" id="gdlr-core-button-id-31124"
                                                                        style={{ "padding": "8px 30px", "border-radius": "8px", "background-color": "#3433b8" }}><span
                                                                            class="gdlr-core-content" style={{ "font-size": "11px" }}>Download
                                                                            Catelog</span></a></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        catalogs: state.common.content.catalogs
    }
}

export default connect(mapStateToProps, null)(Catalog);