import React, { Component } from 'react';
import { connect } from 'react-redux';

class Portfolio extends Component {



    componentDidMount() {
        // var loadScript = function (src) {
        //     var tag = document.createElement('script');
        //     tag.async = false;
        //     tag.src = src;
        //     var body = document.getElementsByTagName('body')[0];
        //     body.appendChild(tag);
        // }

        // loadScript('js/plugins.js');

    }
    render() {
        console.log(this.props.content)
        return (
            <div>
                <div className="inteco-page-title-wrap  inteco-style-medium inteco-center-align">
                    {/* <div className="inteco-header-transparent-substitute"></div> */}
                    <div className="inteco-page-title-overlay"></div>
                    <div className="inteco-page-title-container inteco-container">
                        <div className="inteco-page-title-content inteco-item-pdlr">
                            {/* <h1 className="inteco-page-title">Projects/Gallery</h1> */}
                            {/* <div className="inteco-page-caption">OnGoing, Completed</div> */}
                        </div>
                    </div>
                </div>
                <div className="inteco-page-wrapper" id="inteco-page-wrapper">
                    <div className="gdlr-core-page-builder-body">
                        <div className="gdlr-core-pbf-section">
                            <div className="gdlr-core-pbf-section-container gdlr-core-container clearfix">
                                <div className="gdlr-core-pbf-element">
                                    <div className="gdlr-core-portfolio-item gdlr-core-item-pdb clearfix  gdlr-core-portfolio-item-style-grid" style={{ "paddingBottom": "10px" }}>

                                        <div className="filter light-filter clearfix gdlr-core-filterer-wrap gdlr-core-js  gdlr-core-item-pdlr gdlr-core-style-text gdlr-core-center-align">
                                            <ul>
                                                <li><a href="/#" className="active" data-filter="*">All</a></li>
                                                <li><a href="/#" data-filter=".className1" >Architecture</a></li>
                                                <li><a href="/#" data-filter=".className2" >Highrise</a></li>
                                                <li><a href="/#" data-filter=".className3" >Housing</a></li>
                                            </ul>
                                        </div>

                                        <div className="gdlr-core-portfolio-item-holder gdlr-core-js-2 filter-container clearfix" data-layout="fitrows">
                                            <div className="gdlr-core-item-list className1 gdlr-core-item-pdlr gdlr-core-column-15 ">
                                                <div className="gdlr-core-portfolio-grid  gdlr-core-left-align gdlr-core-style-normal">
                                                    <div className="gdlr-core-portfolio-thumbnail gdlr-core-media-image  gdlr-core-style-title-icon">
                                                        <div className="gdlr-core-portfolio-thumbnail-image-wrap  gdlr-core-zoom-on-hover"><img src="upload/port-1-700x450.jpg" alt="" width="700" height="450" title="port-1" /><span className="gdlr-core-image-overlay  gdlr-core-portfolio-overlay gdlr-core-image-overlay-center-icon gdlr-core-js"><span className="gdlr-core-image-overlay-content" ><span className="gdlr-core-portfolio-title gdlr-core-title-font " style={{ "fontSize": "18px", "fontWeight": 400, "letterSpacing": "1px", "textTransform": "none" }}  ><a href="/#" >Interior Work AvroKo Project</a></span><span className="gdlr-core-portfolio-icon-wrap"><a className="gdlr-core-lightgallery gdlr-core-js " href="upload/port-1.jpg" data-lightbox-group="gdlr-core-img-group-1"><i className="gdlr-core-portfolio-icon arrow_expand" ></i></a></span></span>
                                                        </span>
                                                        </div>
                                                    </div>
                                                    <div className="gdlr-core-portfolio-content-wrap gdlr-core-skin-divider">
                                                        <h3 className="gdlr-core-portfolio-title gdlr-core-skin-title" style={{ "fontSize": "19px", "fontWeight": 600, "letterSpacing": "0px", "textTransform": "none" }}><a href="/#" >Interior Work AvroKo Project</a></h3><span className="gdlr-core-portfolio-info gdlr-core-portfolio-info-tag gdlr-core-info-font gdlr-core-skin-caption"><a href="/#" rel="tag">interior</a> <span className="gdlr-core-sep">/</span> <a href="/#" rel="tag">minimal</a></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="gdlr-core-item-list className2 gdlr-core-item-pdlr gdlr-core-column-15">
                                                <div className="gdlr-core-portfolio-grid  gdlr-core-left-align gdlr-core-style-normal">
                                                    <div className="gdlr-core-portfolio-thumbnail gdlr-core-media-image  gdlr-core-style-title-icon">
                                                        <div className="gdlr-core-portfolio-thumbnail-image-wrap  gdlr-core-zoom-on-hover"><img src="upload/gdtography-310706-unsplash-700x450.jpg" alt="" width="700" height="450" title="gdtography-310706-unsplash" /><span className="gdlr-core-image-overlay  gdlr-core-portfolio-overlay gdlr-core-image-overlay-center-icon gdlr-core-js"><span className="gdlr-core-image-overlay-content" ><span className="gdlr-core-portfolio-title gdlr-core-title-font " style={{ "fontSize": "18px", "fontWeight": 400, "letterSpacing": "1px", "textTransform": "none" }}  ><a href="/#" >The Ark Building Project</a></span><span className="gdlr-core-portfolio-icon-wrap"><a className="gdlr-core-lightgallery gdlr-core-js " href="upload/gdtography-310706-unsplash.jpg" data-lightbox-group="gdlr-core-img-group-1"><i className="gdlr-core-portfolio-icon arrow_expand" ></i></a></span></span>
                                                        </span>
                                                        </div>
                                                    </div>
                                                    <div className="gdlr-core-portfolio-content-wrap gdlr-core-skin-divider">
                                                        <h3 className="gdlr-core-portfolio-title gdlr-core-skin-title" style={{ "fontSize": "19px", "fontWeight": 600, "letterSpacing": "0px", "textTransform": "none" }}><a href="/#" >The Ark Building Project</a></h3><span className="gdlr-core-portfolio-info gdlr-core-portfolio-info-tag gdlr-core-info-font gdlr-core-skin-caption"><a href="/#" rel="tag">Architecture</a> <span className="gdlr-core-sep">/</span> <a href="/#" rel="tag">interior</a></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="gdlr-core-item-list className3 gdlr-core-item-pdlr gdlr-core-column-15 ">
                                                <div className="gdlr-core-portfolio-grid  gdlr-core-left-align gdlr-core-style-normal">
                                                    <div className="gdlr-core-portfolio-thumbnail gdlr-core-media-image  gdlr-core-style-title-icon">
                                                        <div className="gdlr-core-portfolio-thumbnail-image-wrap  gdlr-core-zoom-on-hover"><img src="upload/shutterstock_627788162-700x450.jpg" alt="" width="700" height="450" title="shutterstock_627788162" /><span className="gdlr-core-image-overlay  gdlr-core-portfolio-overlay gdlr-core-image-overlay-center-icon gdlr-core-js"><span className="gdlr-core-image-overlay-content" ><span className="gdlr-core-portfolio-title gdlr-core-title-font " style={{ "fontSize": "18px", "fontWeight": 400, "letterSpacing": "1px", "textTransform": "none" }}  ><a href="/#" >Diamond Park Exterior Design</a></span><span className="gdlr-core-portfolio-icon-wrap"><a className="gdlr-core-lightgallery gdlr-core-js " href="upload/shutterstock_627788162.jpg" data-lightbox-group="gdlr-core-img-group-1"><i className="gdlr-core-portfolio-icon arrow_expand" ></i></a></span></span>
                                                        </span>
                                                        </div>
                                                    </div>
                                                    <div className="gdlr-core-portfolio-content-wrap gdlr-core-skin-divider">
                                                        <h3 className="gdlr-core-portfolio-title gdlr-core-skin-title" style={{ "fontSize": "19px", "fontWeight": 600, "letterSpacing": "0px", "textTransform": "none" }}><a href="/#" >Diamond Park Exterior Design</a></h3><span className="gdlr-core-portfolio-info gdlr-core-portfolio-info-tag gdlr-core-info-font gdlr-core-skin-caption"><a href="/#" rel="tag">Planning</a></span></div>
                                                </div>
                                            </div>
                                            <div className="gdlr-core-item-list className1 gdlr-core-item-pdlr gdlr-core-column-15">
                                                <div className="gdlr-core-portfolio-grid  gdlr-core-left-align gdlr-core-style-normal">
                                                    <div className="gdlr-core-portfolio-thumbnail gdlr-core-media-image  gdlr-core-style-title-icon">
                                                        <div className="gdlr-core-portfolio-thumbnail-image-wrap  gdlr-core-zoom-on-hover"><img src="upload/hutomo-abrianto-576207-unsplash-700x450.jpg" alt="" width="700" height="450" title="hutomo-abrianto-576207-unsplash" /><span className="gdlr-core-image-overlay  gdlr-core-portfolio-overlay gdlr-core-image-overlay-center-icon gdlr-core-js"><span className="gdlr-core-image-overlay-content" ><span className="gdlr-core-portfolio-title gdlr-core-title-font " style={{ "fontSize": "18px", "fontWeight": 400, "letterSpacing": "1px", "textTransform": "none" }}  ><a href="/#" >Museum Of Glass Art in London</a></span><span className="gdlr-core-portfolio-icon-wrap"><a className="gdlr-core-lightgallery gdlr-core-js " href="upload/hutomo-abrianto-576207-unsplash.jpg" data-lightbox-group="gdlr-core-img-group-1"><i className="gdlr-core-portfolio-icon arrow_expand" ></i></a></span></span>
                                                        </span>
                                                        </div>
                                                    </div>
                                                    <div className="gdlr-core-portfolio-content-wrap gdlr-core-skin-divider">
                                                        <h3 className="gdlr-core-portfolio-title gdlr-core-skin-title" style={{ "fontSize": "19px", "fontWeight": 600, "letterSpacing": "0px", "textTransform": "none" }}><a href="/#" >Museum Of Glass Art in London</a></h3><span className="gdlr-core-portfolio-info gdlr-core-portfolio-info-tag gdlr-core-info-font gdlr-core-skin-caption"><a href="/#" rel="tag">Architecture</a> <span className="gdlr-core-sep">/</span> <a href="/#" rel="tag">Planning</a></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="gdlr-core-item-list className2 gdlr-core-item-pdlr gdlr-core-column-15 ">
                                                <div className="gdlr-core-portfolio-grid  gdlr-core-left-align gdlr-core-style-normal">
                                                    <div className="gdlr-core-portfolio-thumbnail gdlr-core-media-image  gdlr-core-style-title-icon">
                                                        <div className="gdlr-core-portfolio-thumbnail-image-wrap  gdlr-core-zoom-on-hover"><img src="upload/shutterstock_578977615-700x450.jpg" alt="" width="700" height="450" title="shutterstock_578977615" /><span className="gdlr-core-image-overlay  gdlr-core-portfolio-overlay gdlr-core-image-overlay-center-icon gdlr-core-js"><span className="gdlr-core-image-overlay-content" ><span className="gdlr-core-portfolio-title gdlr-core-title-font " style={{ "fontSize": "18px", "fontWeight": 400, "letterSpacing": "1px", "textTransform": "none" }}  ><a href="/#" >Minimal &#038; Light Living Area</a></span><span className="gdlr-core-portfolio-icon-wrap"><a className="gdlr-core-lightgallery gdlr-core-js " href="upload/shutterstock_578977615.jpg" data-lightbox-group="gdlr-core-img-group-1"><i className="gdlr-core-portfolio-icon arrow_expand" ></i></a></span></span>
                                                        </span>
                                                        </div>
                                                    </div>
                                                    <div className="gdlr-core-portfolio-content-wrap gdlr-core-skin-divider">
                                                        <h3 className="gdlr-core-portfolio-title gdlr-core-skin-title" style={{ "fontSize": "19px", "fontWeight": 600, "letterSpacing": "0px", "textTransform": "none" }}><a href="/#" >Minimal &#038; Light Living Area</a></h3><span className="gdlr-core-portfolio-info gdlr-core-portfolio-info-tag gdlr-core-info-font gdlr-core-skin-caption"><a href="/#" rel="tag">minimal</a></span></div>
                                                </div>
                                            </div>
                                            <div className="gdlr-core-item-list className3 gdlr-core-item-pdlr gdlr-core-column-15">
                                                <div className="gdlr-core-portfolio-grid  gdlr-core-left-align gdlr-core-style-normal">
                                                    <div className="gdlr-core-portfolio-thumbnail gdlr-core-media-image  gdlr-core-style-title-icon">
                                                        <div className="gdlr-core-portfolio-thumbnail-image-wrap  gdlr-core-zoom-on-hover"><img src="upload/shutterstock_631879034-700x450.jpg" alt="" width="700" height="450" title="shutterstock_631879034" /><span className="gdlr-core-image-overlay  gdlr-core-portfolio-overlay gdlr-core-image-overlay-center-icon gdlr-core-js"><span className="gdlr-core-image-overlay-content" ><span className="gdlr-core-portfolio-title gdlr-core-title-font " style={{ "fontSize": "18px", "fontWeight": 400, "letterSpacing": "1px", "textTransform": "none" }}  ><a href="/#" >Tokyo Soho Hotel</a></span><span className="gdlr-core-portfolio-icon-wrap"><a className="gdlr-core-lightgallery gdlr-core-js " href="upload/shutterstock_631879034.jpg" data-lightbox-group="gdlr-core-img-group-1"><i className="gdlr-core-portfolio-icon arrow_expand" ></i></a></span></span>
                                                        </span>
                                                        </div>
                                                    </div>
                                                    <div className="gdlr-core-portfolio-content-wrap gdlr-core-skin-divider">
                                                        <h3 className="gdlr-core-portfolio-title gdlr-core-skin-title" style={{ "fontSize": "19px", "fontWeight": 600, "letterSpacing": "0px", "textTransform": "none" }}><a href="/#" >Tokyo Soho Hotel</a></h3><span className="gdlr-core-portfolio-info gdlr-core-portfolio-info-tag gdlr-core-info-font gdlr-core-skin-caption"><a href="/#" rel="tag">Architecture</a> <span className="gdlr-core-sep">/</span> <a href="/#" rel="tag">Soho</a></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="gdlr-core-item-list className1 gdlr-core-item-pdlr gdlr-core-column-15 ">
                                                <div className="gdlr-core-portfolio-grid  gdlr-core-left-align gdlr-core-style-normal">
                                                    <div className="gdlr-core-portfolio-thumbnail gdlr-core-media-image  gdlr-core-style-title-icon">
                                                        <div className="gdlr-core-portfolio-thumbnail-image-wrap  gdlr-core-zoom-on-hover"><img src="upload/tobias-van-schneider-230813-unsplash-700x450.jpg" alt="" width="700" height="450" title="tobias-van-schneider-230813-unsplash" /><span className="gdlr-core-image-overlay  gdlr-core-portfolio-overlay gdlr-core-image-overlay-center-icon gdlr-core-js"><span className="gdlr-core-image-overlay-content" ><span className="gdlr-core-portfolio-title gdlr-core-title-font " style={{ "fontSize": "18px", "fontWeight": 400, "letterSpacing": "1px", "textTransform": "none" }}  ><a href="/#" >TomTom Housing Project</a></span><span className="gdlr-core-portfolio-icon-wrap"><a className="gdlr-core-lightgallery gdlr-core-js " href="upload/tobias-van-schneider-230813-unsplash.jpg" data-lightbox-group="gdlr-core-img-group-1"><i className="gdlr-core-portfolio-icon arrow_expand" ></i></a></span></span>
                                                        </span>
                                                        </div>
                                                    </div>
                                                    <div className="gdlr-core-portfolio-content-wrap gdlr-core-skin-divider">
                                                        <h3 className="gdlr-core-portfolio-title gdlr-core-skin-title" style={{ "fontSize": "19px", "fontWeight": 600, "letterSpacing": "0px", "textTransform": "none" }}><a href="/#" >TomTom Housing Project</a></h3><span className="gdlr-core-portfolio-info gdlr-core-portfolio-info-tag gdlr-core-info-font gdlr-core-skin-caption"><a href="/#" rel="tag">Clean</a> <span className="gdlr-core-sep">/</span> <a href="/#" rel="tag">Store</a></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="gdlr-core-item-list className2 gdlr-core-item-pdlr gdlr-core-column-15">
                                                <div className="gdlr-core-portfolio-grid  gdlr-core-left-align gdlr-core-style-normal">
                                                    <div className="gdlr-core-portfolio-thumbnail gdlr-core-media-image  gdlr-core-style-title-icon">
                                                        <div className="gdlr-core-portfolio-thumbnail-image-wrap  gdlr-core-zoom-on-hover"><img src="upload/davide-cantelli-240809-unsplash-700x450.jpg" alt="" width="700" height="450" title="Image Title" /><span className="gdlr-core-image-overlay  gdlr-core-portfolio-overlay gdlr-core-image-overlay-center-icon gdlr-core-js"><span className="gdlr-core-image-overlay-content" ><span className="gdlr-core-portfolio-title gdlr-core-title-font " style={{ "fontSize": "18px", "fontWeight": 400, "letterSpacing": "1px", "textTransform": "none" }}  ><a href="/#" >Zangzak Residential Tower</a></span><span className="gdlr-core-portfolio-icon-wrap"><a className="gdlr-core-lightgallery gdlr-core-js " href="upload/davide-cantelli-240809-unsplash.jpg" data-sub-html="Minimal Interior Design" data-lightbox-group="gdlr-core-img-group-1"><i className="gdlr-core-portfolio-icon arrow_expand" ></i></a></span></span>
                                                        </span>
                                                        </div>
                                                    </div>
                                                    <div className="gdlr-core-portfolio-content-wrap gdlr-core-skin-divider">
                                                        <h3 className="gdlr-core-portfolio-title gdlr-core-skin-title" style={{ "fontSize": "19px", "fontWeight": 600, "letterSpacing": "0px", "textTransform": "none" }}><a href="/#" >Zangzak Residential Tower</a></h3><span className="gdlr-core-portfolio-info gdlr-core-portfolio-info-tag gdlr-core-info-font gdlr-core-skin-caption"><a href="/#" rel="tag">Architecture</a> <span className="gdlr-core-sep">/</span> <a href="/#" rel="tag">interior</a></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="gdlr-core-item-list className2 gdlr-core-item-pdlr gdlr-core-column-15">
                                                <div className="gdlr-core-portfolio-grid  gdlr-core-left-align gdlr-core-style-normal">
                                                    <div className="gdlr-core-portfolio-thumbnail gdlr-core-media-image  gdlr-core-style-title-icon">
                                                        <div className="gdlr-core-portfolio-thumbnail-image-wrap  gdlr-core-zoom-on-hover"><img src="upload/davide-cantelli-240809-unsplash-700x450.jpg" alt="" width="700" height="450" title="Image Title" /><span className="gdlr-core-image-overlay  gdlr-core-portfolio-overlay gdlr-core-image-overlay-center-icon gdlr-core-js"><span className="gdlr-core-image-overlay-content" ><span className="gdlr-core-portfolio-title gdlr-core-title-font " style={{ "fontSize": "18px", "fontWeight": 400, "letterSpacing": "1px", "textTransform": "none" }}  ><a href="/#" >Zangzak Residential Tower</a></span><span className="gdlr-core-portfolio-icon-wrap"><a className="gdlr-core-lightgallery gdlr-core-js " href="upload/davide-cantelli-240809-unsplash.jpg" data-sub-html="Minimal Interior Design" data-lightbox-group="gdlr-core-img-group-1"><i className="gdlr-core-portfolio-icon arrow_expand" ></i></a></span></span>
                                                        </span>
                                                        </div>
                                                    </div>
                                                    <div className="gdlr-core-portfolio-content-wrap gdlr-core-skin-divider">
                                                        <h3 className="gdlr-core-portfolio-title gdlr-core-skin-title" style={{ "fontSize": "19px", "fontWeight": 600, "letterSpacing": "0px", "textTransform": "none" }}><a href="/#" >Zangzak Residential Tower</a></h3><span className="gdlr-core-portfolio-info gdlr-core-portfolio-info-tag gdlr-core-info-font gdlr-core-skin-caption"><a href="/#" rel="tag">Architecture</a> <span className="gdlr-core-sep">/</span> <a href="/#" rel="tag">interior</a></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        content: state.common.content
    }
}

export default connect(mapStateToProps, null)(Portfolio);