import React, { useEffect, lazy } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import server from '../../configs/domain';
import './home.component.css';
import HomeClients from './homeClients.component';
import ReactLazy from 'react-laz-y-component-loader';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const HomeOurStory = lazy(() => import('./homeOurStory.component'));
function Home() {
    const sliders = useSelector(state => state.common.content.sliders);
    const services = useSelector(state => state.common.content.services);
    const projects = useSelector(state => state.common.content.imageGallery);
    const blogs = useSelector(state => state.common.content.blogs);
    const setting = useSelector(state => state.common.content.setting);
    useEffect(() => {
        if (!sliders) return;
        // function setREVStartSize(e) {
        //     try {
        //         // eslint-disable-next-line no-undef
        //         e.c = jQuery(e.c);
        //         // eslint-disable-next-line no-undef
        //         var i = jQuery(window).width(),
        //             t = 9999,
        //             r = 0,
        //             n = 0,
        //             l = 0,
        //             f = 0,
        //             s = 0,
        //             h = 0;
        //         // eslint-disable-next-line no-undef
        //         if (e.responsiveLevels && (jQuery.each(e.responsiveLevels, function (e, f) {
        //             // eslint-disable-next-line no-unused-expressions
        //             f > i && (t = r = f, l = e), i > f && f > r && (r = f, n = e)
        //         }), t > r && (l = n)), f = e.gridheight[l] || e.gridheight[0] || e.gridheight, s = e.gridwidth[l] || e.gridwidth[0] || e.gridwidth, h = i / s, h = h > 1 ? 1 : h, f = Math.round(h * f), "fullscreen" == e.sliderLayout) {
        //             // eslint-disable-next-line no-undef
        //             var u = (e.c.width(), jQuery(window).height());
        //             // eslint-disable-next-line eqeqeq
        //             if (void 0 != e.fullScreenOffsetContainer) {
        //                 var c = e.fullScreenOffsetContainer.split(",");
        //                 // eslint-disable-next-line no-undef
        //                 let tmpJquery = jQuery;
        //                 // eslint-disable-next-line no-unused-expressions 
        //                 if (c) tmpJquery.each(c, function (e, i) {
        //                     // eslint-disable-next-line no-undef
        //                     u = jQuery(i).length > 0 ? u - jQuery(i).outerHeight(!0) : u
        //                     // eslint-disable-next-line no-undef
        //                 }), e.fullScreenOffset.split("%").length > 1 && void 0 != e.fullScreenOffset && e.fullScreenOffset.length > 0 ? u -= jQuery(window).height() * parseInt(e.fullScreenOffset, 0) / 100 : void 0 != e.fullScreenOffset && e.fullScreenOffset.length > 0 && (u -= parseInt(e.fullScreenOffset, 0))
        //             }
        //             f = u
        //         } else void 0 != e.minHeight && f < e.minHeight && (f = e.minHeight);
        //         e.c.closest(".rev_slider_wrapper").css({
        //             height: f
        //         })
        //     } catch (d) {
        //         console.log("Failure at Presize of Slider:" + d)
        //     }
        // };



        // function revslider_showDoubleJqueryError(sliderID) {
        //     var errorMessage = "Revolution Slider Error: You have some jquery.js library include that comes after the revolution files js include.";
        //     errorMessage += "<br> This includes make eliminates the revolution slider libraries, and make it not work.";
        //     errorMessage += "<br><br> To fix it you can:<br>&nbsp;&nbsp;&nbsp; 1. In the Slider Settings -> Troubleshooting set option:  <strong><b>Put JS Includes To Body</b></strong> option to true.";
        //     errorMessage += "<br>&nbsp;&nbsp;&nbsp; 2. Find the double jquery.js include and remove it.";
        //     errorMessage = "<span style='fontSize:16px;color:#BC0C06;'>" + errorMessage + "</span>";
        //     // eslint-disable-next-line no-undef
        //     jQuery(sliderID).show().html(errorMessage);
        // }



        // var htmlDiv = document.getElementById("rs-plugin-settings-inline-css");
        // var htmlDivCss = "";
        // if (htmlDiv) {
        //     htmlDiv.innerHTML = htmlDiv.innerHTML + htmlDivCss;
        // } else {
        //     htmlDiv = document.createElement("div");
        //     htmlDiv.innerHTML = "<style>" + htmlDivCss + "</style>";
        //     document.getElementsByTagName("head")[0].appendChild(htmlDiv.childNodes[0]);
        // }



        // eslint-disable-next-line no-undef
        if (setREVStartSize !== undefined) setREVStartSize({
            c: '#rev_slider_2_1',
            gridwidth: [1340],
            gridheight: [890],
            sliderLayout: 'auto'
        });

        var
            tpj;
        (function () {
            if (!/loaded|interactive|complete/.test(document.readyState)) document.addEventListener("DOMContentLoaded", onLoad);
            else onLoad();

            function onLoad() {
                if (tpj === undefined) {
                    // eslint-disable-next-line no-undef
                    tpj = jQuery;
                    if ("off" === "on") tpj.noConflict();
                }
                if (tpj("#rev_slider_2_1").revolution === undefined) {
                    // eslint-disable-next-line no-undef
                    revslider_showDoubleJqueryError("#rev_slider_2_1");
                } else {
                    tpj("#rev_slider_2_1").show().revolution({
                        sliderType: "standard",
                        jsFileLocation: "plugins/revslider/public/assets/js/",
                        sliderLayout: "auto",
                        dottedOverlay: "none",
                        delay: 9000,
                        navigation: {
                            keyboardNavigation: "off",
                            keyboard_direction: "horizontal",
                            mouseScrollNavigation: "off",
                            mouseScrollReverse: "default",
                            onHoverStop: "off",
                            bullets: {
                                enable: true,
                                hide_onmobile: false,
                                style: "uranus",
                                hide_onleave: false,
                                direction: "horizontal",
                                h_align: "center",
                                v_align: "bottom",
                                h_offset: 0,
                                v_offset: 40,
                                space: 8,
                                tmp: '<span class="tp-bullet-inner"></span>'
                            }
                        },
                        visibilityLevels: [1240, 1024, 778, 480],
                        gridwidth: 1340,
                        gridheight: 890,
                        lazyType: "All",
                        shadow: 0,
                        spinner: "off",
                        stopLoop: "off",
                        stopAfterLoops: -1,
                        stopAtSlide: -1,
                        shuffle: "off",
                        autoHeight: "off",
                        disableProgressBar: "on",
                        hideThumbsOnMobile: "off",
                        hideSliderAtLimit: 0,
                        hideCaptionAtLimit: 0,
                        hideAllCaptionAtLilmit: 0,
                        debugMode: false,
                        fallbacks: {
                            simplifyAll: "off",
                            nextSlideOnWindowFocus: "off",
                            disableFocusListener: false,
                        }
                    });
                }; /* END OF revapi call */

            }; /* END OF ON LOAD FUNCTION */
        }()); /* END OF WRAPPING FUNCTION */



        // var htmlDivCss = unescape("%23rev_slider_2_1%20.uranus%20.tp-bullet%7B%0A%20%20borderRadius%3A%2050%25%3B%0A%20%20boxShadow%3A%200%200%200%202px%20rgba%28255%2C%20255%2C%20255%2C%200%29%3B%0A%20%20-webkit-transition%3A%20boxShadow%200.3s%20ease%3B%0A%20%20transition%3A%20boxShadow%200.3s%20ease%3B%0A%20%20background%3Atransparent%3B%0A%20%20width%3A15px%3B%0A%20%20height%3A15px%3B%0A%7D%0A%23rev_slider_2_1%20.uranus%20.tp-bullet.selected%2C%0A%23rev_slider_2_1%20.uranus%20.tp-bullet%3Ahover%20%7B%0A%20%20boxShadow%3A%200%200%200%202px%20rgba%28255%2C%20255%2C%20255%2C1%29%3B%0A%20%20border%3Anone%3B%0A%20%20borderRadius%3A%2050%25%3B%0A%20%20background%3Atransparent%3B%0A%7D%0A%0A%23rev_slider_2_1%20.uranus%20.tp-bullet-inner%20%7B%0A%20%20-webkit-transition%3A%20backgroundColor%200.3s%20ease%2C%20-webkit-transform%200.3s%20ease%3B%0A%20%20transition%3A%20backgroundColor%200.3s%20ease%2C%20transform%200.3s%20ease%3B%0A%20%20top%3A%200%3B%0A%20%20left%3A%200%3B%0A%20%20width%3A%20100%25%3B%0A%20%20height%3A%20100%25%3B%0A%20%20outline%3A%20none%3B%0A%20%20borderRadius%3A%2050%25%3B%0A%20%20backgroundColor%3A%20rgb%28255%2C%20255%2C%20255%29%3B%0A%20%20backgroundColor%3A%20rgba%28255%2C%20255%2C%20255%2C%200.3%29%3B%0A%20%20text-indent%3A%20-999em%3B%0A%20%20cursor%3A%20pointer%3B%0A%20%20position%3A%20absolute%3B%0A%7D%0A%0A%23rev_slider_2_1%20.uranus%20.tp-bullet.selected%20.tp-bullet-inner%2C%0A%23rev_slider_2_1%20.uranus%20.tp-bullet%3Ahover%20.tp-bullet-inner%7B%0A%20transform%3A%20scale%280.4%29%3B%0A%20-webkit-transform%3A%20scale%280.4%29%3B%0A%20backgroundColor%3Argb%28255%2C%20255%2C%20255%29%3B%0A%7D%0A");
        // var htmlDiv = document.getElementById('rs-plugin-settings-inline-css');
        // if (htmlDiv) {
        //     htmlDiv.innerHTML = htmlDiv.innerHTML + htmlDivCss;
        // } else {
        //     htmlDiv = document.createElement('div');
        //     htmlDiv.innerHTML = '<style>' + htmlDivCss + '</style>';
        //     document.getElementsByTagName('head')[0].appendChild(htmlDiv.childNodes[0]);
        // }



        // var loadScript = function (src) {
        //     var tag = document.createElement('script');
        //     tag.async = false;
        //     tag.src = src;
        //     var body = document.getElementsByTagName('body')[0];
        //     body.appendChild(tag);
        // }

        // loadScript('plugins/goodlayers-core/plugins/combine/script.js');

        // loadScript('plugins/goodlayers-core/include/js/page-builder.js');
        // loadScript('js/jquery/ui/effect.min.js');
        // loadScript('js/plugins.js');

        // window.jQuery('body').each(function () {
        // let $ = window.jQuery;
        // // eslint-disable-next-line no-undef
        // var gdlr_core_js = $(this).find('.gdlr-core-js-2');

        // // title divider
        // // eslint-disable-next-line no-undef
        // $(this).gdlr_core_title_divider(gdlr_core_js);

        // // flexslider
        // // eslint-disable-next-line no-undef
        // $(this).gdlr_core_flexslider(gdlr_core_js);

        // // sly slider
        // // eslint-disable-next-line no-undef
        // $(this).gdlr_core_sly(gdlr_core_js);

        // // isotope
        // // eslint-disable-next-line no-undef
        // $(this).gdlr_core_isotope(gdlr_core_js);

        // });
    }, [sliders]);
    return (


        <div className="inteco-page-wrapper" id="inteco-page-wrapper">
            <div className="gdlr-core-page-builder-body">
                <div className="gdlr-core-pbf-wrapper " style={{ padding: "0px 0px 0px 0px" }}>
                    <div className="gdlr-core-pbf-background-wrap" style={{ "backgroundColor": "#262626" }}></div>
                    <div className="gdlr-core-pbf-wrapper-content gdlr-core-js ">
                        <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-pbf-wrapper-full-no-space">
                            <div className="gdlr-core-pbf-element">
                                <div className="gdlr-core-revolution-slider-item gdlr-core-item-pdlr gdlr-core-item-pdb "
                                    style={{ "paddingBottom": "0px" }}>
                                    <div id="rev_slider_2_1_wrapper"
                                        className="rev_slider_wrapper fullwidthbanner-container" data-source="gallery"
                                        style={{ margin: "0px auto", background: "transparent", padding: "0px", "marginTop": "0px", "marginBottom": "0px" }}>
                                        {/* <!-- START REVOLUTION SLIDER 5.4.8 auto mode --> */}
                                        <div id="rev_slider_2_1" className="rev_slider fullwidthabanner"
                                            style={{ display: "none" }} data-version="5.4.8">
                                            <ul>
                                                {sliders && sliders.map((slider, index) =>
                                                    <li data-index={`rs-${index + 1}`} data-transition="fade"
                                                        data-slotamount="default" data-hideafterloop="0"
                                                        data-hideslideonmobile="off" data-easein="default"
                                                        data-easeout="default" data-masterspeed="300"
                                                        data-thumb="upload/hp1-slider-1-100x50.jpg" data-rotate="0"
                                                        data-saveperformance="off" data-title="Slide" data-param1=""
                                                        data-param2="" data-param3="" data-param4="" data-param5=""
                                                        data-param6="" data-param7="" data-param8="" data-param9=""
                                                        data-param10="" data-description="" key={'home-' + slider.title + index}>
                                                        {/* <!-- MAIN IMAGE --> */}
                                                        <img effect="blur" src={`${server}/${sliders[0].image}`} alt="" title="hp1-slider-1"
                                                            width="1800" height="1200" data-bgposition="center center"
                                                            data-bgfit="cover" data-bgrepeat="no-repeat"
                                                            className="rev-slidebg" data-no-retina data-lazyload={`${server}/${slider.image}`} />
                                                        {/* <!-- LAYERS --> */}

                                                        {/* <!-- LAYER NR. 1 --> */}


                                                        {/* <!-- LAYER NR. 2 --> */}
                                                        <div className="tp-caption   tp-resizeme" id={`slide-${index + 1}-layer-3`}
                                                            data-x="center" data-y="center" data-voffset="0"
                                                            data-width="['auto']" data-height="['auto']"
                                                            data-type="text" data-responsive_offset="on"
                                                            data-frames='[{"delay":680,"speed":300,"frame":"0","from":"x:50px;opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                                                            data-textalign="['inherit','inherit','inherit','inherit']"
                                                            data-paddingtop="[0,10,0,0]" data-paddingright="[0,0,0,0]"
                                                            data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]"
                                                            style={{ "zIndex": 6, "whiteSpace": "nowrap", "fontSize": "43px", "lineHeight": "43px", "fontWeight": 400, color: "#ffffff", "letterSpacing": "0px", "fontFamily": 'arcamajora bold' }}>
                                                            {slider.title} </div>

                                                        {/* <!-- LAYER NR. 3 --> */}
                                                        {/* <div className="tp-caption tp-shape tp-shapewrapper  tp-resizeme"
                                                            id="slide-2-layer-5" data-x="483" data-y="480"
                                                            data-width="['159']" data-height="['12']"
                                                            data-visibility="['on','on','off','off']" data-type="shape"
                                                            data-responsive_offset="on"
                                                            data-frames='[{"delay":930,"speed":300,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                                                            data-textalign="['inherit','inherit','inherit','inherit']"
                                                            data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]"
                                                            data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]"
                                                            style={{ "zIndex": 7, "backgroundColor": "rgb(154,130,125)" }}>
                                                        </div> */}

                                                        {/* <!-- LAYER NR. 4 --> */}
                                                        {/* <div className="tp-caption rev-btn  tp-resizeme"
                                                            id="slide-2-layer-4" data-x="463" data-y="center"
                                                            data-voffset="33" data-width="['auto']"
                                                            data-height="['auto']" data-type="button"
                                                            data-responsive_offset="on"
                                                            data-frames='[{"delay":940,"speed":300,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"},{"frame":"hover","speed":"0","ease":"Linear.easeNone","to":"o:1;rX:0;rY:0;rZ:0;z:0;","style":"c:rgb(198,159,159);bs:solid;bw:0 0 0 0;"}]'
                                                            data-textalign="['inherit','inherit','inherit','inherit']"
                                                            data-paddingtop="[7,7,7,7]"
                                                            data-paddingright="[20,20,20,20]"
                                                            data-paddingbottom="[7,7,7,7]"
                                                            data-paddingleft="[20,20,20,20]"
                                                            style={{ "zIndex": 8, "whiteSpace": "nowrap", "fontSize": "21px", "lineHeight": "12px", "fontWeight": 500, color: "rgba(255,255,255,1)", "letterSpacing": "0px", "fontFamily": 'Arcamajora bold', "borderColor": "rgba(0,0,0,1)", outline: "none", "boxShadow": "none", "boxSizing": "border-box", "MozBoxSizing": "border-box", "WebkitBoxSizing": "border-box", cursor: "pointer" }}>
                                                            <NavLink to={`${slider.categoryUrlSlug}`} style={{ color: "unset" }}>View Our Works </NavLink></div> */}

                                                        {/* <!-- LAYER NR. 5 --> */}

                                                    </li>
                                                )}
                                                {/* <!-- SLIDE  --> */}
                                                {/* <li data-index="rs-2" data-transition="fade"
                                                    data-slotamount="default" data-hideafterloop="0"
                                                    data-hideslideonmobile="off" data-easein="default"
                                                    data-easeout="default" data-masterspeed="300"
                                                    data-thumb="upload/hp1-slider-1-100x50.jpg" data-rotate="0"
                                                    data-saveperformance="off" data-title="Slide" data-param1=""
                                                    data-param2="" data-param3="" data-param4="" data-param5=""
                                                    data-param6="" data-param7="" data-param8="" data-param9=""
                                                    data-param10="" data-description=""> */}
                                                {/* <!-- MAIN IMAGE --> */}
                                                {/* <LazyLoadImage effect="blur" src="upload/slider-1.jpg" alt="" title="hp1-slider-1"
                                                        width="1800" height="1200" data-bgposition="center center"
                                                        data-bgfit="cover" data-bgrepeat="no-repeat"
                                                        className="rev-slidebg" data-no-retina /> */}
                                                {/* <!-- LAYERS --> */}

                                                {/* <!-- LAYER NR. 1 --> */}
                                                {/* <div className="tp-caption   tp-resizeme" id="slide-2-layer-2"
                                                        data-x="675" data-y="486"
                                                        data-width="['none','none','none','none']"
                                                        data-height="['none','none','none','none']"
                                                        data-visibility="['on','on','off','off']" data-type="image"
                                                        data-responsive_offset="on"
                                                        data-frames='[{"delay":360,"speed":300,"frame":"0","from":"x:right;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                                                        data-textAlign="['inherit','inherit','inherit','inherit']"
                                                        data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]"
                                                        data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]"
                                                        style={{ "zIndex": 5 }}><LazyLoadImage effect="blur" src="upload/hp1-slider-1-2.png"
                                                            alt="" data-ww="1166px" data-hh="1px" width="1166"
                                                            height="1" data-no-retina /> </div> */}

                                                {/* <!-- LAYER NR. 2 --> */}
                                                {/* <div className="tp-caption   tp-resizeme" id="slide-2-layer-3"
                                                        data-x="484" data-y="center" data-voffset="-39"
                                                        data-width="['auto']" data-height="['auto']"
                                                        data-type="text" data-responsive_offset="on"
                                                        data-frames='[{"delay":680,"speed":300,"frame":"0","from":"x:50px;opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                                                        data-textAlign="['inherit','inherit','inherit','inherit']"
                                                        data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]"
                                                        data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]"
                                                        style={{ "zIndex": 6, "whiteSpace": "nowrap", "fontSize": "43px", "lineHeight": "43px", "fontWeight": 400, color: "#ffffff", "letterSpacing": "0px", "fontFamily": 'arcamajora bold' }}>
                                                        We Create Amazing Designs </div> */}

                                                {/* <!-- LAYER NR. 3 --> */}
                                                {/* <div className="tp-caption tp-shape tp-shapewrapper  tp-resizeme"
                                                        id="slide-2-layer-5" data-x="483" data-y="480"
                                                        data-width="['159']" data-height="['12']"
                                                        data-visibility="['on','on','off','off']" data-type="shape"
                                                        data-responsive_offset="on"
                                                        data-frames='[{"delay":930,"speed":300,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                                                        data-textAlign="['inherit','inherit','inherit','inherit']"
                                                        data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]"
                                                        data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]"
                                                        style={{ "zIndex": 7, "backgroundColor": "rgb(154,130,125)" }}>
                                                    </div> */}

                                                {/* <!-- LAYER NR. 4 --> */}
                                                {/* <div className="tp-caption rev-btn  tp-resizeme"
                                                        id="slide-2-layer-4" data-x="463" data-y="center"
                                                        data-voffset="33" data-width="['auto']"
                                                        data-height="['auto']" data-type="button"
                                                        data-responsive_offset="on"
                                                        data-frames='[{"delay":940,"speed":300,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"},{"frame":"hover","speed":"0","ease":"Linear.easeNone","to":"o:1;rX:0;rY:0;rZ:0;z:0;","style":"c:rgb(198,159,159);bs:solid;bw:0 0 0 0;"}]'
                                                        data-textAlign="['inherit','inherit','inherit','inherit']"
                                                        data-paddingtop="[7,7,7,7]"
                                                        data-paddingright="[20,20,20,20]"
                                                        data-paddingbottom="[7,7,7,7]"
                                                        data-paddingleft="[20,20,20,20]"
                                                        style={{ "zIndex": 8, "whiteSpace": "nowrap", "fontSize": "21px", "lineHeight": "12px", "fontWeight": 500, color: "rgba(255,255,255,1)", "letterSpacing": "0px", "fontFamily": 'Arcamajora bold', "borderColor": "rgba(0,0,0,1)", outline: "none", "boxShadow": "none", "boxSizing": "border-box", "MozBoxSizing": "border-box", "WebkitBoxSizing": "border-box", cursor: "pointer" }}>
                                                        View Our Works </div> */}

                                                {/* <!-- LAYER NR. 5 --> */}
                                                {/* <div className="tp-caption   tp-resizeme" id="slide-2-layer-9"
                                                        data-x="483" data-y="-681"
                                                        data-width="['none','none','none','none']"
                                                        data-height="['none','none','none','none']"
                                                        data-type="image" data-responsive_offset="on"
                                                        data-frames='[{"delay":0,"speed":300,"frame":"0","from":"y:top;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                                                        data-textAlign="['inherit','inherit','inherit','inherit']"
                                                        data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]"
                                                        data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]"
                                                        style={{ "zIndex": 9 }}><LazyLoadImage effect="blur" src="upload/hp1-slider-1-1-1.png"
                                                            alt="" data-ww="1px" data-hh="1166px" width="1"
                                                            height="1166" data-no-retina /> </div>
                                                </li> */}
                                                {/* <!-- SLIDE  --> */}
                                                {/* <li data-index="rs-3" data-transition="fade"
                                                    data-slotamount="default" data-hideafterloop="0"
                                                    data-hideslideonmobile="off" data-easein="default"
                                                    data-easeout="default" data-masterspeed="300"
                                                    data-thumb="upload/hp1-slider-2-100x50.jpg" data-rotate="0"
                                                    data-saveperformance="off" data-title="Slide" data-param1=""
                                                    data-param2="" data-param3="" data-param4="" data-param5=""
                                                    data-param6="" data-param7="" data-param8="" data-param9=""
                                                    data-param10="" data-description="">

                                                    <LazyLoadImage effect="blur" src="https://a6e8z9v6.stackpathcdn.com/inteco/wp-content/uploads/2018/09/hp1-slider-1.jpg"
                                                        alt="" title="hp1-slider-2" width="1800" height="1200"
                                                        data-bgposition="center center" data-bgfit="cover"
                                                        data-bgrepeat="no-repeat" className="rev-slidebg"
                                                        data-no-retina />

                                                    <div className="tp-caption   tp-resizeme" id="slide-3-layer-3"
                                                        data-x="center" data-hoffset="" data-y="center"
                                                        data-voffset="-19" data-width="['auto']"
                                                        data-height="['auto']" data-type="text"
                                                        data-responsive_offset="on"
                                                        data-frames='[{"delay":680,"speed":300,"frame":"0","from":"x:50px;opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                                                        data-textAlign="['inherit','inherit','inherit','inherit']"
                                                        data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]"
                                                        data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]"
                                                        style={{ "zIndex": 5, "whiteSpace": "nowrap", "fontSize": "43px", "lineHeight": "43px", "fontWeight": 400, color: "#ffffff", "letterSpacing": "0px", "fontFamily": 'arcamajora bold' }}>
                                                        All Details Are Important </div>

                                                    <div className="tp-caption tp-shape tp-shapewrapper  tp-resizeme"
                                                        id="slide-3-layer-5" data-x="center" data-hoffset=""
                                                        data-y="521" data-width="['159']" data-height="['6']"
                                                        data-visibility="['on','on','off','off']" data-type="shape"
                                                        data-responsive_offset="on"
                                                        data-frames='[{"delay":930,"speed":300,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                                                        data-textAlign="['inherit','inherit','inherit','inherit']"
                                                        data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]"
                                                        data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]"
                                                        style={{ "zIndex": 6, "backgroundColor": "rgb(255,255,255)" }}>
                                                    </div>

                                                    <div className="tp-caption rev-btn  tp-resizeme"
                                                        id="slide-3-layer-4" data-x="center" data-hoffset=""
                                                        data-y="center" data-voffset="53" data-width="['auto']"
                                                        data-height="['auto']" data-type="button"
                                                        data-responsive_offset="on"
                                                        data-frames='[{"delay":940,"speed":300,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"},{"frame":"hover","speed":"0","ease":"Linear.easeNone","to":"o:1;rX:0;rY:0;rZ:0;z:0;","style":"c:rgb(137,183,229);bs:solid;bw:0 0 0 0;"}]'
                                                        data-textAlign="['inherit','inherit','inherit','inherit']"
                                                        data-paddingtop="[7,7,7,7]"
                                                        data-paddingright="[20,20,20,20]"
                                                        data-paddingbottom="[7,7,7,7]"
                                                        data-paddingleft="[20,20,20,20]"
                                                        style={{ "zIndex": 7, "whiteSpace": "nowrap", "fontSize": "21px", "lineHeight": "12px", "fontWeight": 500, color: "rgba(255,255,255,1)", "letterSpacing": "0px", "fontFamily": 'Arcamajora bold', "borderColor": "rgba(0,0,0,1)", outline: "none", "boxShadow": "none", "boxSizing": "border-box", "MozBoxSizing": "border-box", "WebkitBoxSizing": "border-box", cursor: "pointer" }}>
                                                        View Our Works </div>
                                                </li> */}
                                            </ul>
                                            <div className="tp-bannertimer tp-bottom"
                                                style={{ visibility: "hidden !important" }}></div>
                                        </div>

                                    </div>
                                    {/* <!-- END REVOLUTION SLIDER --> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>

                    <div className="inteco-copyright-center inteco-item-pdlr " id="mobile-social-icon">
                        <span>
                            <a href="https://www.facebook.com/dit.interior" target="_blank" rel="noreferrer">
                                <i className="fa fa-facebook"
                                    style={{ "fontSize": "25px", "color": "#af938f", "marginRight": "12px" }}></i>
                            </a>
                            <a href="https://www.youtube.com/channel/UCH_kEOE-o6hlo1X1DVermTQ" target="_blank" rel="noreferrer">
                                <i className="fa fa-youtube"
                                    style={{ "fontSize": "25px", "color": "#af938f", "marginRight": "12px" }}></i>
                            </a>
                            {/* <a href="https://www.linkedin.com/in/design-ideas-and-technology-2a79a321a/" target="_blank" rel="noreferrer">
                                <i className="fa fa-linkedin"
                                    style={{ "fontSize": "25px", "color": "#af938f", "marginRight": "12px" }}></i>
                            </a> */}
                            <a href="https://www.instagram.com/ditbd" target="_blank" rel="noreferrer">
                                <i className="fa fa-instagram"
                                    style={{ "fontSize": "25px", "color": "#af938f", "marginRight": "12px" }}></i>
                            </a>
                            {/* <a href="https://twitter.com/Infoditbd" target="_blank" rel="noreferrer">
                                <i className="fa fa-twitter"
                                    style={{ "fontSize": "25px", "color": "#af938f", "marginRight": "12px" }}></i>
                            </a> */}
                        </span>
                    </div>
                </div>

                <div className="gdlr-core-pbf-wrapper " style={{ padding: "40px 0px 0px 0px" }}>
                <div className="gdlr-core-pbf-background-wrap">
                    <div className="gdlr-core-pbf-background gdlr-core-parallax gdlr-core-js"
                        style={{ "backgroundImage": "url(https://a6e8z9v6.stackpathcdn.com/inteco/wp-content/uploads/2018/09/hp1-col-service-bg.png)", "backgroundRepeat": "no-repeat", "backgroundPosition": "top center" }}
                        data-parallax-speed="0.1"></div>
                </div>
                <div className="gdlr-core-pbf-wrapper-content">
                    <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
                        <div className="gdlr-core-pbf-element">
                            <div className="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-center-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                                style={{ "paddingBottom": "40px" }}>
                                <div className="gdlr-core-title-item-title-wrap ">
                                    <h3 className="gdlr-core-title-item-title gdlr-core-skin-title "
                                        style={{ "fontSize": "32px", "letterSpacing": "0px", "textTransform": "none" }}>
                                        Services .</h3>
                                </div>
                            </div>
                        </div>
                        {services && services.slice(0, 3).map((service, index) =>
                            <div className="gdlr-core-pbf-column gdlr-core-column-20" key={'home-' + service.name + index}>
                                <div className="gdlr-core-pbf-column-content-margin">
                                    <div className="gdlr-core-pbf-column-content clearfix">
                                        <div className="gdlr-core-pbf-element">
                                            <div
                                                className="gdlr-core-image-item gdlr-core-item-pdlr gdlr-core-item-pdb  gdlr-core-center-align">
                                                <div className="gdlr-core-image-item-wrap gdlr-core-media-image  gdlr-core-image-item-style-rectangle"
                                                    style={{ "borderWidth": "0px" }}><LazyLoadImage effect="blur"
                                                        src={`${server}/${service.image1}`} alt=""
                                                        width="700" height="430" title="shutterstock_578977615" style={{ height: "270px", objectFit: "cover" }} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="gdlr-core-pbf-element">
                                            <div className="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-center-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                                                style={{ "paddingBottom": "17px" }} id="gdlr-core-title-item-2"><span
                                                    className="gdlr-core-title-item-caption gdlr-core-info-font gdlr-core-skin-caption"
                                                    style={{ "fontSize": "19px", "fontWeight": 800, "fontStyle": "normal", color: "#212121" }}>{service.name}</span></div>
                                        </div>
                                        <div className="gdlr-core-pbf-element">
                                            <div className="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-center-align"
                                                style={{ "paddingBottom": "0px " }}>
                                                <div className="gdlr-core-text-box-item-content"
                                                    style={{ "textTransform": "none" }}>
                                                    <p>
                                                        {/* {service.shortDesc} */}
                                                        <div dangerouslySetInnerHTML={{ __html: service.shortDesc }} />
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="gdlr-core-pbf-element">
                                            <div
                                                className="gdlr-core-button-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-center-align">
                                                <NavLink className="gdlr-core-button  gdlr-core-button-transparent gdlr-core-button-no-border"
                                                    to={`/service/${service.name}`}
                                                    style={{ "fontSize": "15px", "fontWeight": 500, "letterSpacing": "0px", color: "#9a827d", padding: "0px 0px 8px 0px", "textTransform": "none", "borderRadius": "0px", "MozBorderRadius": "0px", "WebkitBorderRadius": " 0px" }}><span
                                                        className="gdlr-core-content">Learn More</span><i
                                                            className="gdlr-core-pos-right fa fa-long-arrow-right"
                                                            style={{ color: "#9a827d " }}></i></NavLink></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {/* <div className="gdlr-core-pbf-column gdlr-core-column-20">
                                <div className="gdlr-core-pbf-column-content-margin gdlr-core-js ">
                                    <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                                        <div className="gdlr-core-pbf-element">
                                            <div
                                                className="gdlr-core-image-item gdlr-core-item-pdlr gdlr-core-item-pdb  gdlr-core-center-align">
                                                <div className="gdlr-core-image-item-wrap gdlr-core-media-image  gdlr-core-image-item-style-rectangle"
                                                    style={{ "borderWidth": "0px" }}><LazyLoadImage effect="blur"
                                                        src="upload/First Floor common area (23).jpg" alt=""
                                                        width="700" height="430"
                                                        title="daan-stevens-549933-unsplash" /></div>
                                            </div>
                                        </div>
                                        <div className="gdlr-core-pbf-element">
                                            <div className="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-center-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                                                style={{ "paddingBottom": "17px" }} id="gdlr-core-title-item-3"><span
                                                    className="gdlr-core-title-item-caption gdlr-core-info-font gdlr-core-skin-caption"
                                                    style={{ "fontSize": "19px", "fontWeight": 800, "fontStyle": "normal", color: "#212121" }}>Furniture
                                                        Design</span></div>
                                        </div>
                                        <div className="gdlr-core-pbf-element">
                                            <div className="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-center-align"
                                                style={{ "paddingBottom": "0px" }}>
                                                <div className="gdlr-core-text-box-item-content"
                                                    style={{ "textTransform": "none" }}>
                                                    <p>Far far away, behind the word mountains, far from the
                                                        countries Vokalia and Consonantia, there live the blind
                                                            texts. Separated they.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="gdlr-core-pbf-element">
                                            <div
                                                className="gdlr-core-button-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-center-align">
                                                <a className="gdlr-core-button  gdlr-core-button-transparent gdlr-core-button-no-border"
                                                    href="/#"
                                                    style={{ "fontSize": "15px", "fontWeight": 500, "letterSpacing": "0px", color: "#9a827d", padding: "0px 0px 8px 0px", "textTransform": "none", "borderRadius": "0px", "MozBorderRadius": "0px", "WebkitBorderRadius": "0px" }}><span
                                                        className="gdlr-core-content">Learn More</span><i
                                                            className="gdlr-core-pos-right fa fa-long-arrow-right"
                                                            style={{ color: "#9a827d" }}></i></a></div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}


                    </div>
                </div>
            </div>
            {/* <div className="gdlr-core-pbf-wrapper "
                style={{ "marginLeft": "auto", "marginRight": "auto", padding: "226px 0px 30px 0px", "maxWidth": "1260px" }}
                id="gdlr-core-wrapper-1">
                <div className="gdlr-core-pbf-background-wrap">
                    <div className="gdlr-core-pbf-background gdlr-core-parallax gdlr-core-js"
                        style={{ "backgroundImage": "url(images/hp-about-bg.png)", "backgroundSize": "cover", "backgroundPosition": "top center" }}
                        data-parallax-speed="0.1"></div>

                </div>
                <div className="gdlr-core-pbf-wrapper-content gdlr-core-js " data-gdlr-animation="fadeInDown"
                    data-gdlr-animation-duration="600ms" data-gdlr-animation-offset="0.8">
                    <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
                        <div className="gdlr-core-pbf-column gdlr-core-column-60 gdlr-core-column-first"
                            id="gdlr-core-column-1">
                            <div className="gdlr-core-pbf-column-content-margin gdlr-core-js "
                                style={{ "margin": "0px 55px 0px 100px", padding: "75px 85px 36px 55px" }}>
                                <div className="gdlr-core-pbf-background-wrap"
                                    style={{ "boxShadow": "0 0 68px rgba(17, 17, 17,0.11)", "MozBoxShadow": "0 0 68px rgba(17, 17, 17,0.11)", "WebkitBoxShadow": "0 0 68px rgba(17, 17, 17,0.11)", "backgroundColor": "#ffffff", "background": "linear-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.4))", "MozBackground": "linear-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.4))", "OBackground": "linear-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.4))", "WebkitBackground": "linear-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.4))" }}>
                                </div>
                                <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                                    <div className="gdlr-core-pbf-element">
                                        <div className="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-bottom gdlr-core-item-pdlr"
                                            style={{ "paddingBottom": "29px" }}>
                                            <div className="gdlr-core-title-item-left-image gdlr-core-media-image">
                                                <LazyLoadImage effect="blur" src="upload/hp-1home-icon.png" alt="" width="58"
                                                    height="58" title="hp-1home-icon" /></div>
                                            <div className="gdlr-core-title-item-left-image-wrap">
                                                <div className="gdlr-core-title-item-title-wrap ">
                                                    <h3 className="gdlr-core-title-item-title gdlr-core-skin-title "
                                                        style={{ "fontSize": "34px", "letterSpacing": "0px", "textTransform": "none" }}>
                                                        Our Story</h3>
                                                </div><span
                                                    className="gdlr-core-title-item-caption gdlr-core-info-font gdlr-core-skin-caption"
                                                    style={{ "fontSize": "19px", "fontStyle": "normal", "marginTop": "0px" }}>Who
                                                    we are</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="gdlr-core-pbf-element">
                                        <div className="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align"
                                            style={{ "paddingBottom": "5px" }}>
                                            <div className="gdlr-core-text-box-item-content"
                                                style={{ "textTransform": "none" }}>
                                                <p>Far far away, behind the word mountains, far from the
                                                    countries Vokalia and Consonantia, there live the blind
                                                    texts. Separated they live in Bookmarksgrove right at the
                                                    coast of the Semantics, a large language ocean. A small
                                                    river named Duden flows by their place and supplies it with
                                                    the necessary regelialia. It is a paradisematic country, in
                                                    which roasted parts of sentences fly into your mouth. Even
                                                    the all-powerful.</p>
                                                <p>One day however a small line of blind text by the name of
                                                    Lorem Ipsum decided to leave for the far World of Grammar.
                                                    The Big Oxmox advised her not to do so, because there were
                                                    thousands of bad Commas, wild Question Marks and devious
                                                    Semikoli, but the Little Blind Text didn’t listen. She
                                                    packed her seven versalia, put her initial into the belt.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="gdlr-core-pbf-element">
                                        <div
                                            className="gdlr-core-button-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                                            <NavLink className="gdlr-core-button  gdlr-core-button-transparent gdlr-core-button-with-border"
                                                to="/our-story"
                                                style={{ "fontSize": "16px", "fontWeight": 600, "letterSpacing": "0px", color: "#9a827d", padding: "0px 0px 2px 0px", "textTransform": "none", "borderRadius": "0px", "MozBorderRadius": "0px", "WebkitBorderRadius": "0px", "borderWidth": "0px 0px 6px 0px", "borderColor": "#d8d8d8" }}><span
                                                    className="gdlr-core-content">Read More</span><i
                                                        className="gdlr-core-pos-right fa fa-long-arrow-right"
                                                        style={{ "fontSize": "19px", color: "#9a827d" }}></i></NavLink></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* <div className="gdlr-core-pbf-wrapper ">
                <div className="gdlr-core-pbf-background-wrap">
                    <div className="gdlr-core-pbf-background gdlr-core-parallax gdlr-core-js"
                        style={{ "backgroundImage": "url(https://a6e8z9v6.stackpathcdn.com/inteco/wp-content/uploads/2018/09/hp1-about-bg-2-1.jpg)", "backgroundRepeat": "no-repeat", "backgroundPosition": "center" }}
                        data-parallax-speed="-0.2"></div>
                </div>
                <div className="gdlr-core-pbf-wrapper-content gdlr-core-js ">
                    <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
                        <div className="gdlr-core-pbf-column gdlr-core-column-30 gdlr-core-column-first"
                            id="gdlr-core-column-2">
                            <div className="gdlr-core-pbf-column-content-margin gdlr-core-js "
                                style={{ "margin": "0px 0px 0px 120px", padding: "80px 0px 0px 0px" }}>
                                <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js "
                                    data-gdlr-animation="fadeInLeft" data-gdlr-animation-duration="600ms"
                                    data-gdlr-animation-offset="0.8">
                                    <div className="gdlr-core-pbf-element">
                                        <div className="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                                            style={{ "paddingBottom": "17px", "marginLeft": "50px" }}><span
                                                className="gdlr-core-title-item-caption gdlr-core-info-font gdlr-core-skin-caption"
                                                style={{ "fontSize": "21px", "fontStyle": "normal", "marginBottom": "2px" }}>Critical
                                                Thinking</span>
                                            <div className="gdlr-core-title-item-title-wrap ">
                                                <h3 className="gdlr-core-title-item-title gdlr-core-skin-title "
                                                    style={{ "fontSize": "34px", "letterSpacing": "0px", "textTransform": "none" }}>
                                                    Concept & Details .</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="gdlr-core-pbf-element">
                                        <div className="gdlr-core-divider-item gdlr-core-divider-item-normal gdlr-core-item-pdlr gdlr-core-center-align"
                                            style={{ "marginBottom": "45px", "marginRight": "-145px" }}>
                                            <div className="gdlr-core-divider-line gdlr-core-skin-divider"
                                                style={{ "borderColor": "#d6c5c2" }}></div>
                                        </div>
                                    </div>
                                    <div className="gdlr-core-pbf-element">
                                        <div className="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align"
                                            style={{ "paddingBottom": "0px" }}>
                                            <div className="gdlr-core-text-box-item-content"
                                                style={{ "textTransform": "none" }}>
                                                <p>A wonderful serenity has taken possession of my entire soul,
                                                    like these sweet mornings of spring which I enjoy with my
                                                    whole heart.</p>
                                                <p><span style={{ "color": "#b6958e" }}>I am alone, and feel the charm
                                                    of existence in this spot</span>, which was created for
                                                    the bliss of souls like mine. I am so happy, my dear friend,
                                                    so absorbed in the exquisite sense of mere tranquil
                                                    existence, that I neglect my talents. I should be incapable
                                                    of drawing a single stroke at the present moment; and yet I
                                                    feel that I never was a greater artist than now. When, while
                                                    the lovely valley.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="gdlr-core-pbf-column gdlr-core-column-30">
                            <div className="gdlr-core-pbf-column-content-margin gdlr-core-js "
                                style={{ "margin": "0px 75px 0px 0px", "padding": "20px 0px 0px 0px" }}>
                                <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js "
                                    data-gdlr-animation="fadeInRight" data-gdlr-animation-duration="600ms"
                                    data-gdlr-animation-offset="0.8">
                                    <div className="gdlr-core-pbf-element">
                                        <div className="gdlr-core-image-item gdlr-core-item-pdlr gdlr-core-item-pdb  gdlr-core-right-align"
                                            style={{ "paddingBottom": "0px" }}>
                                            <div className="gdlr-core-image-item-wrap gdlr-core-media-image  gdlr-core-image-item-style-rectangle"
                                                style={{ "borderWidth": "0px", "maxWidth": "430px" }}><LazyLoadImage effect="blur"
                                                    src="images/hp-concept-details.jpg"
                                                    alt="" width="600" height="800"
                                                    title="jean-philippe-delberghe-660138-unsplash" /></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="gdlr-core-pbf-column gdlr-core-column-30 gdlr-core-column-first"
                            id="gdlr-core-column-3">
                            <div className="gdlr-core-pbf-column-content-margin gdlr-core-js "
                                style={{ "margin": "0px 60px 0px 0px", padding: "0px 0px 0px 0px" }}>
                                <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js "
                                    data-gdlr-animation="fadeInLeft" data-gdlr-animation-duration="600ms"
                                    data-gdlr-animation-offset="0.8">
                                    <div className="gdlr-core-pbf-element">
                                        <div
                                            className="gdlr-core-image-item gdlr-core-item-pdlr gdlr-core-item-pdb  gdlr-core-right-align">
                                            <div className="gdlr-core-image-item-wrap gdlr-core-media-image  gdlr-core-image-item-style-rectangle"
                                                style={{ "borderWidth": "0px", "maxWidth": "430px" }}><LazyLoadImage effect="blur"
                                                    src="images/hp-we-make-perfection.jpg" alt=""
                                                    width="600" height="800"
                                                    title="armin-djuhic-609206-unsplash" /></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="gdlr-core-pbf-column gdlr-core-column-30" id="gdlr-core-column-4">
                            <div className="gdlr-core-pbf-column-content-margin gdlr-core-js "
                                style={{ "margin": "0px 120px 0px 0px", padding: "105px 0px 0px 0px" }}>
                                <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js "
                                    data-gdlr-animation="fadeInRight" data-gdlr-animation-duration="600ms"
                                    data-gdlr-animation-offset="0.8">
                                    <div className="gdlr-core-pbf-element">
                                        <div className="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                                            style={{ "paddingBottom": "17px", "marginLeft": "50px" }}><span
                                                className="gdlr-core-title-item-caption gdlr-core-info-font gdlr-core-skin-caption"
                                                style={{ "fontSize": "21px", "fontStyle": "normal", "marginBottom": "2px" }}>We
                                                Make</span>
                                            <div className="gdlr-core-title-item-title-wrap ">
                                                <h3 className="gdlr-core-title-item-title gdlr-core-skin-title "
                                                    style={{ "fontSize": "34px", "letterSpacing": "0px", "textTransform": "none" }}>
                                                    Perfection .</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="gdlr-core-pbf-element">
                                        <div className="gdlr-core-divider-item gdlr-core-divider-item-normal gdlr-core-item-pdlr gdlr-core-left-align"
                                            style={{ "marginBottom": "45px", "marginLeft": "-105px" }}>
                                            <div className="gdlr-core-divider-container" style={{ "maxWidth": "355px" }}>
                                                <div className="gdlr-core-divider-line gdlr-core-skin-divider"
                                                    style={{ "borderColor": "#d6c5c2" }}></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="gdlr-core-pbf-element">
                                        <div className="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align"
                                            style={{ "paddingBottom": "0px" }}>
                                            <div className="gdlr-core-text-box-item-content"
                                                style={{ "textTransform": "none" }}>
                                                <p>A wonderful serenity has taken possession of my entire soul,
                                                    like these sweet mornings of spring which I enjoy with my
                                                    whole heart.</p>
                                                <p><span style={{ "color": "#b6958e" }}>I am alone, and feel the charm
                                                    of existence in this spot</span>, which was created for
                                                    the bliss of souls like mine. I am so happy, my dear friend,
                                                    so absorbed in the exquisite sense of mere tranquil
                                                    existence, that I neglect my talents. I should be incapable
                                                    of drawing a single stroke at the present moment; and yet I
                                                    feel that I never was a greater artist than now. When, while
                                                    the lovely valley.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="gdlr-core-pbf-wrapper" style={{ "padding": "0px 0px 0px 0px" }} //  gdlr-core-hide-in-mobile
                id="gdlr-core-wrapper-2">
                <div className="gdlr-core-pbf-background-wrap"></div>
                <div className="gdlr-core-pbf-wrapper-content">
                    <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
                        <div className="gdlr-core-pbf-element">
                            <div
                                className="gdlr-core-portfolio-item gdlr-core-item-pdb clearfix  gdlr-core-portfolio-item-style-metro-no-space gdlr-core-item-pdlr">
                                <div className="gdlr-core-block-item-title-wrap  gdlr-core-left-align"
                                    style={{ "marginLeft": "120px", "marginBottom": "30px" }}>
                                    <div className="gdlr-core-block-item-title-inner">
                                        <h3 className="gdlr-core-block-item-title"
                                            style={{ "fontSize": "33px", "textTransform": "none", "letterSpacing": "0px" }}>
                                            Our Projects .</h3><span className="gdlr-core-separator"
                                                style={{ "borderColor": "#968481" }}></span><NavLink
                                                    className="gdlr-core-block-item-read-more" to="/projects/Completed" target="_self"
                                                    style={{ "fontSize": "16px", color: "#968481" }}>View All Projects</NavLink>
                                    </div>
                                </div>


                                {/* <div className="gdlr-core-portfolio-item-holder gdlr-core-js-2 clearfix"
                                    data-layout="masonry"></div> */}
                                <div className="gdlr-core-portfolio-item-holder clearfix">
                                    {/* <div
                                            className="gdlr-core-item-list  gdlr-core-column-20 gdlr-core-column-first">
                                            <div className="gdlr-core-portfolio-metro-badge"></div>
                                            <div className="gdlr-core-portfolio-metro">
                                                <div
                                                    className="gdlr-core-portfolio-thumbnail gdlr-core-media-image  gdlr-core-style-title-icon">
                                                    <div
                                                        className="gdlr-core-portfolio-thumbnail-image-wrap  gdlr-core-zoom-on-hover">
                                                        <LazyLoadImage effect="blur" src="upload/port-1-630x1002.jpg" alt="" width="630"
                                                            height="1002" title="port-1" />
                                                        <span
                                                            className="gdlr-core-image-overlay  gdlr-core-portfolio-overlay gdlr-core-image-overlay-center-icon gdlr-core-js">
                                                            <span className="gdlr-core-image-overlay-content">
                                                                <span
                                                                    className="gdlr-core-portfolio-title gdlr-core-title-font ">
                                                                    <a href="/#">Interior Work AvroKo Project</a>
                                                                </span>
                                                                <span className="gdlr-core-portfolio-icon-wrap">
                                                                    <a className="gdlr-core-lightgallery gdlr-core-js "
                                                                        href="upload/port-1.jpg"
                                                                        data-lightbox-group="gdlr-core-img-group-1">
                                                                        <i
                                                                            className="gdlr-core-portfolio-icon arrow_expand"></i>
                                                                    </a>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                    {/* <div className="gdlr-core-item-list  gdlr-core-column-40">
                                            <div className="gdlr-core-portfolio-metro-badge"></div>
                                            <div className="gdlr-core-portfolio-metro">
                                                <div
                                                    className="gdlr-core-portfolio-thumbnail gdlr-core-media-image  gdlr-core-style-title-icon">
                                                    <div
                                                        className="gdlr-core-portfolio-thumbnail-image-wrap  gdlr-core-zoom-on-hover">
                                                        <LazyLoadImage effect="blur" src="upload/gdtography-310706-unsplash-1260x501.jpg"
                                                            alt="" width="1260" height="501"
                                                            title="gdtography-310706-unsplash" />
                                                        <span
                                                            className="gdlr-core-image-overlay  gdlr-core-portfolio-overlay gdlr-core-image-overlay-center-icon gdlr-core-js">
                                                            <span className="gdlr-core-image-overlay-content">
                                                                <span
                                                                    className="gdlr-core-portfolio-title gdlr-core-title-font ">
                                                                    <a href="/#">The Ark Building Project</a>
                                                                </span>
                                                                <span className="gdlr-core-portfolio-icon-wrap">
                                                                    <a className="gdlr-core-lightgallery gdlr-core-js "
                                                                        href="upload/gdtography-310706-unsplash.jpg"
                                                                        data-lightbox-group="gdlr-core-img-group-1">
                                                                        <i
                                                                            className="gdlr-core-portfolio-icon arrow_expand"></i>
                                                                    </a>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                    {projects && projects.slice(0, 3).map((project, index) =>
                                        <div className="gdlr-core-item-list  gdlr-core-column-20" key={'home-' + project.title + index}>
                                            <div className="gdlr-core-portfolio-metro-badge"></div>
                                            <div className="gdlr-core-portfolio-metro">
                                                <div
                                                    className="gdlr-core-portfolio-thumbnail gdlr-core-media-image  gdlr-core-style-title-icon">
                                                    <div
                                                        className="gdlr-core-portfolio-thumbnail-image-wrap  gdlr-core-zoom-on-hover gdlr-core-item-pdlr">
                                                        <LazyLoadImage effect="blur" src={`${server}/${project.image}`} alt=""
                                                            width="630" height="501"
                                                            title="shutterstock_627788162" style={{ height: "222px",objectFit: "cover" }} />
                                                        <span
                                                            className="gdlr-core-image-overlay  gdlr-core-portfolio-overlay gdlr-core-image-overlay-center-icon">
                                                            <span className="gdlr-core-image-overlay-content">
                                                                <span
                                                                    className="gdlr-core-portfolio-title gdlr-core-title-font ">
                                                                    <NavLink to={'/project/' + project.id}>{project.title}</NavLink>
                                                                </span>
                                                                <span className="gdlr-core-portfolio-icon-wrap">
                                                                    <NavLink className=""
                                                                        to={'/project/' + project.id}
                                                                        data-lightbox-group="gdlr-core-img-group-1">
                                                                        <i
                                                                            className="gdlr-core-portfolio-icon arrow_expand"></i>
                                                                    </NavLink>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}


                                </div>

                                {/* <div className="gdlr-core-load-more-wrap gdlr-core-js gdlr-core-center-align "
                                        data-ajax="gdlr_core_portfolio_ajax"
                                        data-target="gdlr-core-portfolio-item-holder" data-target-action="append"><a
                                            href="/#" className="gdlr-core-load-more gdlr-core-button-color"
                                            data-ajax-name="paged" data-ajax-value="2"
                                            style={{ "marginTop": "65px" }}>Load More Works</a></div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="gdlr-core-pbf-wrapper  gdlr-core-hide-in-desktop-tablet"
                    style={{ "padding": "90px 0px 30px 0px" }} id="gdlr-core-wrapper-3">
                    <div className="gdlr-core-pbf-background-wrap"></div>
                    <div className="gdlr-core-pbf-wrapper-content gdlr-core-js ">
                        <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
                            <div className="gdlr-core-pbf-element">
                                <div
                                    className="gdlr-core-portfolio-item gdlr-core-item-pdb clearfix  gdlr-core-portfolio-item-style-modern-no-space gdlr-core-item-pdlr">
                                    <div className="gdlr-core-block-item-title-wrap  gdlr-core-left-align"
                                        style={{ "marginLeft": "120px", "marginBottom": "67px" }}>
                                        <div className="gdlr-core-block-item-title-inner">
                                            <h3 className="gdlr-core-block-item-title"
                                                style={{ "fontSize": "33px", "textTransform": "none", "letterSpacing": "0px" }}>
                                                Our Works .</h3><span className="gdlr-core-separator"
                                                    style={{ "borderColor": "#968481" }}></span><NavLink
                                                        className="gdlr-core-block-item-read-more" to="/" target="_self"
                                                        style={{ "fontSize": "16px", color: "#968481" }}>View All Works</NavLink>
                                        </div>
                                    </div>

                                    <div className="gdlr-core-portfolio-item-holder gdlr-core-js-2 clearfix"
                                        data-layout="fitrows">
                                        <div
                                            className="gdlr-core-item-list  gdlr-core-column-20 gdlr-core-column-first">
                                            <div className="gdlr-core-portfolio-modern">
                                                <div
                                                    className="gdlr-core-portfolio-thumbnail gdlr-core-media-image  gdlr-core-style-title-icon">
                                                    <div
                                                        className="gdlr-core-portfolio-thumbnail-image-wrap  gdlr-core-zoom-on-hover">
                                                        <LazyLoadImage effect="blur" src="upload/port-1-700x430.jpg" alt="" width="700"
                                                            height="430" title="port-1" />
                                                        <span
                                                            className="gdlr-core-image-overlay  gdlr-core-portfolio-overlay gdlr-core-image-overlay-center-icon gdlr-core-js">
                                                            <span className="gdlr-core-image-overlay-content">
                                                                <span
                                                                    className="gdlr-core-portfolio-title gdlr-core-title-font ">
                                                                    <a href="/#">Interior Work AvroKo Project</a>
                                                                </span>
                                                                <span className="gdlr-core-portfolio-icon-wrap">
                                                                    <a className="gdlr-core-lightgallery gdlr-core-js "
                                                                        href="upload/port-1.jpg"
                                                                        data-lightbox-group="gdlr-core-img-group-2">
                                                                        <i
                                                                            className="gdlr-core-portfolio-icon arrow_expand"></i>
                                                                    </a>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="gdlr-core-item-list  gdlr-core-column-20">
                                            <div className="gdlr-core-portfolio-modern">
                                                <div
                                                    className="gdlr-core-portfolio-thumbnail gdlr-core-media-image  gdlr-core-style-title-icon">
                                                    <div
                                                        className="gdlr-core-portfolio-thumbnail-image-wrap  gdlr-core-zoom-on-hover">
                                                        <LazyLoadImage effect="blur" src="upload/gdtography-310706-unsplash-700x430.jpg"
                                                            alt="" width="700" height="430"
                                                            title="gdtography-310706-unsplash" />
                                                        <span
                                                            className="gdlr-core-image-overlay  gdlr-core-portfolio-overlay gdlr-core-image-overlay-center-icon gdlr-core-js">
                                                            <span className="gdlr-core-image-overlay-content">
                                                                <span
                                                                    className="gdlr-core-portfolio-title gdlr-core-title-font ">
                                                                    <a href="/#">The Ark Building Project</a>
                                                                </span>
                                                                <span className="gdlr-core-portfolio-icon-wrap">
                                                                    <a className="gdlr-core-lightgallery gdlr-core-js "
                                                                        href="upload/gdtography-310706-unsplash.jpg"
                                                                        data-lightbox-group="gdlr-core-img-group-2">
                                                                        <i
                                                                            className="gdlr-core-portfolio-icon arrow_expand"></i>
                                                                    </a>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="gdlr-core-item-list  gdlr-core-column-20">
                                            <div className="gdlr-core-portfolio-modern">
                                                <div
                                                    className="gdlr-core-portfolio-thumbnail gdlr-core-media-image  gdlr-core-style-title-icon">
                                                    <div
                                                        className="gdlr-core-portfolio-thumbnail-image-wrap  gdlr-core-zoom-on-hover">
                                                        <LazyLoadImage effect="blur" src="upload/shutterstock_627788162-700x430.jpg" alt=""
                                                            width="700" height="430"
                                                            title="shutterstock_627788162" />
                                                        <span
                                                            className="gdlr-core-image-overlay  gdlr-core-portfolio-overlay gdlr-core-image-overlay-center-icon gdlr-core-js">
                                                            <span className="gdlr-core-image-overlay-content">
                                                                <span
                                                                    className="gdlr-core-portfolio-title gdlr-core-title-font ">
                                                                    <a href="/#">Diamond Park Exterior Design</a>
                                                                </span>
                                                                <span className="gdlr-core-portfolio-icon-wrap">
                                                                    <a className="gdlr-core-lightgallery gdlr-core-js "
                                                                        href="upload/shutterstock_627788162.jpg"
                                                                        data-lightbox-group="gdlr-core-img-group-2">
                                                                        <i
                                                                            className="gdlr-core-portfolio-icon arrow_expand"></i>
                                                                    </a>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="gdlr-core-item-list  gdlr-core-column-20 gdlr-core-column-first">
                                            <div className="gdlr-core-portfolio-modern">
                                                <div
                                                    className="gdlr-core-portfolio-thumbnail gdlr-core-media-image  gdlr-core-style-title-icon">
                                                    <div
                                                        className="gdlr-core-portfolio-thumbnail-image-wrap  gdlr-core-zoom-on-hover">
                                                        <LazyLoadImage effect="blur" src="upload/hutomo-abrianto-576207-unsplash-700x430.jpg"
                                                            alt="" width="700" height="430"
                                                            title="hutomo-abrianto-576207-unsplash" />
                                                        <span
                                                            className="gdlr-core-image-overlay  gdlr-core-portfolio-overlay gdlr-core-image-overlay-center-icon gdlr-core-js">
                                                            <span className="gdlr-core-image-overlay-content">
                                                                <span
                                                                    className="gdlr-core-portfolio-title gdlr-core-title-font ">
                                                                    <a href="/#">Museum Of Glass Art in London</a>
                                                                </span>
                                                                <span className="gdlr-core-portfolio-icon-wrap">
                                                                    <a className="gdlr-core-lightgallery gdlr-core-js "
                                                                        href="upload/hutomo-abrianto-576207-unsplash.jpg"
                                                                        data-lightbox-group="gdlr-core-img-group-2">
                                                                        <i
                                                                            className="gdlr-core-portfolio-icon arrow_expand"></i>
                                                                    </a>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="gdlr-core-item-list  gdlr-core-column-20">
                                            <div className="gdlr-core-portfolio-modern">
                                                <div
                                                    className="gdlr-core-portfolio-thumbnail gdlr-core-media-image  gdlr-core-style-title-icon">
                                                    <div
                                                        className="gdlr-core-portfolio-thumbnail-image-wrap  gdlr-core-zoom-on-hover">
                                                        <LazyLoadImage effect="blur" src="upload/shutterstock_578977615-700x430.jpg" alt=""
                                                            width="700" height="430"
                                                            title="shutterstock_578977615" />
                                                        <span
                                                            className="gdlr-core-image-overlay  gdlr-core-portfolio-overlay gdlr-core-image-overlay-center-icon gdlr-core-js">
                                                            <span className="gdlr-core-image-overlay-content">
                                                                <span
                                                                    className="gdlr-core-portfolio-title gdlr-core-title-font ">
                                                                    <a href="/#">Minimal &#038; Light Living Area</a>
                                                                </span>
                                                                <span className="gdlr-core-portfolio-icon-wrap">
                                                                    <a className="gdlr-core-lightgallery gdlr-core-js "
                                                                        href="upload/shutterstock_578977615.jpg"
                                                                        data-lightbox-group="gdlr-core-img-group-2">
                                                                        <i
                                                                            className="gdlr-core-portfolio-icon arrow_expand"></i>
                                                                    </a>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="gdlr-core-item-list  gdlr-core-column-20">
                                            <div className="gdlr-core-portfolio-modern">
                                                <div
                                                    className="gdlr-core-portfolio-thumbnail gdlr-core-media-image  gdlr-core-style-title-icon">
                                                    <div
                                                        className="gdlr-core-portfolio-thumbnail-image-wrap  gdlr-core-zoom-on-hover">
                                                        <LazyLoadImage effect="blur" src="upload/shutterstock_631879034-700x430.jpg" alt=""
                                                            width="700" height="430"
                                                            title="shutterstock_631879034" />
                                                        <span
                                                            className="gdlr-core-image-overlay  gdlr-core-portfolio-overlay gdlr-core-image-overlay-center-icon gdlr-core-js">
                                                            <span className="gdlr-core-image-overlay-content">
                                                                <span
                                                                    className="gdlr-core-portfolio-title gdlr-core-title-font ">
                                                                    <a href="/#">Tokyo Soho Hotel</a>
                                                                </span>
                                                                <span className="gdlr-core-portfolio-icon-wrap">
                                                                    <a className="gdlr-core-lightgallery gdlr-core-js "
                                                                        href="upload/shutterstock_631879034.jpg"
                                                                        data-lightbox-group="gdlr-core-img-group-2">
                                                                        <i
                                                                            className="gdlr-core-portfolio-icon arrow_expand"></i>
                                                                    </a>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="gdlr-core-item-list  gdlr-core-column-20 gdlr-core-column-first">
                                            <div className="gdlr-core-portfolio-modern">
                                                <div
                                                    className="gdlr-core-portfolio-thumbnail gdlr-core-media-image  gdlr-core-style-title-icon">
                                                    <div
                                                        className="gdlr-core-portfolio-thumbnail-image-wrap  gdlr-core-zoom-on-hover">
                                                        <LazyLoadImage effect="blur" src="upload/tobias-van-schneider-230813-unsplash-700x430.jpg"
                                                            alt="" width="700" height="430"
                                                            title="tobias-van-schneider-230813-unsplash" />
                                                        <span
                                                            className="gdlr-core-image-overlay  gdlr-core-portfolio-overlay gdlr-core-image-overlay-center-icon gdlr-core-js">
                                                            <span className="gdlr-core-image-overlay-content">
                                                                <span
                                                                    className="gdlr-core-portfolio-title gdlr-core-title-font ">
                                                                    <a href="/#">TomTom Housing Project</a>
                                                                </span>
                                                                <span className="gdlr-core-portfolio-icon-wrap">
                                                                    <a className="gdlr-core-lightgallery gdlr-core-js "
                                                                        href="upload/tobias-van-schneider-230813-unsplash.jpg"
                                                                        data-lightbox-group="gdlr-core-img-group-2">
                                                                        <i
                                                                            className="gdlr-core-portfolio-icon arrow_expand"></i>
                                                                    </a>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="gdlr-core-item-list  gdlr-core-column-20">
                                            <div className="gdlr-core-portfolio-modern">
                                                <div
                                                    className="gdlr-core-portfolio-thumbnail gdlr-core-media-image  gdlr-core-style-title-icon">
                                                    <div
                                                        className="gdlr-core-portfolio-thumbnail-image-wrap  gdlr-core-zoom-on-hover">
                                                        <LazyLoadImage effect="blur" src="upload/davide-cantelli-240809-unsplash-700x430.jpg"
                                                            alt="" width="700" height="430" title="Image Title" />
                                                        <span
                                                            className="gdlr-core-image-overlay  gdlr-core-portfolio-overlay gdlr-core-image-overlay-center-icon gdlr-core-js">
                                                            <span className="gdlr-core-image-overlay-content">
                                                                <span
                                                                    className="gdlr-core-portfolio-title gdlr-core-title-font ">
                                                                    <a href="/#">Zangzak Residential Tower</a>
                                                                </span>
                                                                <span className="gdlr-core-portfolio-icon-wrap">
                                                                    <a className="gdlr-core-lightgallery gdlr-core-js "
                                                                        href="upload/davide-cantelli-240809-unsplash.jpg"
                                                                        data-sub-html="Minimal Interior Design"
                                                                        data-lightbox-group="gdlr-core-img-group-2">
                                                                        <i
                                                                            className="gdlr-core-portfolio-icon arrow_expand"></i>
                                                                    </a>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="gdlr-core-load-more-wrap gdlr-core-js gdlr-core-center-align "
                                        data-ajax="gdlr_core_portfolio_ajax"
                                        data-target="gdlr-core-portfolio-item-holder" data-target-action="append"><a
                                            href="/#" className="gdlr-core-load-more gdlr-core-button-color"
                                            data-ajax-name="paged" data-ajax-value="2"
                                            style={{ "marginTop": "65px" }}>Load More Works</a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            {/* <div className="gdlr-core-pbf-wrapper " style={{ "padding": "180px 0px 90px 0px" }} id="gdlr-core-wrapper-4">
                <div className="gdlr-core-pbf-background-wrap">
                    <div className="gdlr-core-pbf-background gdlr-core-parallax gdlr-core-js"
                        style={{ "backgroundImage": "url(images/hp-testimonial.jpg)", "backgroundSize": "cover", "backgroundPosition": "center" }}
                        data-parallax-speed="0.2"></div>
                </div>
                <div className="gdlr-core-pbf-wrapper-content gdlr-core-js ">
                    <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container-custom"
                        style={{ "maxWidth": "833px" }}>
                        <div className="gdlr-core-pbf-element">
                            <div
                                className="gdlr-core-testimonial-item gdlr-core-item-pdb clearfix  gdlr-core-testimonial-style-center gdlr-core-item-pdlr">
                                <div className="gdlr-core-flexslider flexslider gdlr-core-js-2 gdlr-core-nav-style-middle-large"
                                    data-type="carousel" data-column="1" data-move="1" data-nav="bullet"
                                    data-vcenter-nav="1" data-disable-autoslide="1">
                                    <div className="gdlr-core-block-item-title-wrap  gdlr-core-center-align gdlr-core-item-mglr"
                                        style={{ "marginBottom": "45px" }}>
                                        <div className="gdlr-core-block-item-title-inner">
                                            <h3 className="gdlr-core-block-item-title"
                                                style={{ "fontSize": "33px", "textTransform": "none", "letterSpacing": "0px", color: "#ffffff" }}>
                                                Testimonial .</h3>
                                        </div>
                                        <div
                                            className="gdlr-core-flexslider-nav gdlr-core-round-style gdlr-core-absolute-center gdlr-core-right">
                                        </div>
                                    </div>
                                    <ul className="slides">
                                        <li className="gdlr-core-item-mglr">
                                            <div className="gdlr-core-testimonial clearfix">
                                                <div className="gdlr-core-testimonial-content-wrap">
                                                    <div className="gdlr-core-testimonial-quote gdlr-core-quote-font gdlr-core-skin-icon"
                                                        style={{ "color": "#ffffff" }}>&#8220;</div>
                                                    <div className="gdlr-core-testimonial-content gdlr-core-info-font gdlr-core-skin-content"
                                                        style={{ "fontSize": "20px", color: "#ffffff" }}>
                                                        <p>A wonderful serenity has taken possession of my
                                                            entire soul, like these sweet mornings of spring
                                                            which I enjoy with my whole heart. I am alone, and
                                                            feel the charm of existence in this spot, which was
                                                            created for the bliss of souls like mine. I am so
                                                            happy, my dear friend, so absorbed in the exquisite
                                                            sense of me.</p>
                                                    </div>
                                                    <div className="gdlr-core-testimonial-author-wrap clearfix">
                                                        <div
                                                            className="gdlr-core-testimonial-author-image gdlr-core-media-image">
                                                            <LazyLoadImage effect="blur" src="https://a6e8z9v6.stackpathcdn.com/inteco/wp-content/uploads/2016/05/personnel-2-2-150x150.jpg" alt=""
                                                                width="150" height="150"
                                                                title="personnel-2-2" /></div>
                                                        <div className="gdlr-core-testimonial-author-content">
                                                            <div className="gdlr-core-testimonial-title gdlr-core-title-font gdlr-core-skin-title"
                                                                style={{ "color": "#ffffff" }}>Jane Smith</div>
                                                            <div className="gdlr-core-testimonial-position gdlr-core-info-font gdlr-core-skin-caption"
                                                                style={{ "color": "#ffffff" }}>CEO, InDesign</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* <div className="gdlr-core-pbf-wrapper " style={{ "padding": "95px 0px 50px 0px" }}>
                <div className="gdlr-core-pbf-background-wrap"></div>
                <div className="gdlr-core-pbf-wrapper-content gdlr-core-js " data-gdlr-animation="fadeInUp"
                    data-gdlr-animation-duration="600ms" data-gdlr-animation-offset="0.8">
                    <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
                        <div className="gdlr-core-pbf-column gdlr-core-column-15 gdlr-core-column-first">
                            <div className="gdlr-core-pbf-column-content-margin gdlr-core-js ">
                                <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                                    <div className="gdlr-core-pbf-element">
                                        <div
                                            className="gdlr-core-counter-item gdlr-core-item-pdlr gdlr-core-item-pdb ">
                                            <div className="gdlr-core-counter-item-number gdlr-core-skin-title gdlr-core-title-font"
                                                style={{ "fontSize": "36px" }}><span
                                                    className="gdlr-core-counter-item-count gdlr-core-js"
                                                    data-duration="4000" data-counter-start="0"
                                                    data-counter-end="345">0</span></div>
                                            <div className="gdlr-core-counter-item-bottom-text gdlr-core-skin-content"
                                                style={{ "fontSize": "18px" }}>Happy Clients</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="gdlr-core-pbf-column gdlr-core-column-15">
                            <div className="gdlr-core-pbf-column-content-margin gdlr-core-js ">
                                <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                                    <div className="gdlr-core-pbf-element">
                                        <div
                                            className="gdlr-core-counter-item gdlr-core-item-pdlr gdlr-core-item-pdb ">
                                            <div className="gdlr-core-counter-item-number gdlr-core-skin-title gdlr-core-title-font"
                                                style={{ "fontSize": "36px" }}><span
                                                    className="gdlr-core-counter-item-count gdlr-core-js"
                                                    data-duration="4000" data-counter-start="0"
                                                    data-counter-end="934">0</span></div>
                                            <div className="gdlr-core-counter-item-bottom-text gdlr-core-skin-content"
                                                style={{ "fontSize": "18px" }}>Amazing works</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="gdlr-core-pbf-column gdlr-core-column-15">
                            <div className="gdlr-core-pbf-column-content-margin gdlr-core-js ">
                                <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                                    <div className="gdlr-core-pbf-element">
                                        <div
                                            className="gdlr-core-counter-item gdlr-core-item-pdlr gdlr-core-item-pdb ">
                                            <div className="gdlr-core-counter-item-number gdlr-core-skin-title gdlr-core-title-font"
                                                style={{ "fontSize": "36px" }}><span
                                                    className="gdlr-core-counter-item-count gdlr-core-js"
                                                    data-duration="4000" data-counter-start="0"
                                                    data-counter-end="20">0</span><span
                                                        className="gdlr-core-counter-item-suffix">+</span></div>
                                            <div className="gdlr-core-counter-item-bottom-text gdlr-core-skin-content"
                                                style={{ "fontSize": "18px " }}>Awards winning</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="gdlr-core-pbf-column gdlr-core-column-15">
                            <div className="gdlr-core-pbf-column-content-margin gdlr-core-js ">
                                <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                                    <div className="gdlr-core-pbf-element">
                                        <div
                                            className="gdlr-core-counter-item gdlr-core-item-pdlr gdlr-core-item-pdb ">
                                            <div className="gdlr-core-counter-item-number gdlr-core-skin-title gdlr-core-title-font"
                                                style={{ "fontSize": "36px" }}><span
                                                    className="gdlr-core-counter-item-count gdlr-core-js"
                                                    data-duration="4000" data-counter-start="0"
                                                    data-counter-end="16">0</span></div>
                                            <div className="gdlr-core-counter-item-bottom-text gdlr-core-skin-content"
                                                style={{ "fontSize": "18px" }}>operated Years</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* <div className="gdlr-core-pbf-wrapper " style={{ "padding": "455px 0px 30px 0px" }} id="gdlr-core-wrapper-5">
                <div className="gdlr-core-pbf-background-wrap">
                    <div className="gdlr-core-pbf-background gdlr-core-parallax gdlr-core-js"
                        style={{ "backgroundImage": "url(upload/hp1-service-bg.jpg)", "backgroundSize": "cover", "backgroundPosition": "center" }}
                        data-parallax-speed="0.3"></div>
                </div>
                <div className="gdlr-core-pbf-wrapper-content gdlr-core-js ">
                    <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container"></div>
                </div>
            </div> */}
            <div className="gdlr-core-pbf-wrapper " style={{ "margin": "-76px 0px 0px 0px", padding: "0px 0px 20px 0px" }}>
                <div className="gdlr-core-pbf-background-wrap"></div>
                <div className="gdlr-core-pbf-wrapper-content">
                    <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container-custom"
                        style={{ "maxWidth": "1280px" }}>
                        <div className="gdlr-core-pbf-column gdlr-core-column-20 gdlr-core-column-first"
                            id="gdlr-core-column-5">
                            <div className="gdlr-core-pbf-column-content-margin"
                                style={{ "margin": "0px 33px 0px 0px", padding: "35px 30px 27px 30px" }}>
                                <div className="gdlr-core-pbf-background-wrap"
                                    style={{ "boxShadow": "none", "MozBoxShadow": "none", "WebkitBoxShadow": "none", "backgroundColor": "initial" }}>
                                </div>
                                <div
                                    className="gdlr-core-pbf-column-content clearfix gdlr-core-sync-height-content">
                                    <div className="gdlr-core-pbf-element">
                                        <div className="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                                            style={{ "paddingBottom": "3px" }}>
                                            <div className="gdlr-core-title-item-title-wrap ">
                                                <h3 className="gdlr-core-title-item-title gdlr-core-skin-title "
                                                    style={{ "fontSize": "19px", "letterSpacing": "0px", "textTransform": "none", color: "#2b2b2b" }}>
                                                    Perfect Design </h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="gdlr-core-pbf-element">
                                        <div className="gdlr-core-divider-item gdlr-core-divider-item-normal gdlr-core-item-pdlr gdlr-core-left-align"
                                            style={{ "marginBottom": "22px" }}>
                                            <div className="gdlr-core-divider-container" style={{ "maxWidth": "140px" }}>
                                                <div className="gdlr-core-divider-line gdlr-core-skin-divider"
                                                    style={{ "borderColor": "#968481" }}></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="gdlr-core-pbf-element">
                                        <div className="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align"
                                            style={{ "paddingBottom": "0px" }}>
                                            <div className="gdlr-core-text-box-item-content"
                                                style={{ "fontSize": "18px", "fontWeight": 400, "textTransform": "none" }}>
                                                {setting && <p>{setting.perfectDesign}</p>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="gdlr-core-pbf-element">
                                        <div
                                            className="gdlr-core-button-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align" style={{paddingBottom:"0px"}}>
                                            <a className="gdlr-core-button  gdlr-core-button-transparent gdlr-core-button-no-border"
                                                href="/#"
                                                style={{ "fontSize": "16px", "fontWeight": 800, "letterSpacing": "0px", color: "#1c1c1c", padding: "0px 0px 0px 0px", "textTransform": "none", "borderRadius": "0px", "MozBorderRadius": "0px", "WebkitBorderRadius": "0px" }}><span
                                                    className="gdlr-core-content">More</span><i
                                                        className="gdlr-core-pos-right fa fa-long-arrow-right"
                                                        style={{ "color": "#262626" }}></i></a></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="gdlr-core-pbf-column gdlr-core-column-20" id="gdlr-core-column-6">
                            <div className="gdlr-core-pbf-column-content-margin"
                                style={{ "margin": "0px 16px 0px 16px", padding: "35px 30px 20px 30px" }}>
                                <div className="gdlr-core-pbf-background-wrap"
                                    style={{ "boxShadow": "none", "MozBoxShadow": "none", "WebkitBoxShadow": "none", "backgroundColor": "initial" }}>
                                </div>
                                <div
                                    className="gdlr-core-pbf-column-content clearfix gdlr-core-sync-height-content">
                                    <div className="gdlr-core-pbf-element">
                                        <div className="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                                            style={{ "paddingBottom": "3px" }}>
                                            <div className="gdlr-core-title-item-title-wrap ">
                                                <h3 className="gdlr-core-title-item-title gdlr-core-skin-title "
                                                    style={{ "fontSize": "19px", "letterSpacing": "0px", "textTransform": "none", color: "#2b2b2b" }}>
                                                    Carefully Planned</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="gdlr-core-pbf-element">
                                        <div className="gdlr-core-divider-item gdlr-core-divider-item-normal gdlr-core-item-pdlr gdlr-core-left-align"
                                            style={{ "marginBottom": "22px" }}>
                                            <div className="gdlr-core-divider-container" style={{ "maxWidth": "175px" }}>
                                                <div className="gdlr-core-divider-line gdlr-core-skin-divider"
                                                    style={{ "borderColor": "#968481" }}></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="gdlr-core-pbf-element">
                                        <div className="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align"
                                            style={{ "paddingBottom": "0px" }}>
                                            <div className="gdlr-core-text-box-item-content"
                                                style={{ "fontSize": "18px", "fontWeight": 400, "textTransform": "none" }}>
                                                <p>{setting && setting.carefullyPlanned}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="gdlr-core-pbf-element">
                                        <div
                                            className="gdlr-core-button-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align" style={{paddingBottom:"0px"}}>
                                            <a className="gdlr-core-button  gdlr-core-button-transparent gdlr-core-button-no-border"
                                                href="/#"
                                                style={{ "fontSize": "16px", "fontWeight": 800, "letterSpacing": "0px", color: "#1c1c1c", padding: "0px 0px 0px 0px", "textTransform": "none", "borderRadius": "0px", "MozBorderRadius": "0px", "WebkitBorderRadius": "0px" }}><span
                                                    className="gdlr-core-content">More</span><i
                                                        className="gdlr-core-pos-right fa fa-long-arrow-right"
                                                        style={{ "color": "#262626" }}></i></a></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="gdlr-core-pbf-column gdlr-core-column-20" id="gdlr-core-column-7">
                            <div className="gdlr-core-pbf-column-content-margin"
                                style={{ "margin": "0px 0px 0px 20px", padding: "35px 30px 20px 30px" }}>
                                <div className="gdlr-core-pbf-background-wrap"
                                    style={{ "boxShadow": "none", "MozBoxShadow": "none", "WebkitBoxShadow": "none", "backgroundColor": "initial" }}>
                                </div>
                                <div
                                    className="gdlr-core-pbf-column-content clearfix gdlr-core-sync-height-content">
                                    <div className="gdlr-core-pbf-element">
                                        <div className="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                                            style={{ "paddingBottom": "3px" }}>
                                            <div className="gdlr-core-title-item-title-wrap ">
                                                <h3 className="gdlr-core-title-item-title gdlr-core-skin-title "
                                                    style={{ "fontSize": "19px", "letterSpacing": "0px", "textTransform": "none", color: "#2b2b2b" }}>
                                                    Smartly Execute</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="gdlr-core-pbf-element">
                                        <div className="gdlr-core-divider-item gdlr-core-divider-item-normal gdlr-core-item-pdlr gdlr-core-left-align"
                                            style={{ "marginBottom": "22px" }}>
                                            <div className="gdlr-core-divider-container" style={{ "maxWidth": "155px" }}>
                                                <div className="gdlr-core-divider-line gdlr-core-skin-divider"
                                                    style={{ "borderColor": "#968481" }}></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="gdlr-core-pbf-element">
                                        <div className="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align"
                                            style={{ "paddingBottom": "0px" }}>
                                            <div className="gdlr-core-text-box-item-content"
                                                style={{ "fontSize": "18px", "fontWeight": 400, "textTransform": "none" }}>
                                                <p>{setting && setting.smartlyExecute}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="gdlr-core-pbf-element">
                                        <div
                                            className="gdlr-core-button-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align" style={{paddingBottom:"0px"}}>
                                            <a className="gdlr-core-button  gdlr-core-button-transparent gdlr-core-button-no-border"
                                                href="/#"
                                                style={{ "fontSize": "16px", "fontWeight": 800, "letterSpacing": "0px", color: "#1c1c1c", padding: "0px 0px 0px 0px", "textTransform": "none", "borderRadius": "0px", "MozBorderRadius": "0px", "WebkitBorderRadius": "0px" }}><span
                                                    className="gdlr-core-content">More</span><i
                                                        className="gdlr-core-pos-right fa fa-long-arrow-right"
                                                        style={{ "color": "#262626" }}></i></a></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Super Team Start */}
            {/* <div className="gdlr-core-pbf-wrapper " style={{ "padding": "60px 0px 0px 0px" }}>
                    <div className="gdlr-core-pbf-background-wrap"></div>
                    <div className="gdlr-core-pbf-wrapper-content gdlr-core-js ">
                        <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
                            <div className="gdlr-core-pbf-column gdlr-core-column-30 gdlr-core-column-first">
                                <div className="gdlr-core-pbf-column-content-margin gdlr-core-js ">
                                    <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                                        <div className="gdlr-core-pbf-element">
                                            <div className="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                                                style={{ "paddingBottom": "3px" }}>
                                                <div
                                                    className="gdlr-core-title-item-title-wrap  gdlr-core-with-divider">
                                                    <h3 className="gdlr-core-title-item-title gdlr-core-skin-title "
                                                        style={{ "fontSize": "34px", "letterSpacing": "0px", "textTransform": "none", color: "#2b2b2b" }}>
                                                        Super Team .</h3>
                                                    <div className="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                                                        style={{ "borderColor": "#9a827d" }}></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="gdlr-core-pbf-column gdlr-core-column-30">
                                <div className="gdlr-core-pbf-column-content-margin gdlr-core-js ">
                                    <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                                        <div className="gdlr-core-pbf-element">
                                            <div
                                                className="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                                                <div className="gdlr-core-text-box-item-content"
                                                    style={{ "textTransform": "none", color: "#828282" }}>
                                                    <p>A wonderful serenity has taken possession of my entire soul,
                                                        like these sweet mornings of spring which I enjoy with my
                                                            whole heart. <span style={{ "color": "#9a827d" }}>I am alone, and
                                                                feel the charm of existence in this spot</span>, which
was created for the bliss of souls like mine. I am so happy,
my dear friend, so absorbed in the exquisite sense of mere
tranquil existence, that I neglect my talents. I should be
incapable of drawing a single stroke at the present moment;
                                                            and yet I feel that I never was a greater.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="gdlr-core-pbf-wrapper " style={{ "padding": "20px 0px 0px 0px" }}>
                    <div className="gdlr-core-pbf-background-wrap"></div>
                    <div className="gdlr-core-pbf-wrapper-content gdlr-core-js ">
                        <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
                            <div className="gdlr-core-pbf-element">
                                <div
                                    className="gdlr-core-personnel-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-personnel-item-style-grid gdlr-core-personnel-style-grid gdlr-core-with-divider ">
                                    <div
                                        className="gdlr-core-personnel-list-column  gdlr-core-column-15 gdlr-core-column-first gdlr-core-item-pdlr">
                                        <div className="gdlr-core-personnel-list clearfix">
                                            <div
                                                className="gdlr-core-personnel-list-image gdlr-core-media-image  gdlr-core-opacity-on-hover gdlr-core-zoom-on-hover">
                                                <a href="/#"><LazyLoadImage effect="blur" src="upload/personnel-1-1.jpg" alt="" width="600"
                                                    height="718" title="personnel-1-1" /></a>
                                            </div>
                                            <div className="gdlr-core-personnel-list-content-wrap">
                                                <h3 className="gdlr-core-personnel-list-title"
                                                    style={{ "fontSize": "20px", "letterSpacing": "0px", "textTransform": "none" }}>
                                                    <a href="/#">John Balmer</a></h3>
                                                <div className="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                                                    style={{ "fontSize": "15px", "fontStyle": "normal" }}>CEO & Founder
                                                    </div>
                                                <div
                                                    className="gdlr-core-personnel-list-divider gdlr-core-skin-divider">
                                                </div>
                                                <div className="gdlr-core-personnel-list-social">
                                                    <div className="gdlr-core-social-network-item gdlr-core-item-pdb  gdlr-core-none-align"
                                                        style={{ "paddingBottom": "0px" }}><a href="/#" target="_blank"
                                                            className="gdlr-core-social-network-icon"
                                                            title="facebook"><i className="fa fa-facebook"></i></a><a
                                                                href="/#" target="_blank"
                                                                className="gdlr-core-social-network-icon"
                                                                title="linkedin"><i className="fa fa-linkedin"></i></a><a
                                                                    href="/#" target="_blank"
                                                                    className="gdlr-core-social-network-icon"
                                                                    title="pinterest"><i
                                                                        className="fa fa-pinterest-p"></i></a><a
                                                                            href="/#" target="_blank"
                                                                            className="gdlr-core-social-network-icon" title="twitter"><i
                                                                                className="fa fa-twitter"></i></a></div>
                                                </div> 
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="gdlr-core-personnel-list-column  gdlr-core-column-15 gdlr-core-item-pdlr">
                                        <div className="gdlr-core-personnel-list clearfix">
                                            <div
                                                className="gdlr-core-personnel-list-image gdlr-core-media-image  gdlr-core-opacity-on-hover gdlr-core-zoom-on-hover">
                                                <a href="/#"><LazyLoadImage effect="blur" src="upload/personnel-2-1.jpg" alt="" width="600"
                                                    height="718" title="personnel-2-1" /></a>
                                            </div>
                                            <div className="gdlr-core-personnel-list-content-wrap">
                                                <h3 className="gdlr-core-personnel-list-title"
                                                    style={{ "fontSize": "20px", "letterSpacing": "0px", "textTransform": "none" }}>
                                                    <a href="/#">Marry Scott</a></h3>
                                                <div className="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                                                    style={{ "fontSize": "15px", "fontStyle": "normal" }}>Planning Manager
                                                    </div>
                                                <div
                                                    className="gdlr-core-personnel-list-divider gdlr-core-skin-divider">
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="gdlr-core-personnel-list-column  gdlr-core-column-15 gdlr-core-item-pdlr">
                                        <div className="gdlr-core-personnel-list clearfix">
                                            <div
                                                className="gdlr-core-personnel-list-image gdlr-core-media-image  gdlr-core-opacity-on-hover gdlr-core-zoom-on-hover">
                                                <a href="/#"><LazyLoadImage effect="blur" src="upload/personnel-3-1.jpg" alt="" width="600"
                                                    height="718" title="personnel-3-1" /></a>
                                            </div>
                                            <div className="gdlr-core-personnel-list-content-wrap">
                                                <h3 className="gdlr-core-personnel-list-title"
                                                    style={{ "fontSize": "20px", "letterSpacing": "0px", "textTransform": "none" }}>
                                                    <a href="/#">Jennette Jones</a></h3>
                                                <div className="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                                                    style={{ "fontSize": "15px", "fontStyle": "normal" }}>Senior Interior
                                                    </div>
                                                <div
                                                    className="gdlr-core-personnel-list-divider gdlr-core-skin-divider">
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="gdlr-core-personnel-list-column  gdlr-core-column-15 gdlr-core-item-pdlr">
                                        <div className="gdlr-core-personnel-list clearfix">
                                            <div
                                                className="gdlr-core-personnel-list-image gdlr-core-media-image  gdlr-core-opacity-on-hover gdlr-core-zoom-on-hover">
                                                <a href="/#"><LazyLoadImage effect="blur" src="upload/personnel-4-1.jpg" alt="" width="600"
                                                    height="718" title="personnel-4-1" /></a>
                                            </div>
                                            <div className="gdlr-core-personnel-list-content-wrap">
                                                <h3 className="gdlr-core-personnel-list-title"
                                                    style={{ "fontSize": "20px", "letterSpacing": "0px", "textTransform": "none" }}>
                                                    <a href="/#">Joseph Smith</a></h3>
                                                <div className="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                                                    style={{ "fontSize": "15px", "fontStyle": "normal" }}>Project Manager
                                                    </div>
                                                <div
                                                    className="gdlr-core-personnel-list-divider gdlr-core-skin-divider">
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            {/* Super Team End */}
            {/* <div className="gdlr-core-pbf-wrapper "
                    style={{ "margin": "50px auto 0px auto", "padding": "100px 0px 30px 0px", "maxWidth": "1320px" }}>
                    <div className="gdlr-core-pbf-background-wrap">
                        <div className="gdlr-core-pbf-background gdlr-core-parallax gdlr-core-js"
                            style={{ "backgroundSize": "cover", "backgroundPosition": "center" }} data-parallax-speed="0.2">
                        </div>
                    </div>
                    <div className="gdlr-core-wrapper-bg-overlay gdlr-core-pos-right "
                        style={{ "backgroundImage": "url(images/hp-our-expertise.jpg)", "marginRight": "-600px", "marginBottom": "110px", "marginLeft": "-540px" }}>
                    </div>
                    <div className="gdlr-core-pbf-wrapper-content gdlr-core-js ">
                        <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
                            <div className="gdlr-core-pbf-column gdlr-core-column-30 gdlr-core-column-first">
                                <div className="gdlr-core-pbf-column-content-margin gdlr-core-js "
                                    style={{ "padding": "85px 45px 60px 45px" }}>
                                    <div className="gdlr-core-pbf-background-wrap"
                                        style={{ "boxShadow": "0 0 45px rgba(10, 10, 10,0.08)", "MozBoxShadow": "0 0 45px rgba(10, 10, 10,0.08)", "WebkitBoxShadow": "0 0 45px rgba(10, 10, 10,0.08)", "backgroundColor": "#ffffff" }}>
                                    </div>
                                    <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js "
                                        data-gdlr-animation="fadeInLeft" data-gdlr-animation-duration="600ms"
                                        data-gdlr-animation-offset="0.8">
                                        <div className="gdlr-core-pbf-element">
                                            <div
                                                className="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr">
                                                <div className="gdlr-core-title-item-title-wrap ">
                                                    <h3 className="gdlr-core-title-item-title gdlr-core-skin-title "
                                                        style={{ "fontSize": "30px", "letterSpacing": "0px", "textTransform": "none", color: "#2b2b2b" }}>
                                                        Our Expertise .</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="gdlr-core-pbf-element">
                                            <div
                                                className="gdlr-core-skill-bar-item gdlr-core-item-pdlr gdlr-core-item-pdb  gdlr-core-size-small gdlr-core-type-round">
                                                <div className="gdlr-core-skill-bar">
                                                    <div className="gdlr-core-skill-bar-head gdlr-core-title-font"><span
                                                        className="gdlr-core-skill-bar-title">Interior
                                                                Design</span><span
                                                            className="gdlr-core-skill-bar-right">85%</span></div>
                                                    <div className="gdlr-core-skill-bar-progress">
                                                        <div className="gdlr-core-skill-bar-filled gdlr-core-js"
                                                            data-width="85"></div>
                                                    </div>
                                                </div>
                                                <div className="gdlr-core-skill-bar">
                                                    <div className="gdlr-core-skill-bar-head gdlr-core-title-font"><span
                                                        className="gdlr-core-skill-bar-title">AutoCAD</span><span
                                                            className="gdlr-core-skill-bar-right">75%</span></div>
                                                    <div className="gdlr-core-skill-bar-progress">
                                                        <div className="gdlr-core-skill-bar-filled gdlr-core-js"
                                                            data-width="75"></div>
                                                    </div>
                                                </div>
                                                <div className="gdlr-core-skill-bar">
                                                    <div className="gdlr-core-skill-bar-head gdlr-core-title-font"><span
                                                        className="gdlr-core-skill-bar-title">Lighting</span><span
                                                            className="gdlr-core-skill-bar-right">95%</span></div>
                                                    <div className="gdlr-core-skill-bar-progress">
                                                        <div className="gdlr-core-skill-bar-filled gdlr-core-js"
                                                            data-width="95"></div>
                                                    </div>
                                                </div>
                                                <div className="gdlr-core-skill-bar">
                                                    <div className="gdlr-core-skill-bar-head gdlr-core-title-font"><span
                                                        className="gdlr-core-skill-bar-title">3D Skills</span><span
                                                            className="gdlr-core-skill-bar-right">85%</span></div>
                                                    <div className="gdlr-core-skill-bar-progress">
                                                        <div className="gdlr-core-skill-bar-filled gdlr-core-js"
                                                            data-width="85"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            {/* <div className="gdlr-core-pbf-wrapper "
                style={{ "margin": "80px auto 90px auto", padding: "100px 0px 30px 0px", "maxWidth": "1320px" }}
                id="gdlr-core-wrapper-6">
                <div className="gdlr-core-pbf-background-wrap"></div>
                <div className="gdlr-core-wrapper-bg-overlay gdlr-core-pos-left "
                    style={{ "backgroundImage": "url(images/hp-our-legacy.jpg)", "marginRight": "-540px", "marginBottom": "110px", "marginLeft": "-600px" }}>
                </div>
                <div className="gdlr-core-pbf-wrapper-content gdlr-core-js ">
                    <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
                        <div className="gdlr-core-pbf-column gdlr-core-column-30 gdlr-core-column-first">
                            <div className="gdlr-core-pbf-column-content-margin gdlr-core-js ">
                                <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js "></div>
                            </div>
                        </div>
                        <div className="gdlr-core-pbf-column gdlr-core-column-30">
                            <div className="gdlr-core-pbf-column-content-margin gdlr-core-js "
                                style={{ "padding": "90px 45px 40px 50px" }}>
                                <div className="gdlr-core-pbf-background-wrap"
                                    style={{ "boxShadow": "0 0 45px rgba(10, 10, 10,0.08)", "MozBoxShadow": "0 0 45px rgba(10, 10, 10,0.08)", "WebkitBoxShadow": "0 0 45px rgba(10, 10, 10,0.08)", "backgroundColor": "#ffffff" }}>
                                </div>
                                <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js "
                                    data-gdlr-animation="fadeInRight" data-gdlr-animation-duration="600ms"
                                    data-gdlr-animation-offset="0.8">
                                    <div className="gdlr-core-pbf-element">
                                        <div
                                            className="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr">
                                            <div className="gdlr-core-title-item-title-wrap ">
                                                <h3 className="gdlr-core-title-item-title gdlr-core-skin-title "
                                                    style={{ "fontSize": "30px", "letterSpacing": "0px", "textTransform": "none", color: "#2b2b2b" }}>
                                                    Our Legacy .</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="gdlr-core-pbf-element">
                                        <div
                                            className="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                                            <div className="gdlr-core-text-box-item-content"
                                                style={{ "textTransform": "none" }}>
                                                <p>A wonderful serenity has taken possession of my entire soul,
                                                    like these sweet mornings of spring which I enjoy with my
                                                    whole heart. <span style={{ "color": "#9a827d" }}>I am alone, and
                                                        feel the charm of existence in this spot</span>, which
                                                    was created for the bliss of souls like mine. I am so happy,
                                                    my dear friend, so absorbed in the exquisite sense of mere
                                                    tranquil existence, that I neglect my talents. I should be
                                                    incapable of drawing a single. </p>
                                                <p>I throw myself down among the tall grass by the trickling
                                                    stream; and, as I lie close to the earth, a thousand unknown
                                                    plants are noticed by me: when I hear the buzz of the little
                                                    world among</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="gdlr-core-pbf-wrapper " style={{ "padding": "20px 0px 0px 0px" }} data-skin="Brown Divider">
                <div className="gdlr-core-pbf-background-wrap">
                    {/* <div className="gdlr-core-pbf-background gdlr-core-parallax gdlr-core-js"
                        style={{ "backgroundImage": "url(upload/hp2-bg-1.jpg)", "backgroundSize": "cover", "backgroundPosition": "center" }}
                        data-parallax-speed="0.2"></div> */}
                </div>
                <div className="gdlr-core-pbf-wrapper-content gdlr-core-js ">
                    <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
                        <div className="gdlr-core-pbf-element">
                            <div
                                className="gdlr-core-blog-item gdlr-core-item-pdb clearfix  gdlr-core-style-blog-column" style={{paddingBottom:"0px"}}>
                                <div className="gdlr-core-block-item-title-wrap  gdlr-core-left-align gdlr-core-item-mglr"
                                    style={{ "marginBottom": "25px" }}>
                                    <div className="gdlr-core-block-item-title-inner">
                                        <h3 className="gdlr-core-block-item-title"
                                            style={{ "fontSize": "29px", "fontStyle": "normal", "textTransform": "none", "letterSpacing": "0px", color: "#111111" }}>
                                            Blogs</h3><span className="gdlr-core-separator"
                                                style={{ "borderColor": "#968481" }}></span><NavLink
                                                    className="gdlr-core-block-item-read-more" to="/blog" target="_self"
                                                    style={{ "fontSize": "16px", color: "#968481" }}>View All Blogs</NavLink>
                                    </div>
                                </div>
                                <div className="gdlr-core-blog-item-holder gdlr-core-js-2 clearfix"
                                    data-layout="fitrows">

                                    {blogs && blogs.slice(0, 3).map((blog, index) =>
                                        <div className="gdlr-core-item-list  gdlr-core-item-pdlr gdlr-core-column-20" key={'home-' + blog.title + index}>
                                            <div className="gdlr-core-blog-grid  gdlr-core-js gdlr-core-with-divider"
                                                style={{ "paddingBottom": "20px", marginBottom:"0px" }}>
                                                <div
                                                    className="gdlr-core-blog-thumbnail gdlr-core-media-image  gdlr-core-opacity-on-hover gdlr-core-zoom-on-hover gdlr-core-grayscale-effect">
                                                    <NavLink to={"/blog/" + blog.id}><LazyLoadImage effect="blur"
                                                        src={`${server}/${blog.image}`}
                                                        alt="" width="700" height="430"
                                                        title="oleg-laptev-546619-unsplash" style={{ filter: 'none' }} /></NavLink>
                                                </div>
                                                <div className="gdlr-core-blog-grid-content-wrap">
                                                    <div className="gdlr-core-blog-date-wrapper gdlr-core-skin-divider" style={{"lineHeight":"1.2" }}>
                                                        <div className="gdlr-core-blog-date-day gdlr-core-skin-caption">
                                                            {new Date(blog.createdAt).getDate()}</div>
                                                        <div
                                                            className="gdlr-core-blog-date-month gdlr-core-skin-caption">
                                                            {new Date(blog.createdAt).toLocaleString('default', { month: 'short' })}</div>
                                                    </div>
                                                    <div className="gdlr-core-blog-grid-title-wrap">
                                                        <h3 className="gdlr-core-blog-title gdlr-core-skin-title"
                                                            style={{ "fontSize": "19px","lineHeight":"1.6" }}><NavLink to={"/blog/" + blog.id}>{blog.title}</NavLink></h3>
                                                        <div
                                                            className="gdlr-core-blog-info-wrapper gdlr-core-skin-divider">
                                                            <span
                                                                className="gdlr-core-blog-info gdlr-core-blog-info-font gdlr-core-skin-caption gdlr-core-blog-info-author"><span
                                                                    className="gdlr-core-head">By</span><a href="/#"
                                                                        title="Posts by John Smith" rel="author">ADMIN</a></span><span
                                                                        className="gdlr-core-blog-info gdlr-core-blog-info-font gdlr-core-skin-caption gdlr-core-blog-info-category"><span
                                                                            className="gdlr-core-head">In</span><a href="/#"
                                                                                rel="tag">BLOGS</a></span>
                                                        </div>
                                                    </div>
                                                    <div className="gdlr-core-blog-content">
                                                        <div dangerouslySetInnerHTML={{ __html: blog.short_description.slice(0, 150) }} />
                                                        <div className="clear"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div style={{minHeight:"200px"}}> */}
                <HomeClients />
            {/* </div> */}
            <div className="gdlr-core-pbf-wrapper " style={{ "padding": "30px 0px 30px 0px" }}>
                <div className="gdlr-core-pbf-background-wrap">
                    {/* <div className="gdlr-core-pbf-background gdlr-core-parallax gdlr-core-js"
                            style={{ "backgroundImage": "url(upload/contact-lamp.jpg)", "backgroundRepeat": "no-repeat", "backgroundPosition": "center" }}
                            data-parallax-speed="0"></div> */}
                </div>
                {setting && <div className="gdlr-core-pbf-wrapper-content gdlr-core-js ">
                    <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
                        <div className="gdlr-core-pbf-column gdlr-core-column-30 gdlr-core-column-first">
                            <div className="gdlr-core-pbf-column-content-margin gdlr-core-js ">
                                <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                                    <div className="gdlr-core-wp-google-map-plugin-item gdlr-core-item-pdlr gdlr-core-item-pdb " style={{ "paddingBottom": "0px" }}>
                                        <div style={{ "overflow": "hidden", width: "100%", position: "relative" }}>

                                            {/* <iframe style={{ "width": "100%", height: "480px", border: 0 }} title="home-map" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1825.5028311705346!2d90.3959903!3d23.7828127!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755c76ba377edd1%3A0x3cfb391a0d797509!2s127%20Lane%20No%205%2C%20Dhaka%201206!5e0!3m2!1sen!2sbd!4v1633859704789!5m2!1sen!2sbd" width="600" height="450" allowFullScreen="" loading="lazy"></iframe> */}
                                            <iframe style={{ "width": "100%", height: "480px", border: 0 }} title="home-map" src={setting.mapSrc} width="600" height="450" allowFullScreen="" loading="lazy"></iframe>
                                            <div style={{ "position": "absolute", width: "80%", bottom: "20px", left: 0, right: 0, "marginLeft": "auto", "marginRight": "auto", color: "#000" }}>

                                            </div>
                                            {/* <style>#gmap_canvas img{maxWidth:none!important;background:none!important}</style> */}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="gdlr-core-pbf-column gdlr-core-column-30" style={{ display: "flex" }}>
                            <div className="gdlr-core-pbf-column-content-margin gdlr-core-js gdlr-core-column-40" style={{ margin: "0 auto" }}>
                                <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                                    <div className="gdlr-core-pbf-element">
                                        <div className="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                                            style={{ "paddingBottom": "20px" }}>
                                            <div className="gdlr-core-title-item-title-wrap ">
                                                <h3 className="gdlr-core-title-item-title gdlr-core-skin-title "
                                                    style={{ "fontSize": "29px", "letterSpacing": "0px", "textTransform": "none" }}>
                                                    Way To Contact Us.</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="gdlr-core-pbf-element">
                                        <div className="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align gdlr-core-no-p-space"
                                            style={{ "paddingBottom": "5px" }}>
                                            <div className="gdlr-core-text-box-item-content"
                                                style={{ "fontSize": "18px", "textTransform": "none" }}>
                                                <p>We are open from Saturday to Thursday (10:00-19:00). off day ( Friday ) and Govt. holiday.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="gdlr-core-pbf-element">
                                        <div className="gdlr-core-social-network-item gdlr-core-item-pdb  gdlr-core-left-align gdlr-core-item-pdlr"
                                            style={{ "paddingBottom": "47px" }}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="gdlr-core-pbf-column gdlr-core-column-30">
                            <div className="gdlr-core-pbf-column-content-margin gdlr-core-js ">
                                <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js "></div>
                            </div>
                        </div>
                        <div className="gdlr-core-pbf-column gdlr-core-column-30" style={{ display: "flex" }}>
                            <div className="gdlr-core-pbf-column-content-margin gdlr-core-js gdlr-core-column-40" style={{ margin: "0 auto" }}>
                                <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                                    <div className="gdlr-core-pbf-element">
                                        <div className="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                                            style={{ "paddingBottom": "20px" }}>
                                            <div className="gdlr-core-title-item-title-wrap ">
                                                <h3 className="gdlr-core-title-item-title gdlr-core-skin-title "
                                                    style={{ "fontSize": "20px", "letterSpacing": "0px", "textTransform": "none" }}>
                                                    Office</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="gdlr-core-pbf-element">
                                        <div className="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align gdlr-core-no-p-space"
                                            style={{ "paddingBottom": "15px" }}>
                                            <div className="gdlr-core-text-box-item-content"
                                                style={{ "fontSize": "18px", "textTransform": "none" }}>
                                                <p><span style={{ color: "black" }}><b>Address: </b></span>{setting.address}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="gdlr-core-pbf-element">
                                        <div className="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align gdlr-core-no-p-space"
                                            style={{ "paddingBottom": "15px" }}>
                                            <div className="gdlr-core-text-box-item-content"
                                                style={{ "fontSize": "18px", "textTransform": "none" }}>
                                                <p><span style={{ color: "black" }}><b>Phone: </b></span><a href={`tel:${setting.phone}`}>{setting.phone}</a> </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="gdlr-core-pbf-element">
                                        <div className="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align"
                                            style={{ "paddingBottom": "0px" }}>
                                            <div className="gdlr-core-text-box-item-content"
                                                style={{ "fontSize": "18px", "textTransform": "none" }}>
                                                <p><span style={{ color: "black" }}><b>Mail: </b></span>{setting.email}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>}
            </div>
            </div>
        </div >
    )
}

export default Home;