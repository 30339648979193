import React, { Component } from 'react';
import apiService from '../../api/api.service';
import { connect } from 'react-redux';
import './getAQuote.component.css';

class GetAQuote extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            company: '',
            address: '',
            phone: '',
            budget: '',
            startTime: '',
            description: '',
            service: '',
            serviceFile: '',
            fileName: '',
            checkedItems: new Set(),
            // otpSendStatus: false,
            // otpMessage: "",
            otp: '',
            error: "",
            successMessage: "",
            loading: false
        }
        // create a ref to store the textInput DOM element
        this.fileUpload = React.createRef();

    }

    componentDidMount() {
        // var loadScript = function (src) {
        //     var tag = document.createElement('script');
        //     tag.async = false;
        //     tag.src = src;
        //     var body = document.getElementsByTagName('body')[0];
        //     body.appendChild(tag);
        // }

        // loadScript('plugins/goodlayers-core/include/js/page-builder.js');
    }

    changeHandler = e => {
        this.setState({ [e.target.name]: e.target.value });
    }
    checkboxChangeHandler = (event) => {
        var isChecked = event.target.checked;
        var item = event.target.value;
        if (isChecked) {
            this.state.checkedItems.add(item);
        } else {
            this.state.checkedItems.delete(item);
        }
        this.setState(({ checkedItems: this.state.checkedItems }));

    }

    submitHandler = e => {
        e.preventDefault();
        if (!this.state.name || !this.state.phone || !this.state.checkedItems.size) {
            this.setState({ error: "Please enter valid data" });
            return;
        }
        // if (!this.state.otpSendStatus) {
        //     this.sendOtp();
        //     this.setState({ otpSendStatus: true });
        //     return;
        // }
        // if (this.state.otpSendStatus && !this.state.otpSendStatus) {
        //     this.setState({ error: "Please enter otp" });
        //     return;
        // }
        // this.state.service = [...this.state.checkedItems].join(',');
        this.setState(({ service: [...this.state.checkedItems].join(',') }));
        this.setState({ loading: true });
        apiService.quoteSave({ ...this.state, service: [...this.state.checkedItems].join(',') }).then((res) => {
            if (res.data.status === 'error') {
                this.setState({ error: res.data.message })
                return;
            }
            this.setState({
                name: '',
                email: '',
                company: '',
                address: '',
                phone: '',
                budget: '',
                startTime: '',
                description: '',
                service: '',
                serviceFile: '',
                fileName: '',
                checkedItems: new Set(),
                // otpSendStatus: false,
                // otpMessage: "",
                otp: '',
                error: "",
                successMessage: "Quote successfully submitted"
            });
            this.setState({ loading: false });
        }).catch(err => {
            this.setState({ error: err.response.data.message })
            this.setState({ loading: false });
        })
    }

    sendOtp = () => {
        this.setState({ otpMessage: "" });
        apiService.sendOtp({ phone: this.state.phone }).then(res => {
            this.setState({ otpMessage: "An Otp has been sent to your phone." });
        })
    }
    render() {
        let { error, successMessage } = this.state;
        return (
            <div className="inteco-page-wrapper" id="inteco-page-wrapper">
                <div className="gdlr-core-page-builder-body">
                    {/* <div className="gdlr-core-pbf-wrapper " style={{ "margin": "0px 0px 0px 0px", padding: "390px 0px 170px 0px" }} id="gdlr-core-wrapper-1">
                        <div className="gdlr-core-pbf-background-wrap">
                            <div className="gdlr-core-pbf-background gdlr-core-parallax gdlr-core-js" style={{ "backgroundImage": "url(upload/getquote-bg.jpg)", "backgroundSize": "cover", "backgroundPosition": "center" }} data-parallax-speed="0.3"></div>
                        </div>
                        <div className="gdlr-core-pbf-wrapper-content gdlr-core-js " data-gdlr-animation="fadeIn" data-gdlr-animation-duration="600ms" data-gdlr-animation-offset="0.8">
                            <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container-custom" style={{ "maxWidth": "730px" }}>
                                <div className="gdlr-core-pbf-element">
                                    <div className="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-center-align gdlr-core-title-item-caption-bottom gdlr-core-item-pdlr">
                                        <div className="gdlr-core-title-item-title-wrap ">
                                            <h3 className="gdlr-core-title-item-title gdlr-core-skin-title " style={{ "fontSize": "60px", "fontWeight": 700, "letterSpacing": "0px ", "textTransform": "none", "color": "#ffffff" }}>Get A Quote</h3></div><span className="gdlr-core-title-item-caption gdlr-core-info-font gdlr-core-skin-caption" style={{ "fontSize": "18px", "fontStyle": "normal", color: "#ffffff", "marginTop": "15px" }}>A wonderful serenity has taken possession of my entire soul, like these sweet mornings of spring which I enjoy with my whole heart. I am alone, and feel the charm of existence in this spot, which was created for the bliss of souls like.</span></div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className="inteco-page-title-wrap  inteco-style-custom inteco-left-align">
                        {/* <div className="inteco-header-transparent-substitute"></div> */}
                        <div className="inteco-page-title-overlay"></div>
                        <div className="inteco-page-title-container inteco-container">
                            <div className="inteco-page-title-content inteco-item-pdlr">
                                {/* <h1 className="inteco-page-title">About Our Team</h1> */}
                            </div>
                        </div>
                    </div>
                    <div className="gdlr-core-pbf-wrapper " style={{ "padding": "40px 0px 10px 0px" }}>
                        <div className="gdlr-core-pbf-background-wrap" style={{ "backgroundColor": "#f5f5f5" }}></div>
                        <div className="gdlr-core-pbf-wrapper-content gdlr-core-js ">
                            <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
                                <div className="gdlr-core-pbf-column gdlr-core-column-20 gdlr-core-column-first">
                                    <div className="gdlr-core-pbf-column-content-margin gdlr-core-js " style={{ "padding": "50px 20px 0px 20px" }}>
                                        <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                                            <div className="gdlr-core-pbf-element">
                                                <div className="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-bottom gdlr-core-item-pdlr" style={{ "paddingBottom": "25px" }}>
                                                    <div className="gdlr-core-title-item-title-wrap ">
                                                        <h3 className="gdlr-core-title-item-title gdlr-core-skin-title " style={{ "fontSize": "22px", "fontWeight": 700, "letterSpacing": "0px", "textTransform": "none" }}>Location</h3></div>
                                                </div>
                                            </div>
                                            <div className="gdlr-core-pbf-element">
                                                <div className="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align" style={{ "paddingBottom": "0px" }}>
                                                    <div className="gdlr-core-text-box-item-content" style={{ "fontSize": "16px" }}>
                                                        <p>{this.props.setting.address}
                                                            <br />
                                                            {/* Dhaka, Bangladesh */}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="gdlr-core-pbf-element">
                                                <div className="gdlr-core-icon-list-item gdlr-core-item-pdlr gdlr-core-item-pdb clearfix ">
                                                    <ul>
                                                        <li className=" gdlr-core-skin-divider"><span className="gdlr-core-icon-list-icon-wrap" style={{ "marginTop": "7px" }}><i className="gdlr-core-icon-list-icon fa fa-envelope-o" style={{ "fontSize": "17px", width: "17px" }} ></i></span>
                                                            <div className="gdlr-core-icon-list-content-wrap"><span className="gdlr-core-icon-list-content" style={{ "fontSize": "17px" }}>{this.props.setting.email}</span></div>
                                                        </li>
                                                        <li className=" gdlr-core-skin-divider"><span className="gdlr-core-icon-list-icon-wrap" style={{ "marginTop": "7px" }}><i className="gdlr-core-icon-list-icon fa fa-phone" style={{ "fontSize": "17px", width: "17px" }} ></i></span>
                                                            <div className="gdlr-core-icon-list-content-wrap"><a href={`tel:${this.props.setting.phone}`} className="gdlr-core-icon-list-content" style={{ "fontSize": "17px" }}>{this.props.setting.phone}</a></div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="gdlr-core-pbf-element">
                                                <div className="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-bottom gdlr-core-item-pdlr" style={{ "paddingBottom": "25px" }}>
                                                    <div className="gdlr-core-title-item-title-wrap ">
                                                        <h3 className="gdlr-core-title-item-title gdlr-core-skin-title " style={{ "fontSize": "22px", "fontWeight": 700, "letterSpacing": "0px", "textTransform": "none" }}>Office Time</h3></div>
                                                </div>
                                            </div>
                                            <div className="gdlr-core-pbf-element">
                                                <div className="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align" style={{ "paddingBottom": "0px" }}>
                                                    <div className="gdlr-core-text-box-item-content" style={{ "fontSize": "16px", "textTransform": "none" }}>
                                                        <p>We are open on <strong>Saturday–Thursday at (10am-7pm)</strong>, except Friday and Govt. holidays.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="gdlr-core-pbf-column gdlr-core-column-40">
                                    <div className="gdlr-core-pbf-column-content-margin gdlr-core-js " style={{ "padding": "50px 20px 0px 20px" }}>
                                        <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                                            <div className="gdlr-core-pbf-element">
                                                <div className="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-bottom gdlr-core-item-pdlr" style={{ "paddingBottom": "25px" }}>
                                                    <div className="gdlr-core-title-item-title-wrap ">
                                                        <h3 className="gdlr-core-title-item-title gdlr-core-skin-title " style={{ "fontSize": "29px", "fontWeight": 700, "letterSpacing": "0px", "textTransform": "none" }}>Request A Quote By Submitting This Form</h3></div>
                                                </div>
                                            </div>
                                            <div className="gdlr-core-pbf-element">
                                                <div className="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align" style={{ "paddingBottom": "35px" }}>
                                                    <div className="gdlr-core-text-box-item-content" style={{ "fontSize": "16px", "textTransform": "none " }}>
                                                        {/* <p>A wonderful serenity has taken possession of my entire soul, like these sweet mornings of spring which I enjoy with my whole heart. I am alone, and feel the charm of existence in this spot, which was created for the bliss of souls like mine.</p> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="gdlr-core-pbf-element" style={{ border: "1px solid #999" }}>
                                                <div className="gdlr-core-contact-form-7-item gdlr-core-item-pdlr gdlr-core-item-pdb ">
                                                    <div role="form" className="wpcf7" id="wpcf7-f5826-p5822-o1" lang="en-US" dir="ltr">
                                                        <div className="screen-reader-response"></div>
                                                        <form action="/#" method="post" className="wpcf7-form" noValidate="novalidate">
                                                            <div style={{ "display": "none" }}>
                                                                <input type="hidden" name="g-recaptcha-response" value="" />
                                                            </div>
                                                            <div className="gdlr-core-input-wrap gdlr-core-large gdlr-core-full-width gdlr-core-with-column gdlr-core-no-border quote-user-form" style={{ marginBottom: "60px" }}>
                                                                <div className="gdlr-core-column-60" style={{ marginTop: "14px" }}><span className="wpcf7-form-control-wrap your-name"><input type="text" name="name" value={this.state.name} onChange={this.changeHandler} size="40" className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" aria-required="true" aria-invalid="false" placeholder="Full Name*" /></span></div>
                                                                <div className="gdlr-core-column-60"><span className="wpcf7-form-control-wrap your-phone"><input type="tel" name="phone" value={this.state.phone} onChange={this.changeHandler} size="40" className="wpcf7-form-control wpcf7-text wpcf7-tel wpcf7-validates-as-required wpcf7-validates-as-tel" aria-required="true" aria-invalid="false" placeholder="Phone Number*" /></span></div>
                                                                <div className="clear"></div>
                                                                <div className="gdlr-core-column-60"><span className="wpcf7-form-control-wrap your-email"><input type="email" name="email" value={this.state.email} onChange={this.changeHandler} size="40" className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email" aria-required="true" aria-invalid="false" placeholder="Email" /></span></div>
                                                                <div className="gdlr-core-column-60"><span className="wpcf7-form-control-wrap your-address"><input type="text" name="address" value={this.state.address} onChange={this.changeHandler} size="40" className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" aria-required="true" aria-invalid="false" placeholder="Address" /></span></div>
                                                                <div className="clear"></div>
                                                                {/* <div className="gdlr-core-column-30"><span className="wpcf7-form-control-wrap your-company"><input type="text" name="company" value={this.state.company} onChange={this.changeHandler} size="40" className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" aria-required="true" aria-invalid="false" placeholder="Company" /></span></div> */}
                                                                {/* <div className="gdlr-core-column-30"><span className="wpcf7-form-control-wrap your-phone"><input type="tel" name="phone" value={this.state.phone} onChange={this.changeHandler} size="40" className="wpcf7-form-control wpcf7-text wpcf7-tel wpcf7-validates-as-required wpcf7-validates-as-tel" aria-required="true" aria-invalid="false" placeholder="Phone*" /></span></div> */}
                                                                {/* <div className="clear"></div> */}
                                                                {/* <div className="gdlr-core-column-30"><span className="wpcf7-form-control-wrap your-area"><input type="text" name="your-area" value="" size="40" className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" aria-required="true" aria-invalid="false" placeholder="Areas Of Interest*" /></span></div> */}
                                                                {/* <div className="gdlr-core-column-30"><span className="wpcf7-form-control-wrap your-budget"><input type="text" name="budget" value={this.state.budget} onChange={this.changeHandler} size="40" className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" aria-required="true" aria-invalid="false" placeholder="Project Budget" /></span></div> */}
                                                                {/* <div className="clear"></div> */}
                                                                {/* <div className="gdlr-core-column-30"><span className="wpcf7-form-control-wrap your-date"><input type="date" name="startTime" value={this.state.startTime} onChange={this.changeHandler} className="wpcf7-form-control wpcf7-date wpcf7-validates-as-required wpcf7-validates-as-date" aria-required="true" aria-invalid="false" placeholder="Requested Completion Date" /></span></div> */}
                                                                {/* <div className="gdlr-core-column-30"><span className="wpcf7-form-control-wrap your-refer"><input type="text" name="your-refer" value="" size="40" className="wpcf7-form-control wpcf7-text" aria-invalid="false" placeholder="Referred By" /></span></div> */}
                                                                {/* <div className="clear"></div>
                                                                <div className="gdlr-core-column-60"><span className="wpcf7-form-control-wrap your-message"><textarea value={this.state.description} onChange={this.changeHandler} name="description" cols="40" rows="10" className="wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required" aria-required="true" aria-invalid="false" placeholder="Tell us about your project"></textarea></span></div> */}

                                                            </div>
                                                            <div className="wpcf7-response-output wpcf7-display-none"></div>
                                                            <div className="contact-form">
                                                                <div className="gdlr-core-pbf-element">
                                                                    <div className="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-bottom gdlr-core-item-pdlr" style={{ "paddingBottom": "25px" }}>
                                                                        <div className="gdlr-core-title-item-title-wrap ">
                                                                            <h3 className="gdlr-core-title-item-title gdlr-core-skin-title " style={{ "fontSize": "22px", "fontWeight": 700, "letterSpacing": "0px", "textTransform": "none" }}>Select services are you interested in</h3></div>
                                                                    </div>
                                                                </div>

                                                                <div className="gdlr-core-input-wrap gdlr-core-large gdlr-core-full-width gdlr-core-with-column gdlr-core-no-border">

                                                                    <div className="gdlr-core-column-30">
                                                                        <div className="radio position-relative">
                                                                            {/* <input type="radio" name="service" id="optionsRadios1" className="position-absolute" value="Software Development" checked="" /> */}
                                                                            <input className="position-absolute" id="optionsRadios1" name="services" onChange={this.checkboxChangeHandler} type="checkbox" value="Apartment Interior design"></input>
                                                                            <label htmlFor="optionsRadios1">
                                                                                <img className="disable-check" src="https://www.gsoftt.com/_next/static/images/Software-Development-ed42c61cd88ab0733518ad3148875da5.png" style={{ "opacity": " 0.3" }} alt="Resident Architectural 1" />
                                                                                <img className="active-check" src="https://www.gsoftt.com/_next/static/images/Software-Development-ed42c61cd88ab0733518ad3148875da5.png" alt="Resident Architectural" />
                                                                                <span>Apartment Interior design</span>
                                                                            </label>
                                                                        </div>
                                                                    </div>

                                                                    <div className="gdlr-core-column-30">
                                                                        <div className="radio position-relative">
                                                                            <input className="position-absolute" id="option2" name="services" onChange={this.checkboxChangeHandler} type="checkbox" value="Duplex interior design"></input>
                                                                            <label htmlFor="option2">
                                                                                <img className="disable-check" src="https://www.gsoftt.com/_next/static/images/Software-Development-ed42c61cd88ab0733518ad3148875da5.png" style={{ "opacity": " 0.3" }} alt="Home Interior 1" />
                                                                                <img className="active-check" src="https://www.gsoftt.com/_next/static/images/Software-Development-ed42c61cd88ab0733518ad3148875da5.png" alt="Home Interior" />
                                                                                <span>Duplex interior design</span>
                                                                            </label>
                                                                        </div>
                                                                    </div>

                                                                    <div className="gdlr-core-column-30">
                                                                        <div className="radio position-relative">
                                                                            <input className="position-absolute" id="option3" name="services" onChange={this.checkboxChangeHandler} type="checkbox" value="Kitchen design"></input>
                                                                            <label htmlFor="option3">
                                                                                <img className="disable-check" src="https://www.gsoftt.com/_next/static/images/Software-Development-ed42c61cd88ab0733518ad3148875da5.png" style={{ "opacity": " 0.3" }} alt="Home Furniture 1" />
                                                                                <img className="active-check" src="https://www.gsoftt.com/_next/static/images/Software-Development-ed42c61cd88ab0733518ad3148875da5.png" alt="Home Furniture" />
                                                                                <span>Kitchen design</span>
                                                                            </label>
                                                                        </div>
                                                                    </div>

                                                                    <div className="gdlr-core-column-30">
                                                                        <div className="radio position-relative">
                                                                            <input className="position-absolute" id="option4" name="services" onChange={this.checkboxChangeHandler} type="checkbox" value="Bathroom design"></input>
                                                                            <label htmlFor="option4">
                                                                                <img className="disable-check" src="https://www.gsoftt.com/_next/static/images/Software-Development-ed42c61cd88ab0733518ad3148875da5.png" style={{ "opacity": " 0.3" }} alt="Roof & Tarace 1" />
                                                                                <img className="active-check" src="https://www.gsoftt.com/_next/static/images/Software-Development-ed42c61cd88ab0733518ad3148875da5.png" alt="Roof & Tarace" />
                                                                                <span>Bathroom design</span>
                                                                            </label>
                                                                        </div>
                                                                    </div>

                                                                    <div className="gdlr-core-column-30">
                                                                        <div className="radio position-relative">
                                                                            <input className="position-absolute" id="option5" name="services" onChange={this.checkboxChangeHandler} type="checkbox" value="Building design"></input>
                                                                            <label htmlFor="option5">
                                                                                <img className="disable-check" src="https://www.gsoftt.com/_next/static/images/Software-Development-ed42c61cd88ab0733518ad3148875da5.png" style={{ "opacity": " 0.3" }} alt="Kitchen 1" />
                                                                                <img className="active-check" src="https://www.gsoftt.com/_next/static/images/Software-Development-ed42c61cd88ab0733518ad3148875da5.png" alt="Kitchen" />
                                                                                <span>Building design</span>
                                                                            </label>
                                                                        </div>
                                                                    </div>

                                                                    <div className="gdlr-core-column-30">
                                                                        <div className="radio position-relative">
                                                                            <input className="position-absolute" id="option6" name="services" onChange={this.checkboxChangeHandler} type="checkbox" value="Tiles & Marble design"></input>
                                                                            <label htmlFor="option6">
                                                                                <img className="disable-check" src="https://www.gsoftt.com/_next/static/images/Software-Development-ed42c61cd88ab0733518ad3148875da5.png" style={{ "opacity": " 0.3" }} alt="Drawing/Living 1" />
                                                                                <img className="active-check" src="https://www.gsoftt.com/_next/static/images/Software-Development-ed42c61cd88ab0733518ad3148875da5.png" alt="Drawing/Living" />
                                                                                <span>Tiles & Marble design</span>
                                                                            </label>
                                                                        </div>
                                                                    </div>

                                                                    <div className="gdlr-core-column-30">
                                                                        <div className="radio position-relative">
                                                                            <input className="position-absolute" id="option7" name="services" onChange={this.checkboxChangeHandler} type="checkbox" value="Electric & Plumbing design"></input>
                                                                            <label htmlFor="option7">
                                                                                <img className="disable-check" src="https://www.gsoftt.com/_next/static/images/Software-Development-ed42c61cd88ab0733518ad3148875da5.png" style={{ "opacity": " 0.3" }} alt="Dining Area" />
                                                                                <img className="active-check" src="https://www.gsoftt.com/_next/static/images/Software-Development-ed42c61cd88ab0733518ad3148875da5.png" alt="Dining Area" />
                                                                                <span>Electric & Plumbing design</span>
                                                                            </label>
                                                                        </div>
                                                                    </div>

                                                                    <div className="gdlr-core-column-30">
                                                                        <div className="radio position-relative">
                                                                            <input className="position-absolute" id="option8" name="services" onChange={this.checkboxChangeHandler} type="checkbox" value="Furniture design"></input>
                                                                            <label htmlFor="option8">
                                                                                <img className="disable-check" src="https://www.gsoftt.com/_next/static/images/Software-Development-ed42c61cd88ab0733518ad3148875da5.png" style={{ "opacity": " 0.3" }} alt="Master Bed" />
                                                                                <img className="active-check" src="https://www.gsoftt.com/_next/static/images/Software-Development-ed42c61cd88ab0733518ad3148875da5.png" alt="Master Bed" />
                                                                                <span>Furniture design</span>
                                                                            </label>
                                                                        </div>
                                                                    </div>

                                                                    <div className="gdlr-core-column-30">
                                                                        <div className="radio position-relative">
                                                                            <input className="position-absolute" id="option9" name="services" onChange={this.checkboxChangeHandler} type="checkbox" value="Rooftop design"></input>
                                                                            <label htmlFor="option9">
                                                                                <img className="disable-check" src="https://www.gsoftt.com/_next/static/images/Software-Development-ed42c61cd88ab0733518ad3148875da5.png" style={{ "opacity": " 0.3" }} alt="Child Bed" />
                                                                                <img className="active-check" src="https://www.gsoftt.com/_next/static/images/Software-Development-ed42c61cd88ab0733518ad3148875da5.png" alt="Child Bed" />
                                                                                <span>Rooftop design</span>
                                                                            </label>
                                                                        </div>
                                                                    </div>

                                                                    <div className="gdlr-core-column-30">
                                                                        <div className="radio position-relative">
                                                                            <input className="position-absolute" id="option10" name="services" onChange={this.checkboxChangeHandler} type="checkbox" value="Landscaping design"></input>
                                                                            <label htmlFor="option10">
                                                                                <img className="disable-check" src="https://www.gsoftt.com/_next/static/images/Software-Development-ed42c61cd88ab0733518ad3148875da5.png" style={{ "opacity": " 0.3" }} alt="Guest Bed" />
                                                                                <img className="active-check" src="https://www.gsoftt.com/_next/static/images/Software-Development-ed42c61cd88ab0733518ad3148875da5.png" alt="Guest Bed" />
                                                                                <span>Landscaping design</span>
                                                                            </label>
                                                                        </div>
                                                                    </div>



                                                                    {/* <div className="gdlr-core-column-30">
                                                                        <div class="radio position-relative">
                                                                            <input type="radio" name="service" id="optionsRadios3" class="position-absolute" value="Software Development" />
                                                                            <label for="optionsRadios3">
                                                                                <img class="disable-check" src="https://www.gsoftt.com/_next/static/images/Software-Development-ed42c61cd88ab0733518ad3148875da5.png" style={{ "opacity": " 0.3" }} />
                                                                                <img class="active-check" src="https://www.gsoftt.com/_next/static/images/Software-Development-ed42c61cd88ab0733518ad3148875da5.png" alt="" />
                                                                                <span>Software Development3</span>
                                                                            </label>
                                                                        </div>
                                                                    </div> */}

                                                                    {/* {otpSendStatus && <div className="gdlr-core-column-60">
                                                                        <span className="wpcf7-form-control-wrap your-name">
                                                                            <input type="text" name="otp" value={this.state.otp} onChange={this.changeHandler} size="40" className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" aria-required="true" aria-invalid="false" placeholder="Otp*" style={{ background: "#d6d6d6" }} />
                                                                        </span>
                                                                        <a href="/#" style={{ float: "left", color: "green", fontSize: "14.5px" }}>{otpMessage}</a>
                                                                        <span onClick={this.sendOtp} style={{ float: "right", color: "#d6961cdb", cursor: "pointer" }}>Resend otp</span>
                                                                    </div>} */}
                                                                    <div className="clear"></div>
                                                                    {/* <div className="gdlr-core-column-60 gdlr-core-left-align">
                                                                        <input onClick={this.submitHandler} type="submit" value={otpSendStatus ? "Verify And Submit" : "Submit Now"} className="wpcf7-form-control wpcf7-submit gdlr-core-full" />
                                                                    </div> */}
                                                                    <div className="gdlr-core-column-60 gdlr-core-left-align">
                                                                        {/* <input onClick={this.submitHandler} type="submit" value={"Submit Now"} className="wpcf7-form-control wpcf7-submit gdlr-core-full" /> */}
                                                                        <button type="submit" className="submit-button" onClick={this.submitHandler} style={{ padding: "5px" }} disabled={this.state.loading}>
                                                                            {this.state.loading && <i class="fa fa-refresh fa-spin" style={{ fontSize: "20px" }}></i>}
                                                                            {!this.state.loading && <i class="fa fa-sign-in" style={{ fontSize: "20px" }}></i>}
                                                                            <span style={{ paddingLeft: "10px", fontSize: "20px" }}>Submit Now</span>
                                                                        </button>
                                                                    </div>
                                                                    {error &&
                                                                        <span style={{ color: "red" }}>{error}</span>
                                                                    }
                                                                    {successMessage &&
                                                                        <span style={{ color: "green" }}>{successMessage}</span>
                                                                    }
                                                                </div>

                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="gdlr-core-pbf-wrapper " style={{ "padding": "0px 0px 0px 0px" }}>
                        <div className="gdlr-core-pbf-background-wrap"></div>
                        <div className="gdlr-core-pbf-wrapper-content gdlr-core-js ">
                            <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-pbf-wrapper-full-no-space">
                                <div className="gdlr-core-pbf-element">
                                    <div className="gdlr-core-wp-google-map-plugin-item gdlr-core-item-pdlr gdlr-core-item-pdb " style={{ "paddingBottom": "0px" }}>
                                        <div style={{ "overflow": "hidden", width: "100%", position: "relative" }}>

                                            <iframe style={{ "width": "100%", height: "480px", border: 0 }} title="quote-map" src={this.props.setting.mapSrc} width="600" height="450" allowFullScreen="" loading="lazy"></iframe>
                                            <div style={{ "position": "absolute", "width": "80%", bottom: "20px", left: 0, right: 0, "marginLeft": "auto", "marginRight": "auto", color: "#000" }}>

                                            </div>
                                            {/* <style>#gmap_canvas img{maxWidth:none!important;background:none!important}</style> */}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="gdlr-core-pbf-wrapper " style={{ "padding": "75px 0px 75px 0px" }}>
                        <div className="gdlr-core-pbf-background-wrap" style={{ "backgroundColor": "#ffffff" }}></div>
                        <div className="gdlr-core-pbf-wrapper-content gdlr-core-js " data-gdlr-animation="fadeInUp" data-gdlr-animation-duration="600ms" data-gdlr-animation-offset="0.8">
                            <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-pbf-wrapper-full-no-space">
                                <div className="gdlr-core-pbf-element">
                                    <div className="gdlr-core-social-network-item gdlr-core-item-pdb  gdlr-core-center-align gdlr-core-item-pdlr" style={{ "paddingBottom": "0px" }}><a href="/#" target="_blank" className="gdlr-core-social-network-icon" title="email" style={{ "fontSize": "20px", color: "#232323" }}><i className="fa fa-envelope" ></i></a><a href="/#" target="_blank" className="gdlr-core-social-network-icon" title="facebook" style={{ "fontSize": "20px", color: "#232323", "marginLeft": "37px" }}><i className="fa fa-facebook" ></i></a><a href="/#" target="_blank" className="gdlr-core-social-network-icon" title="google-plus" style={{ "fontSize": "20px", color: "#232323", "marginLeft": "37px" }}><i className="fa fa-google-plus" ></i></a><a href="/#" target="_blank" className="gdlr-core-social-network-icon" title="linkedin" style={{ "fontSize": "20px", color: "#232323", "marginLeft": "37px" }}><i className="fa fa-linkedin" ></i></a><a href="/#" target="_blank" className="gdlr-core-social-network-icon" title="skype" style={{ "fontSize": "20px", color: "#232323", "marginLeft": "37px" }}><i className="fa fa-skype" ></i></a><a href="/#" target="_blank" className="gdlr-core-social-network-icon" title="twitter" style={{ "fontSize": "20px", color: "#232323", "marginLeft": "37px" }}><i className="fa fa-twitter" ></i></a></div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        setting: state.common.content.setting
    }
}

export default connect(mapStateToProps, null)(GetAQuote);