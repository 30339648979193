import React, { Component } from 'react';
import server from '../../configs/domain';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import './projects.component.css';

class Projects extends Component {

    componentDidMount() {
        // var loadScript = function (src) {
        //     var tag = document.createElement('script');
        //     tag.async = false;
        //     tag.src = src;
        //     var body = document.getElementsByTagName('body')[0];
        //     body.appendChild(tag);
        // }

        // loadScript('/js/plugins.js');

    }
    render() {
        return (
            <div>
                <div className="inteco-page-title-wrap  inteco-style-medium inteco-center-align">
                    {/* <div className="inteco-header-transparent-substitute"></div> */}
                    <div className="inteco-page-title-overlay"></div>
                    <div className="inteco-page-title-container inteco-container">
                        <div className="inteco-page-title-content inteco-item-pdlr">
                            {/* <h1 className="inteco-page-title">Projects</h1> */}
                            {/* <div className="inteco-page-caption">{this.props.match.params.type}</div> */}
                        </div>
                    </div>
                </div>
                <div className="inteco-page-wrapper" id="inteco-page-wrapper">
                    <div className="gdlr-core-page-builder-body">
                        <div className="gdlr-core-pbf-section">
                            <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-pbf-wrapper-full">
                                <div className="gdlr-core-pbf-element">
                                    <div className="gdlr-core-portfolio-item gdlr-core-item-pdb clearfix  gdlr-core-portfolio-item-style-grid" style={{ "paddingBottom": "10px" }}>

                                        {/* <div className="filter light-filter clearfix gdlr-core-filterer-wrap gdlr-core-js  gdlr-core-item-pdlr gdlr-core-style-text gdlr-core-center-align">
                                            <ul>
                                                <li><a href="/#" className="active" data-filter="*">All</a></li>
                                                <li><a href="/#" data-filter=".className1" >Architecture</a></li>
                                                <li><a href="/#" data-filter=".className2" >Highrise</a></li>
                                                <li><a href="/#" data-filter=".className3" >Housing</a></li>
                                            </ul>
                                        </div> */}

                                        <div className="gdlr-core-portfolio-item-holder gdlr-core-js-2 filter-container clearfix" data-layout="fitrows">
                                            {/* {
                                                this.props.projects && this.props.projects.map(project => {
                                                    return project.type === this.props.match.params.type &&
                                                        <div className="gdlr-core-item-list className1 gdlr-core-item-pdlr gdlr-core-column-15 ">
                                                            <div className="gdlr-core-portfolio-grid  gdlr-core-left-align gdlr-core-style-normal">
                                                                <div className="gdlr-core-portfolio-thumbnail gdlr-core-media-image  gdlr-core-style-title-icon">
                                                                    <div className="gdlr-core-portfolio-thumbnail-image-wrap  gdlr-core-zoom-on-hover">
                                                                        <img src={`${server}/${project.image}`} alt="" width="700" height="450" title="port-1" />
                                                                        <span className="gdlr-core-image-overlay  gdlr-core-portfolio-overlay gdlr-core-image-overlay-center-icon gdlr-core-js">
                                                                            <span className="gdlr-core-image-overlay-content" >
                                                                                <span className="gdlr-core-portfolio-title gdlr-core-title-font " style={{ "fontSize": "18px", "fontWeight": 400, "letterSpacing": "1px", "textTransform": "none" }}  >
                                                                                    <a href="/#" >{project.title}</a>
                                                                                </span><span className="gdlr-core-portfolio-icon-wrap">
                                                                                    <a className="gdlr-core-lightgallery gdlr-core-js " href={`${server}/${project.image}`} data-lightbox-group="gdlr-core-img-group-1">
                                                                                        <i className="gdlr-core-portfolio-icon arrow_expand" >
                                                                                        </i>
                                                                                    </a>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="gdlr-core-portfolio-content-wrap gdlr-core-skin-divider">
                                                                    <h3 className="gdlr-core-portfolio-title gdlr-core-skin-title" style={{ "fontSize": "19px", "fontWeight": 600, "letterSpacing": "0px", "textTransform": "none" }}>
                                                                        <a href="/#" >{project.title}</a>
                                                                    </h3>
                                                                </div>
                                                            </div>
                                                        </div>

                                                })
                                            } */}

                                            {
                                                this.props.projects && this.props.projects.map(project => {
                                                    return project.type === this.props.match.params.type &&
                                                        <div className="gdlr-core-item-list  gdlr-core-item-pdlr gdlr-core-column-15 project-list" key={project.title + project.id}>
                                                            <div className="gdlr-core-portfolio-grid  gdlr-core-center-align gdlr-core-style-normal" style={{ "marginBottom": "25px" }}>
                                                                <div className="gdlr-core-portfolio-thumbnail gdlr-core-media-image  gdlr-core-style-margin-icon">
                                                                    <div className="gdlr-core-portfolio-thumbnail-image-wrap  gdlr-core-zoom-on-hover">
                                                                        <NavLink className="gdlr-core-js " to={'/project/' + project.id} data-lightbox-group="gdlr-core-img-group-1"><img src={`${server}/${project.image}`} srcSet={`${server}/${project.image} 400w, ${server}/${project.image} 700w`} sizes="(maxWidth: 767px) 100vw, 25vw" alt="" width="700" height="660" /><span className="gdlr-core-image-overlay  gdlr-core-portfolio-overlay gdlr-core-image-overlay-center-icon gdlr-core-js gdlr-core-with-margin"><span className="gdlr-core-image-overlay-content" style={{ "marginTop": "-17.8417px" }}><span className="gdlr-core-portfolio-icon-wrap"><i className="gdlr-core-portfolio-icon arrow_expand"></i></span></span>
                                                                        </span>
                                                                        </NavLink>
                                                                    </div>
                                                                </div>
                                                                <div className="gdlr-core-portfolio-content-wrap gdlr-core-skin-divider">
                                                                    <h3 className="gdlr-core-portfolio-title gdlr-core-skin-title" style={{ "fontSize": "16px", "fontWeight": "600", "letterSpacing": "0px", "textTransform": "none" }}><NavLink to={'/project/' + project.id}>{project.title}</NavLink></h3>
                                                                    <span className="gdlr-core-portfolio-info gdlr-core-portfolio-info-tag gdlr-core-info-font gdlr-core-skin-caption"><a href="/#" rel="tag">{this.props.match.params.type}</a> <span className="gdlr-core-sep">/</span> <a href="/#" rel="tag">Projects</a></span>
                                                                    <div className="gdlr-core-portfolio-content"></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                })
                                            }

                                            {/* <div className="gdlr-core-item-list  gdlr-core-item-pdlr gdlr-core-column-15 ">
                                                <div className="gdlr-core-portfolio-grid  gdlr-core-center-align gdlr-core-style-normal" style={{ "marginBottom": "25px" }}>
                                                    <div className="gdlr-core-portfolio-thumbnail gdlr-core-media-image  gdlr-core-style-margin-icon">
                                                        <div className="gdlr-core-portfolio-thumbnail-image-wrap  gdlr-core-zoom-on-hover">
                                                            <a className="gdlr-core-lightgallery gdlr-core-js " href="//a6e8z9v6.stackpathcdn.com/inteco/wp-content/uploads/2018/09/port-1.jpg" data-lightbox-group="gdlr-core-img-group-1"><img src="//a6e8z9v6.stackpathcdn.com/inteco/wp-content/uploads/2018/09/port-1-400x377.jpg" srcset="//a6e8z9v6.stackpathcdn.com/inteco/wp-content/uploads/2018/09/port-1-400x377.jpg 400w, //a6e8z9v6.stackpathcdn.com/inteco/wp-content/uploads/2018/09/port-1-700x660.jpg 700w" sizes="(maxWidth: 767px) 100vw, 25vw" alt="" width="700" height="660" /><span className="gdlr-core-image-overlay  gdlr-core-portfolio-overlay gdlr-core-image-overlay-center-icon gdlr-core-js gdlr-core-with-margin"><span className="gdlr-core-image-overlay-content" style={{ "marginTop": "-17.8417px" }}><span className="gdlr-core-portfolio-icon-wrap"><i className="gdlr-core-portfolio-icon arrow_expand"></i></span></span>
                                                            </span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="gdlr-core-portfolio-content-wrap gdlr-core-skin-divider">
                                                        <h3 className="gdlr-core-portfolio-title gdlr-core-skin-title" style={{ "fontSize": "16px", "fontWeight": "600", "letterSpacing": "0px", "textTransform": "none" }}><a href="https://demo.goodlayers.com/inteco/portfolio/interior-work-avroko-project/">Interior Work AvroKo Project</a></h3><span className="gdlr-core-portfolio-info gdlr-core-portfolio-info-tag gdlr-core-info-font gdlr-core-skin-caption"><a href="https://demo.goodlayers.com/inteco/portfolio_tag/interior/" rel="tag">Ongoing</a> <span className="gdlr-core-sep">/</span> <a href="https://demo.goodlayers.com/inteco/portfolio_tag/minimal/" rel="tag">Projects</a></span>
                                                        <div className="gdlr-core-portfolio-content"></div>
                                                    </div>
                                                </div>
                                            </div> */}

                                            {/* <div className="gdlr-core-item-list className2 gdlr-core-item-pdlr gdlr-core-column-15">
                                                <div className="gdlr-core-portfolio-grid  gdlr-core-left-align gdlr-core-style-normal">
                                                    <div className="gdlr-core-portfolio-thumbnail gdlr-core-media-image  gdlr-core-style-title-icon">
                                                        <div className="gdlr-core-portfolio-thumbnail-image-wrap  gdlr-core-zoom-on-hover"><img src="/upload/gdtography-310706-unsplash-700x450.jpg" alt="" width="700" height="450" title="gdtography-310706-unsplash" /><span className="gdlr-core-image-overlay  gdlr-core-portfolio-overlay gdlr-core-image-overlay-center-icon gdlr-core-js"><span className="gdlr-core-image-overlay-content" ><span className="gdlr-core-portfolio-title gdlr-core-title-font " style={{ "fontSize": "18px", "fontWeight": 400, "letterSpacing": "1px", "textTransform": "none" }}  ><a href="/#" >The Ark Building Project</a></span><span className="gdlr-core-portfolio-icon-wrap"><a className="gdlr-core-lightgallery gdlr-core-js " href="/upload/gdtography-310706-unsplash.jpg" data-lightbox-group="gdlr-core-img-group-1"><i className="gdlr-core-portfolio-icon arrow_expand" ></i></a></span></span>
                                                        </span>
                                                        </div>
                                                    </div>
                                                    <div className="gdlr-core-portfolio-content-wrap gdlr-core-skin-divider">
                                                        <h3 className="gdlr-core-portfolio-title gdlr-core-skin-title" style={{ "fontSize": "19px", "fontWeight": 600, "letterSpacing": "0px", "textTransform": "none" }}><a href="/#" >The Ark Building Project</a></h3><span className="gdlr-core-portfolio-info gdlr-core-portfolio-info-tag gdlr-core-info-font gdlr-core-skin-caption"><a href="/#" rel="tag">Ongoing</a> <span className="gdlr-core-sep">/</span> <a href="/#" rel="tag">Project</a></span>
                                                    </div>
                                                </div>
                                            </div> */}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        projects: state.common.content.imageGallery
    }
}

export default connect(mapStateToProps, null)(Projects);