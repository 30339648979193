import axios from 'axios';
// import { GET_CONTENT } from './commonTypes';
import domain from '../../configs/domain';
import { FETCH_CONTENT_SUCCESS } from './commonTypes';

export const getContent = () => {
    return (dispatch) => {
        axios
            .get(`${domain}/contents`)
            .then(response => {
                dispatch(fetchContentSuccess(response.data.data))
            })
            .catch(error => {
            })
    }
}

export const fetchContentSuccess = contents => {
    return {
        type: FETCH_CONTENT_SUCCESS,
        payload: contents
    }
}